import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { useApiQuery } from 'utils/common';
import { schemaFormService } from 'services/schemaForm.service';
import { Button, Skeleton, notification } from 'antd';
import { projectService } from 'services/project.service';
import { CustomWidgets } from 'form-components';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { buttonBorder } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';

export interface iProjectGeneralProps {
  project: iProjectModel;
}

export const ProjectGeneral = ({ project }: iProjectGeneralProps) => {
  const [schema, schemaError, schemaLoader] = useApiQuery(() => schemaFormService.getGeneral(project.id));

  const formContent = { ...project, tags: (Array.isArray(project?.tags) ? project.tags : []).join(', ') };

  const handleOnSubmit = async e => {
    e.formData.tags = (e.formData?.tags || '')
      .split(/[ ,]/)
      .map((tag: string) => tag.trim())
      .filter((tag: string | any[]) => tag && tag.length > 0);
    const res = await projectService.setProjectSettings(project.id, e.formData);
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
  };

  return schema ? (
    /**
     * @todo replace this form to the antd form https://ant.design/components/form
     *
     *
     * Use this fields from schema:
     *    name
          title
          description
          logo
          tags
          allowCustomClusterRoles
          allowCustomProvisioner
          oneDeploymentPerCluster
          hardcodedNameSpace
          notAllowRemoveNamespace
          showVerionsTab
          allowAutoApplyLimits
          showEnvVarsTab
     */
    <Form
      widgets={CustomWidgets}
      formData={formContent}
      schema={schema.generalSettingschema}
      uiSchema={schema.UIgeneralSettingschema}
      validator={validator}
      onSubmit={handleOnSubmit}
      templates={{ FieldTemplate: CustomFieldTemplate }}
      onError={e => console.log('Error from submit: ', e)}
    >
      <BottomButtons>
        <Button type="primary" htmlType="submit" style={buttonBorder}>
          Save
        </Button>
      </BottomButtons>
    </Form>
  ) : (
    <Skeleton active={true} loading={true} />
  );
};

import DeploymentServicesSettingsForm from './General/Services';
import { useHistory, useParams } from 'react-router-dom';
import { useGqlAllServicesConfigurationUI, useGqlDeploymentById, useGqlDeploymentRoutes } from '../../../services/deployment.service';
import { iDeployment, iDeploymentServicesConfig } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { DeploymentOneServiceSettings } from './General/DeploymentOneServiceSettings';
import { Skeleton, Tabs } from 'antd';
import { Storage } from './General/Storage';
import DeploymentGeneralSettingsForm from './General/General';
import DeploymentEnvVariablesForm from './EnvironmentVariable/EnvironmentVariable';
import { CronJobsUI } from './General/CronJobs';
import DeploymentUpdateVersionForm from './General/DeploymentUpdateVersion';
import { IngressAnnotationForm } from './IngressAnnotationForm/IngressAnnotationForm';
import { ControlOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

export default function DeploymentServicesSettingPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();
  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;
  // const qlQuery = useGqlAllServicesConfigurationUI(Number(id));
  // const servicesConfig: iDeploymentServicesConfig[] = qlQuery.data?.DeploymentsController_AllServicesConfigurationUI || [];

  const dpQueryRoutes = useGqlDeploymentRoutes(deployment.id);
  const hasRoutes = dpQueryRoutes.data?.DeploymentsController_getRoutes?.routes?.length > 0;

  const handleTabClick = (key: any) => history.push(`/app/${id}/services/${key}`);

  if (error || loading || !deployment || !deployment.isReady /*||qlQuery.loading || qlQuery.error*/) {
    return <Skeleton active={true} loading={true} />;
  }
  if (!deployment.isReady) history.push(`/app/${deployment.id}/services/services-requests`);
  if (!tabName) history.push(`/app/${id}/services/services-requests`);
  if (error) history.push('/');

  const conditionalTabs = [
    {
      key: 'general-configuration',
      tab: 'General',
      content: <DeploymentGeneralSettingsForm deploymentId={deployment.id} hasRoutes={hasRoutes} />,
      condition: true,
    },
    {
      key: 'env-configuration',
      tab: 'Environment variables',
      content: <DeploymentEnvVariablesForm deployment={deployment} />,
      condition: deployment.ProjectModel?.showEnvVarsTab !== false,
    },
    {
      key: 'version-configuration',
      tab: 'Version',
      content: <DeploymentUpdateVersionForm deployment={deployment} />,
      condition: deployment.ProjectModel?.showVerionsTab !== false,
    },
    {
      key: 'storage-configuration',
      tab: 'Storage',
      content: <Storage deployment={deployment} />,
      condition: deployment.ProjectModel.name === 'nanoheal',
    },
    {
      key: 'cronjobs-configuration',
      tab: 'Cron jobs',
      content: <CronJobsUI deployment={deployment} />,
      condition: deployment.ProjectModel.name === 'nanoheal',
    },
    { key: 'ingress-configuration', tab: 'Ingress', content: <IngressAnnotationForm deployment={deployment} />, condition: hasRoutes },
  ];

  return (
    <>
      <TitleUIRow title={`Deployment: ${deployment.name}`} />
      <DeploymentWidgetsRow deployment={deployment} />
      <Tabs defaultActiveKey={tabName || 'services-requests'} onChange={handleTabClick}>
        {conditionalTabs.map(({ key, tab, content, condition }) =>
          condition ? (
            <TabPane key={key} tab={tab}>
              {content}
            </TabPane>
          ) : null,
        )}
        {/* <TabPane tab="Requests" key="services-requests">
          <DeploymentServicesSettingsForm deployment={deployment} />
        </TabPane> */}
        {/* {(servicesConfig || [])
          .filter(elem => elem.hasUIconfig)
          .map(service => (
            <TabPane
              tab={
                <>
                  <ControlOutlined /> {service.serviceName}
                </>
              }
              key={service.serviceName}
            >
              <DeploymentOneServiceSettings deployment={deployment} serviceName={service.serviceName} schema={service} />
            </TabPane>
          ))} */}
      </Tabs>
    </>
  );
}

import gql from 'graphql-tag';
import { useState, useEffect, useContext } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { iProject } from 'interface/project';
import { useApiQuery } from 'utils/common';
import { ProjectServiceList, projectService } from 'services/project.service';
import { ProjectCreateService } from './ProjectCreateService';
import { ProjectOneServiceSettingsFormKEDA } from './ProjectService/ProjectOneServiceSettingsFormKEDA';
import { ProjectOneServiceSettingsFormRoutes } from './ProjectService/ProjectOneServiceSettingsFormRoutes';
import { ProjectOneServiceSettingsMainTab } from './ProjectService/MainTab';
import { ProjectOneServiceSettingsPostProcessing } from './ProjectService/PostProcessingTab';
import { ProjectOneServiceCustomYAML } from './ProjectService/ProjectOneServiceCustomYAML';
import { ProjectOneServiceDeploymentConfigurationTab } from './ProjectService/ProjectOneServiceDeploymentConfigurationTab';
import { ProjectDeploymentContext, ProjectDeploymentSyncVarsContext } from '../ProjectDeploymentContext';
import { ProjectOneServiceVariables } from './ProjectService/ProjectOneServiceVariables';
import { Row, Menu, Col, Button, Skeleton } from 'antd';
import { buttonBorder, innerSidebar, innerSidebarButton, menuButton, siderStyle, topMargined } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';

const { SubMenu, Item } = Menu;

export interface iProjectServicesPage {
  project: iProject | iProjectModel | any;
}

export const ProjectServices = ({ project }: iProjectServicesPage) => {
  // @ts-ignore
  const { serviceName, activeTabType } = useParams();
  const history = useHistory();

  const [serviceList, servicesError, servicesLoader] = useApiQuery(() => projectService.getProjectServiceList(project.id));
  const [selectedServiceName, setSelectedServiceName] = useState(serviceName);
  const selectedTabType = activeTabType || 'main';
  const services: ProjectServiceList[] = serviceList;

  const handleClick = (newSelectedServiceName: string, tabType: string, e?: any) => {
    setSelectedServiceName(newSelectedServiceName);
    history.push(`/project/${project.id}/settings/services/${newSelectedServiceName}/${tabType}`);
  };

  const deploymentContext = useContext(ProjectDeploymentContext);
  const deploymentId = deploymentContext?.deployment?.id;

  const [getSyncVars, { loading, error, data }] = useLazyQuery(
    gql`
      query DeploymentsController_debugUI_deploymentSyncVars($deploymentId: Int!) {
        DeploymentsController_debugUI_deploymentSyncVars(deploymentId: $deploymentId) {
          syncVars
        }
      }
    `,
  );

  const [service, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(project?.id, serviceName),
    [project?.id, serviceName],
  );

  useEffect(() => {
    if (deploymentId) getSyncVars({ variables: { deploymentId: deploymentId } });
  }, [deploymentId]);

  if (servicesError || servicesLoader || loading) return <Skeleton active={true} loading={true} />;

  if (!services || !services.length || selectedServiceName === 'new')
    return <ProjectCreateService projectId={project.id} firstService={!services || services?.length === 0} />;

  if (services && !selectedServiceName && services[0]) {
    setSelectedServiceName(services[0].name);
    return <Skeleton active={true} loading={true} />;
  }

  const projectFunctions = { service: service, project: project, serviceName: selectedServiceName, tabType: selectedTabType };

  return (
    <ProjectDeploymentSyncVarsContext.Provider
      value={{ serviceName: serviceName, syncVars: data?.DeploymentsController_debugUI_deploymentSyncVars?.syncVars }}
    >
      <Row gutter={[0, 0]} wrap={false} style={topMargined}>
        <Col flex={'250px'} style={innerSidebar}>
          <Menu
            style={{ ...siderStyle, paddingBottom: '73px' }}
            selectedKeys={[`${selectedServiceName}_${selectedTabType}`]}
            defaultOpenKeys={[selectedServiceName]}
            mode="inline"
          >
            {services.map(elem => {
              const serviceName = `${elem.name}`;
              const submenuItems = [
                { key: `${serviceName}_main`, label: 'Basic Details', tabType: 'main' },
                { key: `${serviceName}_variables`, label: 'Variables', tabType: 'variables' },
                { key: `${serviceName}_deployment-configuration`, label: 'Kube Details', tabType: 'deployment-configuration' },
                { key: `${serviceName}_custom-yaml`, label: 'Custom YAML Template', tabType: 'custom-yaml' },
                { key: `${serviceName}_route`, label: 'Routes', tabType: 'route' },
                { key: `${serviceName}_keda-autoscaling`, label: 'Autoscaling', tabType: 'keda-autoscaling' },
                { key: `${serviceName}_post-processing-rules`, label: 'Post Processing', tabType: 'post-processing-rules' },
              ];

              const subMenuData = () => (
                <SubMenu key={serviceName} title={serviceName} style={buttonBorder}>
                  {submenuItems.map(item => (
                    <Item key={item.key} onClick={(e: any) => handleClick(serviceName, item.tabType, e)}>
                      <Link to={`/project/${project.id}/settings/services/${serviceName}/${item.tabType}`}> {item.label} </Link>
                    </Item>
                  ))}
                </SubMenu>
              );

              return subMenuData();
            })}
          </Menu>
          <div style={menuButton}>
            <Link to={`/project/${project.id}/settings/services/new`}>
              <Button type="primary" style={innerSidebarButton}>
                Add a Service
              </Button>
            </Link>
          </div>
        </Col>
        <Col flex="auto">
          {{
            main: <ProjectOneServiceSettingsMainTab deployment={null} {...projectFunctions} />,
            variables: <ProjectOneServiceVariables {...projectFunctions} />,
            'post-processing-rules': <ProjectOneServiceSettingsPostProcessing {...projectFunctions} />,
            'keda-autoscaling': <ProjectOneServiceSettingsFormKEDA {...projectFunctions} />,
            route: <ProjectOneServiceSettingsFormRoutes {...projectFunctions} />,
            'custom-yaml': <ProjectOneServiceCustomYAML {...projectFunctions} />,
            'deployment-configuration': <ProjectOneServiceDeploymentConfigurationTab {...projectFunctions} />,
          }[selectedTabType] || null}
        </Col>
      </Row>
    </ProjectDeploymentSyncVarsContext.Provider>
  );
};

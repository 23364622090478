import { KeyOutlined } from '@ant-design/icons';
import { Button, Modal, Skeleton, Space, Switch } from 'antd';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { authService } from 'services/auth.service';
import { usersService } from 'services/users.service';
import { useApiQuery } from 'utils/common';
import { useAuthedMutation } from 'utils/qlAuth';

export const UserApiKeys = (props: { userId: number }) => {
  const [saveAllowApi] = useAuthedMutation(gql`
    mutation UsersController_allowApi($userId: Int!, $allowApi: Boolean!) {
      UsersController_allowApi(userId: $userId, allowApi: $allowApi)
    }
  `);
  const [userData, err, load] = useApiQuery(() => usersService.getUser(props.userId), [props.userId]);
  const [allowApi, setAllowApi] = useState(userData?.allowApi);
  useEffect(() => {
    setAllowApi(userData?.allowApi);
  }, [userData]);

  if (load) {
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <Space direction="horizontal">
      Allow api{' '}
      <Switch
        onChange={v => {
          setAllowApi(v);
          saveAllowApi({ variables: { userId: Number(props.userId), allowApi: v } });
        }}
        checkedChildren="On"
        unCheckedChildren="OFF"
        defaultChecked={allowApi}
      />
      <Button
        disabled={!allowApi}
        onClick={() => {
          Modal.confirm({
            title: 'Confirm?',
            content: `Do you want to get new api key and remove the old one?`,
            okText: 'Ok',
            cancelText: 'Cancel',
            onOk: async () => {
              const query = await authService.apolloQuery({
                query: gql`
                  mutation UsersController_getApiKey($userId: Int!) {
                    UsersController_getApiKey(userId: $userId)
                  }
                `,
                variables: { userId: Number(props.userId) },
              });

              const resPromis = query.data?.UsersController_getApiKey;

              Modal.success({
                title: 'Api key',
                content: (
                  <>
                    <p>Getting API Key</p>
                    <CodeLine>{resPromis}</CodeLine>
                    You can pass this key to `x-auth-token`
                  </>
                ),
                icon: <KeyOutlined />,
                closable: true,
              });
            },
          });
        }}
      >
        Get API Key
      </Button>
    </Space>
  );
};

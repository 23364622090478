import _ from 'lodash';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { HelpNoteAboutGoTemplates } from './HelpNotes';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { iIngressRoute, iIngressRouteOnePath } from 'shared/project.interface';
import { TitleUIRow } from 'layout/TitleUI';
import { Alert, Button, Collapse, Form, Input, InputNumber, Radio, Skeleton, Space, notification, Typography, Row, Col, Flex } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { spaceWidth, buttonWidth, fieldWidth, buttonBorder } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

export interface ProjectOneServiceSettingsFormRoutesProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
}

const { Group } = Radio;
const { Panel } = Collapse;
const { Text } = Typography;
const { Item, List } = Form;

export const ProjectOneServiceSettingsFormRoutes = (props: ProjectOneServiceSettingsFormRoutesProps) => {
  const projectId = props.project.id;
  const serviceName = props.serviceName;
  const tabType = props.tabType;
  // const [form] = Form.useForm();

  const [service, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(projectId, serviceName),
    [projectId, serviceName, tabType],
  );

  if (serviceError || serviceLoader) return <Skeleton active={true} loading={true} />;

  const routesListForm = () => {
    const radioOptions = [
      { value: 'Prefix', label: 'Prefix' },
      { value: 'Exact', label: 'Exact' },
    ];

    const routesInputs = [
      { label: 'Path', name: 'path', placeholder: 'Enter Path Here', defaultValue: '/', type: 'Input' },
      { label: 'Backend Service Name', name: 'backend_service_name', placeholder: 'Enter Backend Service Name Here', type: 'Input' },
      { label: 'Port Number', name: 'backend_service_port_number', placeholder: 'Enter Port Number Here', type: 'InputNumber' },
      { label: 'Path type', name: 'pathType', defaultValue: 'Prefix', type: 'RadioGroup', options: radioOptions },
    ];

    const panelHeader = <Text strong> Routes </Text>;

    return (
      <List name="routes">
        {(subFields, subOpt) => (
          <Space direction="vertical" style={spaceWidth}>
            <Collapse size="small" defaultActiveKey={subFields.map(s => s.name)}>
              {subFields.map(subField => (
                <Panel header={panelHeader} key={subField.name} extra={<CloseOutlined onClick={() => subOpt.remove(subField.name)} />}>
                  <Space direction="vertical" style={spaceWidth}>
                    <Row>
                      <Col span={12}>
                        <Text strong> Host Name </Text>
                      </Col>
                      <Col span={12}>
                        <Item noStyle name={[subField.name, 'host']}>
                          <Input placeholder="Enter the Host Name Here" style={fieldWidth} defaultValue={`{{ .deployment.domain }}`} />
                        </Item>
                      </Col>
                    </Row>
                    <List name={[subField.name, 'http_paths']}>
                      {(subFields_paths, subOpt_paths) => (
                        <>
                          {subFields_paths.map((subField_path, index) => (
                            <Space direction="vertical" key={subField_path.key} style={spaceWidth}>
                              <Flex justify={'flex-end'}>
                                <TipTop tip="Remove Additional Path">
                                  <Button
                                    danger
                                    type="primary"
                                    key={index}
                                    icon={<CloseOutlined />}
                                    onClick={() => subOpt_paths.remove(subField_path.name)}
                                    style={buttonWidth}
                                  />
                                </TipTop>
                              </Flex>
                              {routesInputs.map(field => (
                                <Row key={field.name}>
                                  <Col span={12}>
                                    <Text strong> {field.label} </Text>
                                  </Col>
                                  <Col span={12}>
                                    <Item noStyle name={[subField_path.name, field.name]}>
                                      {field.type === 'Input' && (
                                        <Input placeholder={field.placeholder} style={fieldWidth} defaultValue={field.defaultValue} />
                                      )}
                                      {field.type === 'InputNumber' && <InputNumber placeholder={field.placeholder} style={fieldWidth} />}
                                      {field.type === 'RadioGroup' && (
                                        <Group defaultValue={field.defaultValue}>
                                          {field.options.map(option => (
                                            <Radio key={option.value} value={option.value}>
                                              {option.label}
                                            </Radio>
                                          ))}
                                        </Group>
                                      )}
                                    </Item>
                                  </Col>
                                </Row>
                              ))}
                            </Space>
                          ))}
                          <Flex justify={'flex-end'}>
                            <Button type="primary" onClick={() => subOpt_paths.add()}>
                              Add an additional path
                            </Button>
                          </Flex>
                        </>
                      )}
                    </List>
                  </Space>
                </Panel>
              ))}
            </Collapse>
            <Button type="primary" onClick={() => subOpt.add()} style={buttonWidth}>
              Add Routes
            </Button>
          </Space>
        )}
      </List>
    );
  };

  const mainFormData = () => {
    const handleSubmit = async (data: { routes: any[] }) => {
      const serviceObj = {
        tabType: tabType,
        name: serviceName,
        data: {
          routes: data.routes.map((route): iIngressRoute => {
            return {
              // iIngressRoute
              host: route.host || '{{ .deployment.domain }}',
              http: {
                paths: (route?.http_paths || []).map(
                  (path: { path: any; pathType: any; backend_service_name: any; backend_service_port_number: any }): iIngressRouteOnePath => {
                    return {
                      // iIngressRouteOnePath
                      path: path?.path || '/',
                      pathType: path?.pathType || 'Prefix',
                      backend: { service: { name: path?.backend_service_name, port: { number: path?.backend_service_port_number || 80 } } },
                    };
                  },
                ),
              },
            };
          }),
        },
      };
      const validate = serviceObj.data.routes.find((route: iIngressRoute) =>
        !route.http.paths.length
          ? (notification.error({
              key: 'RoutesNotEmpty',
              message: `Please check your routes config.`,
              description: `All routes should have at least one path.`,
            }),
            true)
          : route.http.paths.find((path: iIngressRouteOnePath) => {
              const showError = (message: string) => {
                notification.error({ key: 'NameNotEmpty', message: `Please check your routes config.`, description: message });
                return true;
              };
              return !path?.backend?.service?.name
                ? showError('Service name is required.')
                : !path?.backend?.service?.port?.number
                ? showError('Port number is required.')
                : !path?.path
                ? showError('Path is required.')
                : false;
            }),
      );
      if (validate) {
        return;
      }
      console.log(`handleSubmit: `, serviceObj);
      const res = await projectService.setProjectServices(projectId, serviceObj);
      res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
    };

    const formRoutes = (service?.routes || []).map((route: iIngressRoute, index: number) => {
      return {
        key: index,
        host: route.host,
        http_paths: route?.http?.paths.map((path: iIngressRouteOnePath) => {
          return {
            path: path.path,
            backend_service_name: path?.backend?.service?.name,
            backend_service_port_number: path?.backend?.service?.port?.number,
            pathType: path.pathType,
          };
        }),
      };
    });

    return (
      <Form
        onFinish={handleSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        name="dynamic_form_complex"
        autoComplete="off"
        initialValues={{ routes: formRoutes }}
        style={spaceWidth}
      >
        {routesListForm()}
        <Text /> <Text /> <Text /> <Text />
        <Item>
          <BottomButtons>
            <Button type="primary" htmlType="submit" style={buttonBorder}>
              Save
            </Button>
          </BottomButtons>
        </Item>
      </Form>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title={`Routes configuration for ${serviceName}`} />
      <Text />
      <Alert type="info" showIcon message={<HelpNoteAboutGoTemplates />} />
      {mainFormData()}
    </Space>
  );
};

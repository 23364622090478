import { Link } from 'react-router-dom';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { Button, Result, Space, Typography } from 'antd';
import { CaretLeftFilled } from '@ant-design/icons';
import { blackText, noPageFoundStyle } from 'utils/styles';
import { TipBottom } from '../Tooltip/Tooltip';

const { Title, Text } = Typography;

const NotFoundPage = ({ me }) => {
  const notFoundData = () => {
    const resultTitle = (
      <Title level={4} style={blackText}>
        No Data found
      </Title>
    );

    const resultSubTitle = (
      <Text style={blackText}>The content you are looking for does not exists, please click the below button to navigate back to the portal...</Text>
    );

    const resultExtra = () => {
      return (
        <TipBottom tip="Click here to navigate back to portal">
          <Link to="/">
            <Button type="primary" icon={<CaretLeftFilled />}>
              Back to Portal
            </Button>
          </Link>
        </TipBottom>
      );
    };

    return (
      <Space style={noPageFoundStyle}>
        <Result status="404" title={resultTitle} subTitle={resultSubTitle} extra={resultExtra()} />
      </Space>
    );
  };

  if (!userHasPermission(me, UserPermissions.Viewer)) return notFoundData();
  return notFoundData();
};

export default NotFoundPage;

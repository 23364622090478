import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';

export const DeploymentApplyWidget = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;

  if (!deployment.useManualApply) {
    return null;
  }

  return (
    <Link to={`/app/${deployment.id}/status/deploy`}>
      <Button type={deployment.hasChangesForApply ? 'primary' : 'default'} size="small">
        Check {`&`} Deploy
      </Button>
    </Link>
  );
};

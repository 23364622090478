import gql from 'graphql-tag';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from '../../../interface/common';
import { useAuthedMutationWithNotification } from '../../../utils/qlAuth';
import { iJobModel, iRegionModel } from 'shared/deployment';
import { ShowJobLogsBtn } from './JobLogs';
import { Button, Popconfirm, Space } from 'antd';
import { buttonBorder } from 'utils/styles';
import { TipTopLeft } from 'components/SharedComponents/Tooltip/Tooltip';

interface ButtonJobControlProps {
  region: iRegionModel;
  showJobList: any;
  refetchJobList: any;
  clusterAreReady: boolean;
}

export const ButtonJobControl = (props: ButtonJobControlProps) => {
  const [newJobFunction, newJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_newJob($regionId: Int!) {
      JobsController_newJob(regionId: $regionId) {
        id
        status
        region
        createdAt
        updatedAt
        variables
      }
    }
  `);

  const [destroyPlanJobFunction, destroyPlanJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_newDestroyPlanJob($regionId: Int!) {
      JobsController_newDestroyPlanJob(regionId: $regionId) {
        id
      }
    }
  `);

  const [ebsJobFunction, ebsJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_InstallEBS_Job($regionId: Int!) {
      JobsController_InstallEBS_Job(regionId: $regionId) {
        id
      }
    }
  `);

  const [installKarpenter_Job, InstallKarpenter_JobId] = useAuthedMutationWithNotification(gql`
    mutation JobsController_InstallKarpenter_Job($regionId: Int!) {
      JobsController_InstallKarpenter_Job(regionId: $regionId) {
        id
      }
    }
  `);

  const [installApiAgent_Job, installApiAgent_JobId] = useAuthedMutationWithNotification(gql`
    mutation JobsController_InstallApiAgent_Job($regionId: Int!) {
      JobsController_InstallApiAgent_Job(regionId: $regionId) {
        id
      }
    }
  `);

  useEffect(() => {
    props.refetchJobList();
  }, [newJobRes, destroyPlanJobRes, ebsJobRes, InstallKarpenter_JobId, installApiAgent_JobId]);

  let lastPlanJob: iJobModel = (props.showJobList || []).find((job: iJobModel) => job.tfMode === 'plan' && job.status === `finished`);
  let planIsDone = lastPlanJob?.id;
  let lastDestroyPlanJob: iJobModel = (props.showJobList || []).find((job: iJobModel) => job.tfMode === 'destroy-plan' && job.status === `finished`);

  const clusterPlan = () => {
    const handleButton = async () => await newJobFunction({ variables: { regionId: Number(props.region.id) } });
    const clusterPlan = props.clusterAreReady;
    return (
      <TipTopLeft
        tip={
          clusterPlan
            ? 'This will create a new plan job. It will create a new plan job and run the terraform plan command. It will not apply any changes to your infrastructure.'
            : 'This will create a new plan job. It will create a new plan job and run the terraform plan command. It will not apply any changes to your infrastructure. This plan is for Cluster creation.'
        }
      >
        <Button onClick={handleButton} type={!planIsDone ? 'primary' : 'default'} style={buttonBorder}>
          {clusterPlan ? `Cluster modification plan` : `Cluster creation plan`}
        </Button>
      </TipTopLeft>
    );
  };

  const popDestroy = () => {
    const popDestroyDescription = (
      <ul>
        <li> This will create a destroy plan. </li>
        <li> It will create a new plan job and run the terraform plan command. </li>
        <li> It will not apply any changes to your infrastructure. </li>
      </ul>
    );
    const popDestroyOnConfirm = async e => {
      try {
        await destroyPlanJobFunction({ variables: { regionId: props.region.id } });
      } catch (e) {
        console.error(`Error in destroyPlanJobFunction`, e);
      }
    };
    return (
      <Popconfirm
        title="Cluster destroy plan"
        description={popDestroyDescription}
        onConfirm={popDestroyOnConfirm}
        okText="Continue"
        cancelText="Cancel"
      >
        <TipTopLeft tip="Initiating a new plan job that will execute the terraform plan command, providing an overview of the changes without applying them. This plan focuses on the removal of the cluster.">
          <Button hidden={!props.clusterAreReady} type="default" style={buttonBorder}>
            Destroy plan
          </Button>
        </TipTopLeft>
      </Popconfirm>
    );
  };

  const popInstall = () => {
    const popInstallDescription = (
      <ul>
        <li> This will install EBS addon into you cluster </li>
        <li>
          <Link to={`/clusters/${props.region.id}/integrations/10`}> Read more about EBS </Link>
        </li>
      </ul>
    );
    const popInstallOnConfirm = async e => {
      try {
        await ebsJobFunction({ variables: { regionId: Number(props.region.id) } });
      } catch (e) {
        console.error(`Error in createEbs`, e);
      }
    };
    const initializeEBS = props.region.plugins.includes('AWS-EBS-driver');
    return (
      <Popconfirm
        title="Install Amazon Elastic Block Store (Amazon EBS)"
        description={popInstallDescription}
        onConfirm={popInstallOnConfirm}
        okText="Continue"
        cancelText="Cancel"
      >
        <TipTopLeft
          tip={
            initializeEBS
              ? 'A plan job will be generated to execute the terraform plan command, outlining potential changes without implementing them. This job is aimed at reinstalling EBS.'
              : 'A new plan job will be initiated to execute the terraform plan command, previewing the configuration changes without making any actual modifications. This task is designed for installing the necessary components.'
          }
        >
          <Button
            hidden={!props.clusterAreReady || props.region.cloudProvider !== 'aws'}
            type={initializeEBS ? 'default' : 'primary'}
            style={buttonBorder}
          >
            {initializeEBS ? 'Reinstall' : 'Install'} EBS
          </Button>
        </TipTopLeft>
      </Popconfirm>
    );
  };

  const popInstalled = () => {
    const popInstalledDescription = (
      <ul>
        <li> This will install Karpenter into you cluster </li>
        <li>
          <Link to={`/clusters/${props.region.id}/integrations/5`}> Read more about Karpenter </Link>
        </li>
      </ul>
    );
    const popInstalledOnConfirm = async e => {
      try {
        await installKarpenter_Job({ variables: { regionId: Number(props.region.id) } });
      } catch (e) {
        console.error(`Error in installKarpenter_Job`, e);
      }
    };
    const initializeKarpenter = props.region.plugins.includes('Karpenter');
    return (
      <Popconfirm
        title="Install Karpenter"
        description={popInstalledDescription}
        onConfirm={popInstalledOnConfirm}
        okText="Continue"
        cancelText="Cancel"
      >
        <TipTopLeft
          tip={
            initializeKarpenter
              ? 'A new plan job will be created to perform the terraform plan command. This action will preview changes without affecting the current setup. This plan is for reinstalling Karpenter.'
              : 'A plan job will be initiated to run the terraform plan command, simulating the intended setup while leaving the current environment unchanged. This plan is intended for the installation process.'
          }
        >
          <Button
            hidden={!props.clusterAreReady || props.region.cloudProvider !== 'aws'}
            type={initializeKarpenter ? 'default' : 'primary'}
            style={buttonBorder}
          >
            {initializeKarpenter ? 'Reinstall' : 'Install'} Karpenter
          </Button>
        </TipTopLeft>
      </Popconfirm>
    );
  };

  const popReInstall = () => {
    const popReInstallDescription = (
      <ul>
        <li> Update {PROJECT_NAME} agent </li>
      </ul>
    );
    const popReInstallOnConfirm = async e => {
      try {
        await installApiAgent_Job({ variables: { regionId: Number(props.region.id) } });
      } catch (e) {
        console.error(`Error in installApiAgent_Job`, e);
      }
    };
    const agentName = `Reinstall ${PROJECT_NAME} agent`;
    return (
      <Popconfirm title={agentName} description={popReInstallDescription} onConfirm={popReInstallOnConfirm} okText="Continue" cancelText="Cancel">
        <TipTopLeft
          tip={`This operation will generate a new plan job to run the terraform plan command, simulating changes without applying them.The focus of this plan is the reinstallation of the ${PROJECT_NAME} agent.`}
        >
          <Button hidden={!props.clusterAreReady} type="default" style={buttonBorder}>
            {agentName}
          </Button>
        </TipTopLeft>
      </Popconfirm>
    );
  };

  const planControl = () => lastPlanJob && <ShowJobLogsBtn job={lastPlanJob} type={props.clusterAreReady ? `default` : `primary`} title={`Apply`} />;

  const destroyControl = () => lastDestroyPlanJob && <ShowJobLogsBtn job={lastDestroyPlanJob} type={`primary`} title={`Destroy`} />;

  return (
    <Space direction="horizontal">
      {clusterPlan()}
      {planControl()}
      {popDestroy()}
      {destroyControl()}
      {popInstall()}
      {popInstalled()}
      {popReInstall()}
    </Space>
  );
};

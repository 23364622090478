import { useState, useEffect } from 'react';
import { Skeleton, Button, notification, Result, Input, Form, Card, Space } from 'antd';
import { deploymentService, useGqlDeploymentById } from '../../../services/deployment.service';
import { useApiQueryLoop, useUser } from '../../../utils/common';
import { useHistory, useParams } from 'react-router-dom';
import { PoweroffOutlined, SyncOutlined } from '@ant-design/icons';
import { iDeployment, iStatusWebSsh } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { buttonBorder } from 'utils/styles';

export function DeploymentWebsshPage() {
  // @ts-ignore
  let { id } = useParams();
  const dpQuery = useGqlDeploymentById(id);
  if (dpQuery.loading) return <Skeleton active={true} loading={true} />;
  return <DeploymentWebsshConsole deployment={dpQuery.data?.DeploymentsController_getDeployment} />;
}

export function DeploymentWebsshConsole(props: { deployment: iDeployment }) {
  // @ts-ignore
  const history = useHistory();
  const [tunnelData, terror, tloading] = useApiQueryLoop(() => deploymentService.statusWebSsh(props.deployment.id));
  const tunnel: iStatusWebSsh = tunnelData;
  const user = useUser();
  const deployment: iDeployment = props.deployment;
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    terror ? history.push('/') : null;
  }, [terror, history]);

  if (tloading || showProgress) return <Skeleton active={true} loading={true} />;
  if (!userHasPermission(user, UserPermissions.DeploymentWebSSH)) return <UserNoPermissions permission={UserPermissions.DeploymentWebSSH} />;
  if (deployment.isReady === false)
    return <Result status="warning" title="Deployment is not ready" subTitle={`Please wait for deployment to be ready.`} />;

  const webResult = () => {
    const goButton = () => {
      const tunnelData = tunnel?.status === 'running' && tunnel?.tunnelStatus === 'ready';
      return (
        !tunnelData && (
          <Form key="goButton" action={tunnel.terminalURL} method="POST" target="_blank" style={{ display: 'inline-block' }}>
            <Input type="hidden" name="username" value={tunnel.login} />
            <Input type="hidden" name="userpassword" value={tunnel.password} />
            <Input type="hidden" name="header" value="SSH Agent" />
            <Button htmlType="submit"> Open console </Button>
          </Form>
        )
      );
    };

    const startButton = () => {
      const startWebSssh = async () => {
        setShowProgress(true);
        setShowProgress(false);
        const { status, error } = (await deploymentService.startWebSsh(props.deployment.id))?.data || {};
        status
          ? notification.success({ key: 'startWebSsh', message: 'Web SSH deployment started' })
          : notification.error({ key: 'startWebSsh', description: error || 'Error', message: 'Error' });
      };
      return (
        <Button type="primary" key="startButton" onClick={startWebSssh}>
          {tunnel?.status === 'not-started' ? 'Start session' : 'Restart session'}
        </Button>
      );
    };

    const stopButton = () => {
      const stopWebSsh = async () => {
        const { status, error } = (await deploymentService.stopWebSsh(props.deployment.id))?.data || {};
        status
          ? notification.success({ key: 'stopWebSsh', message: 'Web SSH deployment stopped' })
          : notification.error({ key: 'stopWebSsh', description: error || 'Error', message: 'Error' });
      };
      return (
        tunnel?.status !== 'not-started' && (
          <Button danger type="primary" key="stopButton" onClick={stopWebSsh} style={buttonBorder}>
            Stop session
          </Button>
        )
      );
    };

    const cardExtra = (
      <Space direction="horizontal">
        {goButton()} {startButton()} {stopButton()}
      </Space>
    );

    const resultData = () => {
      const { status: tunnelStatus, tunnelStatus: currentTunnelStatus, error } = tunnel || {};

      const resultStatus = tunnelStatus === 'running' ? 'success' : undefined;

      const resultIcon =
        tunnelStatus === 'pending' || currentTunnelStatus !== 'ready' ? (
          <SyncOutlined spin={tunnelStatus === 'pending'} />
        ) : tunnelStatus === 'not-started' ? (
          <PoweroffOutlined />
        ) : undefined;

      const resultText =
        tunnelStatus === 'running'
          ? 'You can open webssh console'
          : tunnelStatus === 'pending'
          ? 'Pod in pending state, please wait'
          : currentTunnelStatus !== 'ready'
          ? 'Tunnel is not ready, Please wait...'
          : tunnelStatus === 'not-started'
          ? 'SSH Agent is not running'
          : null;

      const resultSubTitle =
        tunnelStatus === 'pending'
          ? `Current pod status ${tunnelStatus}`
          : currentTunnelStatus !== 'ready'
          ? `Current Tunnel Status: ${JSON.stringify(currentTunnelStatus || error)}`
          : 'Web SSH console allows you to connect to a remote server via SSH.';

      return <Result status={resultStatus} icon={resultIcon} title={resultText} subTitle={resultSubTitle} />;
    };

    return (
      <Card type="inner" bordered={false} title="Web SSH console" extra={cardExtra}>
        {resultData()}
      </Card>
    );
  };

  return (
    <>
      <DeploymentWidgetsRow deployment={deployment} />
      {webResult()}
    </>
  );
}

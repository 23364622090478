import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { projectService } from 'services/project.service';
import { useUser } from 'utils/common';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { CustomWidgets } from 'form-components';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { Button, Drawer, notification, Space } from 'antd';
import { buttonSize, buttonWidth } from 'utils/styles';
import { LoadingOutlined } from '@ant-design/icons';

interface iNewProjectForm {
  openBtnUI: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

export const NewProjectForm = (props: iNewProjectForm) => {
  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);

  const user = useUser();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreateProject = async (e: { formData: { projectName: string } }) => {
    const res = await projectService.setNewProject({ projectName: e.formData.projectName });
    if (!res.data) {
      setLoading(false);
      return notification.error({ message: 'Error', description: res.error });
    }
    history.push(`/app/${res.data.deploymentId}/configuration/git`);
  };

  let yourForm;

  // Not authorized to access this page
  if (!userHasPermission(user, UserPermissions.ProjectsMange)) {
    return null;
  }

  const createButton = () => (
    <Space onClick={showDrawer} onKeyUp={showDrawer} tabIndex={0} role="button">
      {props.openBtnUI ? props.openBtnUI : ''}
    </Space>
  );

  const drawerFooter = (
    <Space wrap>
      <Button
        disabled={loading}
        type="primary"
        onClick={() => {
          setLoading(true);
          yourForm.submit();
        }}
        style={buttonWidth}
      >
        Next {`>`} Add a service {loading ? <LoadingOutlined /> : null}
      </Button>
      <Button disabled={loading} onClick={onClose} style={buttonSize}>
        Cancel
      </Button>
    </Space>
  );

  return (
    <Space direction="vertical">
      {createButton()}
      <Drawer title="Add a New Application" open={open} onClose={onClose} closable={true} width={800} footer={drawerFooter}>
        <Form
          widgets={CustomWidgets}
          uiSchema={{
            name: {
              'ui:autofocus': true,
              'ui:emptyValue': '',
              'ui:autocomplete': 'family-name',
              'ui:placeholder': 'Enter Name of the Project Here',
            },
            description: {
              'ui:autofocus': true,
              'ui:emptyValue': '',
              'ui:autocomplete': 'family-name',
              'ui:placeholder': 'Enter Project Description Here',
              'ui:widget': 'textarea',
            },
          }}
          schema={{
            type: 'object',
            required: ['projectName'],
            properties: {
              projectName: {
                type: 'string',
                title: 'Application Name',
              },
              description: {
                type: 'string',
                title: 'Application Description',
              },
            },
          }}
          validator={validator}
          templates={{ FieldTemplate: CustomFieldTemplate }}
          onSubmit={(e: any) => handleCreateProject(e)}
          onChange={() => console.log('Changed')}
          onError={(e: any) => console.log('Error from submit: ', e)}
          ref={form => (yourForm = form)}
        >
          {' '}
        </Form>
      </Drawer>
    </Space>
  );
};

import gql from 'graphql-tag';
import { ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { authService } from 'services/auth.service';
import { Row, Col, Typography, Button, Modal, Skeleton, Tag, Card, Space, Menu } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { absolutely, buttonWidthNew, spaceWidth, templateMargin } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';
import type { MenuProps } from 'antd';
import { TipLeftTop } from 'components/SharedComponents/Tooltip/Tooltip';

type MenuItem = Required<MenuProps>['items'][number];

interface iExistingContent {
  name: any;
  id: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  title: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

interface iTemplateSelectionList {
  preSelectedProjectId?: number;
  onSelect: any;
  selectBtnName: string;
  selectBtnTooltip: string;
}

const { Text } = Typography;

export function TemplateSelectionList(props: iTemplateSelectionList) {
  const { selectBtnTooltip, onSelect, selectBtnName } = props;
  const [selectedMenuKey, setSelectedMenuKey] = useState('1');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();
  const user = useUser();

  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        PreconfiguredProjectsController_getPreconfiguredProjects {
          id
          name
          title
          description
          logo
          tags
        }
      }
    `,
    {},
  );

  if (error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }
  if (loading) return <Skeleton active={true} loading={true} />;
  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;

  const templateData = () => (
    <Space direction="vertical" style={spaceWidth} data-qa="DeploymentFromTemplate.tsx">
      <Row gutter={[16, 16]}>
        {data.PreconfiguredProjectsController_getPreconfiguredProjects.map(elem => {
          const deployButton = (
            <TipLeftTop tip={selectBtnTooltip}>
              <Button type="primary" data-qa="PreconfiguredProjects-Deploy-btn" onClick={() => onSelect(elem)}>
                {selectBtnName}
              </Button>
            </TipLeftTop>
          );

          const deployTags = () => (
            <Space direction="horizontal">
              {elem.tags &&
                elem.tags.map(tag => (
                  <Tag color="green" key={tag}>
                    {tag}
                  </Tag>
                ))}
            </Space>
          );

          const extraCard = (projectId: number) => (
            <a href={`/project/${projectId}`}>
              <Button> Details </Button>
            </a>
          );

          const cardData = () => (
            <Col key={elem.id} xs={24} sm={12} md={8}>
              <Card size="small" type="inner" bordered={false} title={elem.title} extra={extraCard(elem.id)} style={templateMargin}>
                <Space direction="vertical">
                  <Space direction="horizontal">
                    <LogoAvatar logo={elem.logo} name={elem.title} />
                    <Text type="secondary"> {elem.description} </Text>
                  </Space>
                  <Text /> <Text /> <Text />
                  <Space direction="horizontal">
                    <Space style={absolutely}> {deployTags()} </Space>
                    <Space style={{ ...absolutely, right: 5 }}> {deployButton} </Space>
                  </Space>
                </Space>
              </Card>
            </Col>
          );

          return cardData();
        })}
      </Row>
    </Space>
  );

  const templateModalData = () => {
    const menuData = () => {
      const items: MenuItem[] = [
        { key: '1', icon: <InfoCircleOutlined />, label: 'All' },
        { key: '2', icon: <InfoCircleOutlined />, label: 'Personal' },
        { key: '3', icon: <InfoCircleOutlined />, label: 'Content Management' },
        { key: '4', icon: <InfoCircleOutlined />, label: 'DevOps Tools' },
        { key: '5', icon: <InfoCircleOutlined />, label: 'Database Management' },
        { key: '6', icon: <InfoCircleOutlined />, label: 'Monitoring & Logging' },
        { key: '7', icon: <InfoCircleOutlined />, label: 'Media & Entertainment' },
        { key: '8', icon: <InfoCircleOutlined />, label: 'Security' },
      ];
      const onClick: MenuProps['onClick'] = e => setSelectedMenuKey(e.key);
      const menuStyle: any = { width: 200, position: 'relative', left: -24, marginTop: -300, borderRight: '1px solid transparent' };
      return <Menu mode="inline" onClick={onClick} defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} items={items} style={menuStyle} />;
    };
    return (
      <>
        <Button type="primary" onClick={() => setIsModalOpen(true)} style={buttonWidthNew}>
          Deploy from public template
        </Button>
        <Modal
          title="Choose from one click deployment"
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={''}
          width={'95vw'}
          style={{ top: 20 }}
        >
          <Space direction="horizontal">
            {menuData()}
            {['1', '2', '3', '4', '5', '7', '8'].includes(selectedMenuKey) && <Text strong> Comming Soon... </Text>}
            {selectedMenuKey === '6' && templateData()}
          </Space>
        </Modal>
      </>
    );
  };

  return templateData();
}

/**
 * Create deployment from (/new route)
 * @returns
 */
export default function DeploymentFromTemplate() {
  const params: any = useParams();
  const history = useHistory();
  const preSelectedProject = Number(params?.projectId) || null;

  const [createDeployment, newDeployment] = useAuthedMutationWithNotification(gql`
    mutation DeploymentsController_createDeployment($projectId: Int!, $dpDeployFlow: String!, $name: String!, $tags: [Int]) {
      DeploymentsController_createDeployment(projectId: $projectId, dpDeployFlow: $dpDeployFlow, deploymentName: $name, deploymentTags: $tags) {
        id
      }
    }
  `);

  const handleCreateFromTemplate = async (project: iProjectModel) => {
    // const similarProjects = (data?.ProjectController_getProjectList || []).filter(project => project.id === projectId);
    // @todo: Add call to ProjectController_findSimilarProjects(projectId)
    // Can solve: https://nanoheal.atlassian.net/browse/DP-635
    // And if it returns a project, ask user if they want to use that project instead of creating a new one.
    // It needed for avoinding duplicating projects.

    const result = await authService.getApolloClient().query({
      query: gql`
        query ProjectController_findSimilarProjects($projectId: Int!) {
          ProjectController_findSimilarProjects(projectId: $projectId) {
            id
            name
            title
            description
            logo
            tags
          }
        }
      `,
      variables: { projectId: project.id },
    });

    const similarProjects = result?.data?.ProjectController_findSimilarProjects || [];

    const existingProjects = {
      title: 'Use Existing Project',
      icon: <InfoCircleOutlined />,
      content: (
        <>
          A similar project already exists. Do you want to use the existing project or create a new copy?
          {similarProjects.map((elem: iExistingContent) => {
            const handleButtonClick = async () =>
              await createDeployment({ variables: { projectId: elem.id, dpDeployFlow: `from-copy`, name: `New deployment ${elem.name}`, tags: [] } });
            return (
              <Button onClick={handleButtonClick}>
                Copy from ({elem.id}) {elem.title}
              </Button>
            );
          })}
        </>
      ),
      cancelText: 'Cancel',
      okText: 'Create a new copy',
      async onOk() {
        await createDeployment({ variables: { projectId: project.id, dpDeployFlow: `from-copy`, name: `New deployment ${project.name}`, tags: [] } });
      },
    };

    const creatingProjects = {
      title: 'Creating a deployment from a project template',
      icon: <InfoCircleOutlined />,
      content: (
        <Space direction="vertical">
          <Text> The project template will be automatically copied from the general template library and added to your own project list. </Text>
          <Text> You can find it on the Projects tab and edit it if you wish. </Text>
        </Space>
      ),
      async onOk() {
        await createDeployment({
          variables: { projectId: project.id, dpDeployFlow: `from-template`, name: `New deployment ${project.name}`, tags: [] },
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    };

    Modal.confirm(similarProjects.length > 0 ? existingProjects : creatingProjects);
  };

  const newDeploymentId = newDeployment?.data?.DeploymentsController_createDeployment?.id;
  if (newDeploymentId) {
    history.push(`/app/${newDeploymentId}/setting/details`);
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <TemplateSelectionList
      preSelectedProjectId={preSelectedProject}
      onSelect={handleCreateFromTemplate}
      selectBtnName={`Deploy`}
      selectBtnTooltip={'You can deploy preconfigured ready-made project. It allow to deploy any software in your cluster in several minutes.'}
    />
  );
}

import { Space, Typography } from 'antd';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;

const deploymentDescription = (
  <Space direction="horizontal">
    Name must be unique within a namespace.&nbsp;
    <Text strong>
      <TipTop tip="Click here to learn more">
        <a target="_blank" href={'https://kubernetes.io/docs/concepts/overview/working-with-objects/names/'}>
          see more...
        </a>
      </TipTop>
    </Text>
  </Space>
);

export const kind_Deployment = {
  type: 'object',
  properties: {
    apiVersion: { type: 'string', default: 'apps/v1', required: true },
    kind: { type: 'string', default: 'Deployment', required: true },
    metadata: {
      type: 'object',
      required: true,
      properties: {
        name: { required: true, title: 'Deployment name', type: 'string', description: deploymentDescription },
        // xTest: { type: 'boolean' },
        labels: { type: 'object', additionalProperties: { type: 'string' } },
        annotations: { type: 'object', additionalProperties: { type: 'string' } },
      },
    },
    spec: {
      type: 'object',
      properties: {
        selector: { type: 'object', properties: { matchLabels: { type: 'object', properties: { octopusexport: { type: 'string' } } } } },
        revisionHistoryLimit: { description: 'The number revisions to retain.', type: 'number' },
        replicas: { description: 'The number of pod replicas to create from this deployment.', type: 'number' },
        progressDeadlineSeconds: {
          description:
            "The maximum time for a deployment to make progress before it's considered to be failed. Blue/Green deployments will point the service to the new deployment only once the new deployments has succeeded",
          type: 'number',
        },
        strategy: {
          type: 'object',
          properties: { type: { enum: ['Recreate', 'RollingUpdate'], default: 'Recreate' } },
          allOf: [
            {
              if: { properties: { type: { const: 'RollingUpdate' } } },
              then: {
                properties: { rollingUpdate: { type: 'object', properties: { maxUnavailable: { type: 'string' }, maxSurge: { type: 'string' } } } },
              },
            },
          ],
        },
        template: {
          type: 'object',
          properties: {
            metadata: {
              type: 'object',
              properties: {
                labels: { type: 'object', additionalProperties: { type: 'string' } },
                annotations: { type: 'object', additionalProperties: { type: 'string' } },
              },
            },
            spec: {
              type: 'object',
              properties: {
                /** @todo volumes and containers */
                dnsPolicy: { type: 'string', enum: ['None', 'Default', 'ClusterFirstWithHostNet', 'ClusterFirst'], default: 'None' },
                dnsConfig: {
                  type: 'object',
                  properties: {
                    nameservers: {
                      type: 'array',
                      items: { description: 'A line separated list of up to 3 DNS name server IP addresses', type: 'string' },
                    },
                    searches: { type: 'array', items: { description: 'DNS search domains for host-name lookup.', type: 'string' } },
                    options: { type: 'array', items: { type: 'object', properties: { name: { type: 'string' }, value: { type: 'string' } } } },
                  },
                },
                hostNetwork: { type: 'boolean' },
                terminationGracePeriodSeconds: { type: 'number' },
                containers: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      name: { type: 'string' },
                      image: { type: 'string' },
                      ports: { type: 'array', items: { type: 'object', properties: { containerPort: { type: 'number' } } } },
                    },
                  },
                },
                readinessGates: { type: 'array', items: { type: 'object', properties: { conditionType: { type: 'string' } } } },
                tolerations: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      key: { description: 'The key of the taint to tolerate', type: 'string' },
                      operator: { description: 'Operators are Exists or Equal', type: 'string' },
                      value: { description: 'Value should only be set if operator is Equal', type: 'string' },
                      effect: { type: 'string' },
                    },
                  },
                },
                securityContext: {
                  type: 'object',
                  properties: {
                    fsGroup: { description: 'A special supplemental group ID that applies to all containers in a pod', type: 'string' },
                    runAsGroup: {
                      description: 'The group ID to run the entrypoint of the container process. Introduced in Kubernetes 1.10',
                      type: 'number',
                    },
                    runAsNonRoot: { type: 'boolean' },
                    sysctls: { type: 'array', items: { type: 'object', properties: { name: { type: 'string' }, value: { type: 'string' } } } },
                    seLinuxOptions: {
                      type: 'object',
                      properties: {
                        level: { description: 'The SELinux level that applies to the container', type: 'string' },
                        role: { description: 'The SELinux role that applies to the container', type: 'string' },
                        type: { description: 'The SELinux type that applies to the container.', type: 'string' },
                        user: { description: 'The SELinux user that applies to the container', type: 'string' },
                      },
                    },
                  },
                },
                serviceAccountName: { type: 'string' },
                affinity: {
                  type: 'object',
                  properties: {
                    nodeAffinity: {
                      type: 'object',
                      properties: {
                        requiredDuringSchedulingIgnoredDuringExecution: {
                          type: 'object',
                          properties: {
                            nodeSelectorTerms: {
                              type: 'array',
                              items: {
                                properties: {
                                  matchExpressions: {
                                    type: 'object',
                                    properties: {
                                      key: { type: 'string' },
                                      operator: { type: 'string' },
                                      values: { type: 'array', items: { type: 'string' } },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        preferredDuringSchedulingIgnoredDuringExecution: {
                          type: 'array',
                          items: {
                            type: 'object',
                            properties: {
                              weight: { type: 'number' },
                              preference: {
                                type: 'object',
                                properties: {
                                  matchExpressions: {
                                    type: 'array',
                                    items: { type: 'object', properties: { key: { type: 'string' }, operator: { type: 'string' } } },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    podAffinity: {
                      type: 'object',
                      properties: {
                        requiredDuringSchedulingIgnoredDuringExecution: {
                          type: 'array',
                          items: {
                            type: 'object',
                            properties: {
                              topologyKey: { type: 'string' },
                              labelSelector: {
                                type: 'object',
                                properties: {
                                  matchExpressions: {
                                    type: 'array',
                                    items: {
                                      type: 'object',
                                      properties: {
                                        key: { type: 'string' },
                                        operator: { type: 'string' },
                                        values: { type: 'array', items: { type: 'string' } },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        preferredDuringSchedulingIgnoredDuringExecution: {
                          type: 'array',
                          items: {
                            type: 'object',
                            properties: {
                              weight: { type: 'number' },
                              podAffinityTerm: {
                                type: 'object',
                                properties: {
                                  topologyKey: { type: 'string' },
                                  labelSelector: {
                                    type: 'object',
                                    properties: {
                                      matchExpressions: {
                                        type: 'array',
                                        items: {
                                          type: 'object',
                                          properties: {
                                            key: { type: 'string' },
                                            operator: { type: 'string' },
                                            values: { type: 'array', items: { type: 'string' } },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    podAntiAffinity: {
                      type: 'object',
                      properties: {
                        requiredDuringSchedulingIgnoredDuringExecution: {
                          type: 'array',
                          items: {
                            type: 'object',
                            properties: {
                              topologyKey: { type: 'string' },
                              labelSelector: {
                                type: 'object',
                                properties: {
                                  matchExpressions: {
                                    type: 'array',
                                    items: {
                                      type: 'object',
                                      properties: {
                                        key: { type: 'string' },
                                        operator: { type: 'string' },
                                        values: { type: 'array', items: { type: 'string' } },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        preferredDuringSchedulingIgnoredDuringExecution: {
                          type: 'array',
                          items: {
                            type: 'object',
                            properties: {
                              weight: { type: 'number' },
                              podAffinityTerm: {
                                type: 'object',
                                properties: {
                                  topologyKey: { type: 'string' },
                                  labelSelector: {
                                    type: 'object',
                                    properties: {
                                      matchExpressions: {
                                        type: 'array',
                                        items: {
                                          type: 'object',
                                          properties: {
                                            key: { type: 'string' },
                                            operator: { type: 'string' },
                                            values: { type: 'array', items: { type: 'string' } },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

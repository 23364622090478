import { Form, Button, Dropdown, Space, Menu } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { CheckOutlined, EditOutlined, PlusOutlined, StarOutlined, StopOutlined } from '@ant-design/icons/lib';
import { PageContainer } from '@ant-design/pro-layout';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';

import { ModalForm, ProFormCheckbox, ProFormDigit, ProFormList, ProFormSelect, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import Link from 'antd/lib/typography/Link';

interface CustomColumn extends ProColumns<any> {
  render?: (dom: any, entity: any) => JSX.Element;
  dataIndex: string;
  title: string | any;

  valueType?: string | any;
}

interface iProTableApiProps {
  opt: {
    columns: CustomColumn[];
    primaryKey: string;
    actions?: any[];
    canCreate?: boolean;
    canUpdate?: boolean;
    canDelete?: boolean;
  };
  Add?: any;
  onAction?: any;
  Update: any;
  getData: any;
  extraButtons?: any[];
}

export const ProTableApi = (props: iProTableApiProps) => {
  const opt = props.opt;

  const Add = props.Add;
  const Update = props.Update;
  const getData = props.getData;
  const onAction = props.onAction;
  const extraButtons = props.extraButtons || [];

  const [currentRow, setCurrentRow] = useState(null);

  const [form] = Form.useForm();
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (currentRow) {
      form?.setFieldsValue(currentRow);
    }
    form?.resetFields();
  }, [currentRow]);

  const newBtn = !!Add ? (
    <Button
      type="primary"
      key="primary"
      onClick={() => {
        setCurrentRow(null);
        form?.resetFields();
        handleModalVisible(true);
      }}
    >
      <PlusOutlined /> New
    </Button>
  ) : null;

  const actionRef = useRef<ActionType>();

  const handleAction = async (action: any, selectedRows: any[]) => {
    if (onAction) {
      await onAction(action, selectedRows);
    }
  };

  const handleShowEdit = async (entity: any) => {
    await setCurrentRow({ ...entity });
    form?.resetFields();
    // await setMyImages([{ key: 'dsf', val: entity.name }]);
    // await setShowDetail(false);
    // await handleUpdateModalVisible(true);
    await handleModalVisible(true);
  };

  const columns: CustomColumn[] = opt.columns.map(column => {
    let hidden = false;
    if (column.hideInTable == true) {
      hidden = true;
    }
    const result = {
      title: column.title,
      dataIndex: column.dataIndex,
      hideInTable: hidden,
      sorter: true,
      render: (dom: any, entity: any) => {
        if (column.valueType === 'text' || column.valueType === 'textarea') {
          return <div>{entity[column.dataIndex]}</div>;
        }
        if (column.valueType === 'boolean') {
          if (entity[column.dataIndex]) {
            return <CheckOutlined style={{ color: '#1f6d00' }} />;
          }
          return <StopOutlined style={{ color: '#8b5b00' }} />;
        }
        if (column.dataIndex === opt.primaryKey) {
          let menuNotEmpty = opt.canUpdate;
          const menu = (
            <Menu>
              {opt.canUpdate ? (
                <Menu.Item
                  key="edit"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setCurrentRow({ ...entity });
                    form?.resetFields();
                    // setMyImages([{ key: 'dsf', val: entity.name }]);
                    // setShowDetail(false);
                    // handleUpdateModalVisible(true);
                    handleModalVisible(true);
                  }}
                >
                  Edit
                </Menu.Item>
              ) : null}
              {opt.actions
                ? opt.actions
                    .filter(action => {
                      return true;
                    })
                    .map((action, index) => {
                      menuNotEmpty = true;
                      const btnProps: any = {};

                      btnProps.icon = action.icon || <StarOutlined />;

                      const actionData = action;
                      return (
                        <Menu.Item
                          key={index}
                          {...btnProps}
                          onClick={async () => {
                            await handleAction(actionData, [entity]);
                            if (actionRef.current) actionRef.current.reload();
                          }}
                        >
                          {action.name}
                        </Menu.Item>
                      );
                    })
                : null}
            </Menu>
          );

          let dropdownProps: any = {};
          if (menuNotEmpty) {
            dropdownProps.overlay = menu;
          }
          // @ts-ignore
          const elem = (
            <Link
              onClick={async () => {
                await handleShowEdit(entity);
              }}
              style={{ color: '#000' }}
              {...dropdownProps}
            >
              {dom}
            </Link>
          );
          return (
            <Space>
              <Dropdown.Button
                onClick={() => {
                  setCurrentRow({ ...entity });
                  form?.resetFields();
                  // setMyImages([{ key: 'dsf', val: entity.name }]);
                  // setShowDetail(true);
                  // handleUpdateModalVisible(false);
                  handleModalVisible(false);
                }}
                {...dropdownProps}
              >
                {elem}
              </Dropdown.Button>
            </Space>
          );
        }
        if (column.valueType === 'dateTime') {
          let dateUnix = Number(entity[column.dataIndex]);
          const date = new Date(dateUnix);
          return <div>{date.toISOString().replace('T', ' ').substring(0, 19)}</div>;
        } else {
          return <div></div>;
        }
      },
    };
    return result;
  });

  return (
    <PageContainer>
      <ProTable
        headerTitle={'Versions'}
        rowKey="id"
        search={{ labelWidth: 120 }}
        toolBarRender={() => [...extraButtons, newBtn]}
        request={getData}
        columns={columns}
        actionRef={actionRef}
        onReset={() => getData({})}
        // rowSelection={{ onChange: (_, selectedRows) => setSelectedRows(selectedRows) }}
      />
      <ModalForm
        form={form}
        title={'New item'}
        width="500px"
        visible={createModalVisible}
        onVisibleChange={v => {
          handleModalVisible(v);

          setTimeout(() => {
            form?.resetFields();
          }, 0);
        }}
        submitter={{
          render: props => [
            <Button type="primary" onClick={() => props.submit()}>
              Save
            </Button>,
          ],
        }}
        onFinish={async value => {
          columns.map((column: any) => {
            if (column.normalize) {
              value[column.dataIndex] = column.normalize(value[column.dataIndex]);
            }
            if (['json', 'array', 'map'].includes(column.valueType)) {
              try {
                value[column.dataIndex] = JSON.parse(value[column.dataIndex]);
              } catch (e) {
                console.warn(`JSON.parse Error (2)`, e);
              }
            }
          });

          handleModalVisible(false);
          if (currentRow && Object.keys(currentRow).length > 0) {
            await Update(value);
          } else {
            await Add(value);
          }
          setCurrentRow(null);
          form?.resetFields();

          if (actionRef.current) actionRef.current.reload();
        }}
      >
        {opt.columns
          .filter((column: any) => column.new != undefined)
          .map((column: any) => {
            const attr: any = {
              key: column.dataIndex,
              label: column.title,
              name: column.dataIndex,
              width: 'md',
              value: `${currentRow && currentRow[column.dataIndex] ? currentRow[column.dataIndex] : ''}`,
              rules: [
                {
                  required: column.new.required,
                  message: `${column.title} is required`,
                },
              ],
            };
            if (column.dataIndex == 'images') {
              const arrayImages = [];
              if (currentRow?.['images']) {
                const rowImages = currentRow['images'];
                for (var key of Object.keys(rowImages)) {
                  arrayImages.push({ key: key, val: rowImages[key] });
                }
              }

              return (
                <>
                  <ProFormList
                    name="images"
                    key={currentRow?.['id']}
                    initialValue={arrayImages}
                    creatorButtonProps={{
                      position: 'top',
                      creatorButtonText: 'Add image',
                    }}
                  >
                    <Space>
                      <ProFormText
                        name={'key'}
                        placeholder="name"
                        // onChange={(value) => handleImageChange(index, 'key', value)}
                      />
                      <ProFormText
                        name={'val'}
                        placeholder="value"
                        // onChange={(value) => handleImageChange(index, 'key', value)}
                      />
                    </Space>
                  </ProFormList>
                </>
              );
              // }
            }

            if (column.valueType === 'ProFormTextArea' || column.valueType === 'textarea') {
              return <ProFormTextArea {...attr} />;
            }
            if (['json', 'tags', 'array'].includes(column.valueType)) {
              return <ProFormTextArea {...attr} />;
            }
            if (['ProFormText', 'text', 'link'].includes(column.valueType)) {
              return <ProFormText {...attr} />;
            }
            if (['password'].includes(column.valueType)) {
              return <ProFormText.Password {...attr} />;
            }
            if (column.valueType === 'ProFormDigit' || column.valueType === 'number') {
              return <ProFormDigit {...attr} />;
            }
            if (column.valueType === 'ProFormSelect' || column.valueType === 'select') {
              return <ProFormSelect {...attr} />;
            }
            if (column.valueType === 'ProFormCheckbox' || column.valueType === 'boolean') {
              // attr.options = ['true', 'false'];

              return <ProFormCheckbox {...attr} />;
            }

            return <ProFormTextArea {...attr} />;
          })}
      </ModalForm>
    </PageContainer>
  );
};

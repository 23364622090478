import { deploymentService } from '../../../services/deployment.service';
import { notification } from 'antd';

export const updateDeployment = async (id: number, values: { vars?: {}; ingressAnnotations?: {}; services?: {} }) => {
  const res = await deploymentService.updateDeployment(id, values);
  return res.error
    ? notification.error({ description: res.data.message, message: '' })
    : res.status === 401
    ? (window.location.reload(), null)
    : notification.success({ description: 'Saved', message: '' });
};

export const layout = { labelCol: { span: 6 }, wrapperCol: { span: 20 } };

export const tailLayout = { wrapperCol: { offset: 4, span: 20 }, style: { textAlign: 'right' } };

import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { useHistory } from 'react-router-dom';
import { regionsService } from 'services/regions.service';
import { CustomWidgets } from 'form-components';
import { TitleUIRow } from 'layout/TitleUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Button, Col, notification } from 'antd';
import { buttonBorder } from 'utils/styles';
import { getUrlHashParams } from 'utils/common';

export interface iNewClusterPageProps {}

const NewRegionSchemaFormUI = {};

export const NewClusterPage = (props: iNewClusterPageProps) => {
  const history = useHistory();
  const addOptions = getUrlHashParams();

  const handleSubmit = async (e: any) => {
    const data = e.formData;

    if (addOptions.provider) {
      data.cloudProvider = addOptions.provider;
    }

    const hasCluster = addOptions?.hasCluster ? `?hasCluster=${addOptions.hasCluster}` : ``;

    const res = await regionsService.newRegion(data);
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
    history.push(res.error ? '/' : `/clusters/${res.data.id}${hasCluster}`);
  };

  const NewRegionSchemaForm: any = {
    required: ['name'],
    title: `New ${addOptions?.provider || ''} cluster`,
    type: 'object',
    properties: {
      name: { type: 'string', title: 'Cluster name', description: `The name of the cluster. It will be used in the UI and in the API.` },
    },
  };

  const clusterCreationStep1 = () => (
    <>
      <TitleUIRow title={`Configure own cluster`} />
      <Col className="gutter-row" span={12}>
        <Form
          widgets={CustomWidgets}
          formData={{}}
          schema={NewRegionSchemaForm}
          uiSchema={NewRegionSchemaFormUI}
          validator={validator}
          onSubmit={handleSubmit}
          onError={e => console.log('Error from submit: ', e)}
        >
          <BottomButtons>
            <Button type="primary" htmlType="submit" data-qa="NewClusterPage.tsx" style={buttonBorder}>
              Save & Continue
            </Button>
          </BottomButtons>
        </Form>
      </Col>
    </>
  );

  return clusterCreationStep1();
};

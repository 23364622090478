import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setPageWidgets } from 'services/actions';
import { iDeployment } from 'shared/deployment';
import { DeploymentErrors } from './DeploymentErrors';
import { Button, Dropdown, Image, Popover, Space, Switch, Tag, Typography } from 'antd';
import { CheckCircleOutlined, DownOutlined, LockOutlined, PauseCircleOutlined } from '@ant-design/icons';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';
import { DeploymentApplyWidget } from './SpecsRevisions/DeploymentApplyWidget';

const { Text } = Typography;

const DeploymentVersionWidget = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;

  return deployment.VersionModel ? (
    <Link to={`/app/${deployment.id}/advanced/version`}>
      <Tag> {`Version: ${deployment.VersionModel.channel} ${deployment.VersionModel.name}`} </Tag>
    </Link>
  ) : null;
};

export const DeploymentWidgetsRow = (props: { deployment: iDeployment }) => {
  const deployment = props.deployment;

  useEffect(() => {
    if (deployment) {
      const cloudProvider: any = deployment.RegionModel ? deployment.RegionModel.cloudProvider : 'on-premise';

      const srcImg: any =
        cloudProvider === 'aws'
          ? 'branding/aws.png'
          : cloudProvider === 'azure'
          ? 'branding/azure.svg'
          : cloudProvider === 'gcp'
          ? 'branding/gsp.svg'
          : 'branding/kubernetes.svg';

      const deploymentError = () => <DeploymentErrors deployment={deployment} />;

      const deploymentName = () =>
        deployment.VersionModel ? <Tag> {`Version: ${deployment?.VersionModel?.channel} ${deployment?.VersionModel?.name}`} </Tag> : null;

      const freezedStatus = () =>
        deployment.freezed ? (
          <Tag color="cyan">
            freezed <LockOutlined />
          </Tag>
        ) : null;

      const deploymentStatus = () =>
        deployment.isReady && !deployment.isEnabled ? (
          <TipTop tip="Deployment is disabled. You can Enable it in Settings.">
            <Link to={`/app/${deployment.id}/settings/delete`}>
              <Tag color="orange" icon={<PauseCircleOutlined />}>
                On Pause
              </Tag>
            </Link>
          </TipTop>
        ) : null;

      const deploymentDuration = () =>
        deployment.autoOff ? (
          <Link to={`/app/${deployment.id}/settings/delete`}>
            <Tag color="orange" icon={<PauseCircleOutlined />}>
              Auto off in 2 Days
            </Tag>
          </Link>
        ) : null;

      // const deploymentTitle = () => (
      //   <Link to={`/project/${deployment.ProjectModel?.id}/settings/general`}>
      //     <Tag icon={<LinkOutlined />} color="default">
      //       {deployment.ProjectModel?.title}
      //     </Tag>
      //   </Link>
      // );

      const deploymentPopover = () => {
        const popoverContent = (
          <Space direction="vertical">
            <Text> Name: {deployment?.RegionModel?.name} </Text>
            <Text> {(deployment?.RegionModel && `Nat IP: ${deployment.RegionModel.natIp}`) || `Nat IP is not configured`} </Text>
          </Space>
        );
        return (
          <Popover title="Region" trigger="hover" content={popoverContent}>
            <Link to={`/clusters/${deployment.RegionModel?.id}`}>
              <Tag> {deployment?.RegionModel?.title} </Tag>
            </Link>
            <Image src={srcImg} alt={cloudProvider} preview={false} style={{ height: '25px' }} />
          </Popover>
        );
      };

      const row = (
        <Space>
          <DeploymentApplyWidget deployment={deployment} />
          {deploymentError()}
          {/* {deploymentName()} */}
          <DeploymentVersionWidget deployment={deployment} />
          {freezedStatus()}
          {deploymentStatus()}
          {deploymentDuration()}
          {/* {deploymentTitle()} */}
          {deploymentPopover()}
        </Space>
      );
      setPageWidgets(row);
    }

    return () => {
      setPageWidgets(null);
    };
  }, [deployment]);

  return null;
};

import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { ReactElement, ReactNode, ReactPortal } from 'react';
import { projectService } from 'services/project.service';
import { CustomWidgets } from 'form-components';
import { useAuthedMutation } from 'utils/qlAuth';
import { ProjectController_removeService } from 'queries/queries';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Alert, Button, Col, Popconfirm, Space, Typography, notification } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { buttonBorder, buttonWidth, redColor, spaceWidth } from 'utils/styles';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';

export interface iProjectOneServiceSettingsFormProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
  service: iCloudProjectServiceYamlSpecs;
  deployment: iDeployment;
}

interface iVType {
  type: string | number | boolean | ReactElement<string> | Iterable<ReactNode>;
  cmd: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  message: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const oneServiceSettingsForm: any = {
  type: 'object',
  properties: {
    // UIshowRequestsConfig: {
    //   type: 'boolean',
    //   title: 'Show Requests Config In UI',
    //   default: false,
    // },
    enabledByDefault: {
      type: 'boolean',
      title: 'Enabled By Default',
      default: true,
    },
  },
};

const oneServiceSettingsUISchema = {};

const { Text } = Typography;

export const ProjectOneServiceSettingsMainTab = (props: iProjectOneServiceSettingsFormProps) => {
  const projectId = props.project.id;
  const serviceName = props.serviceName;
  const tabType = props.tabType;
  const service = props.service;

  const [removeServiceFunction, removeServiceRes] = useAuthedMutation(ProjectController_removeService);

  const handleSubmit = async e => {
    e.formData.helmChartName = e.formData.helmRepositoryChartName;
    const res = await projectService.setProjectServices(projectId, { tabType: tabType, name: e.formData.name, data: e.formData });
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
  };

  const tabGeneral = () => {
    const handleChanged = async e => {
      e.formData.helmChartName = e.formData.helmRepositoryChartName;
    };

    return (
      <Col span={20}>
        <Form
          widgets={CustomWidgets}
          formData={service}
          schema={oneServiceSettingsForm}
          uiSchema={oneServiceSettingsUISchema}
          validator={validator}
          onChange={handleChanged}
          onSubmit={handleSubmit}
          onError={e => console.log('Error in form submit: ', e)}
        >
          <BottomButtons>
            <Space direction="horizontal">
              <Button type="primary" htmlType="submit" style={buttonBorder}>
                Save
              </Button>
              <Popconfirm
                title="Are you sure?"
                placement="topLeft"
                icon={<WarningFilled style={redColor} />}
                okText="Continue"
                cancelText="Cancel"
                description={
                  <Space direction="vertical">
                    <Text>
                      Do you want to Delete the Service <Text strong> {serviceName} </Text> ?
                    </Text>
                    <Text /> <Text />
                    <Alert showIcon type="error" message={`This action is irreversible, and deletion will lead to the loss of data.`} />
                  </Space>
                }
                onConfirm={async () => {
                  await removeServiceFunction({ variables: { projectId: projectId, serviceName: serviceName } });
                  window.location.replace(`${window.location.origin}/app/${props?.deployment?.id}/configuration/new-service/add`);
                  return true;
                }}
              >
                <Button type="primary" danger style={buttonWidth}>
                  Delete Service
                </Button>
              </Popconfirm>
            </Space>
          </BottomButtons>
        </Form>
      </Col>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {tabGeneral()}
    </Space>
  );
};

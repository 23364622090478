import { Link } from 'react-router-dom';
import { PROJECT_NAME } from 'interface/common';
import { Card, Flex, Image, Space, Typography } from 'antd';
import { newImage } from 'utils/styles';
import { TipBottom } from 'components/SharedComponents/Tooltip/Tooltip';

const { Title, Text } = Typography;

const Center = ({ children }) => <Flex justify="center"> {children} </Flex>;

export function NewDeployment() {
  const heading = () => (
    <>
      <Text /> <Text />
      <Center>
        <Title level={3}> Ready to Get Started? </Title>
      </Center>
      <Center>
        <Text type="secondary"> Please choose where you want your application to run. </Text>
      </Center>
      <Text /> <Text />
    </>
  );

  const createCluster = () => {
    const commonTooltip = 'Create a cluster using';
    const existingData = [
      {
        name: 'Create a new cluster',
        path: '/cluster/new?provider=aws&hasCluster=false',
        tooltip: 'Create a new cluster',
        img: 'branding/newCluster.svg',
      },
      {
        name: 'Use an existing cluster',
        path: '/cluster/new?hasCluster=true',
        tooltip: 'Use an existing cluster',
        img: 'branding/newCluster.svg',
      },
      // { name: 'AWS', path: '/cluster/new?provider=aws', tooltip: `${commonTooltip} AWS`, img: 'branding/logoAWS.svg' },
      // { name: 'GCP', path: '/cluster/new?provider=gcp', tooltip: `${commonTooltip} GCP`, img: 'branding/logoGCP.svg' },
      // { name: 'Azure', path: '/cluster/new?provider=azure', tooltip: `${commonTooltip} Azure`, img: 'branding/logoAzure.svg' },
      // { name: 'K3s', path: '/cluster/new?provider=k3s', tooltip: `${commonTooltip} K3s`, img: 'branding/logoK3S.svg' },
      // { name: 'Other', path: '/cluster/new', tooltip: `${commonTooltip} Other cloud services`, img: 'branding/logoExtra.svg' },
      // { name: 'On-premise instance', path: '/new-intro', tooltip: `${commonTooltip} On-premise`, img: 'branding/newCluster.svg' },
    ];
    return (
      <Space direction="horizontal">
        {existingData.map(({ name, path, tooltip, img }, index) => (
          <>
            <TipBottom tip={tooltip}>
              <Link to={path}>
                <Card size="small" bordered={false}>
                  <Center>
                    <Image src={img} preview={false} height={30} />
                  </Center>
                  <Center>
                    <Title level={5}> {name} </Title>
                  </Center>
                  <Text type="secondary"> Hosted on your own cloud </Text>
                </Card>
              </Link>
            </TipBottom>
            {index !== existingData.length - 1 && <Text />}
          </>
        ))}
      </Space>
    );
  };

  const portalCluster = () => {
    const content = [
      { component: <Image src="branding/newCluster.svg" preview={false} height={60} /> },
      { component: <Title level={5}> {PROJECT_NAME} Shared Cluster </Title> },
      { component: <Text> Test drive {PROJECT_NAME}'s features on our shared cluster </Text> },
      { component: <Text type="secondary"> Free for 14 days </Text> },
    ];
    const newData = content.map(({ component }) => <Center> {component} </Center>);
    return (
      <>
        <Text />
        <Center>
          <Text strong> Or </Text>
        </Center>
        <Text />
        <TipBottom tip="Click here to create your own cluster">
          <Link to={'/new-intro'}>
            <Card size="small" bordered={false}>
              <Center>
                <Space direction="vertical"> {newData} </Space>
              </Center>
            </Card>
          </Link>
          <Flex justify="flex-end" style={newImage}>
            <Image src="branding/newClusterDesign.svg" preview={false} />
          </Flex>
        </TipBottom>
      </>
    );
  };

  return (
    <Center>
      <Space direction="vertical">
        <Space direction="vertical">
          {heading()}
          {createCluster()}
        </Space>
        {portalCluster()}
      </Space>
    </Center>
  );
}

import gql from 'graphql-tag';
import { ReactElement, useEffect, useState } from 'react';
import { projectService } from 'services/project.service';
import { useAuthedQuery } from 'utils/qlAuth';
import { createGitHubRepoForProject, getGitHubAllRepos, getGitHubRepoBranches, getGitHubToken, isValidGithubApiToken } from 'services/githubApi';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { ProjectGitBtn_Clone, ProjectGitBtn_Pull, ProjectGitBtn_push, projectGitPull } from './GitButtons';
import { Button, notification, Skeleton, Alert, Input, Space, Select, Form, Radio, Tag, Typography, Popover } from 'antd';
import { ArrowRightOutlined, GithubOutlined, KeyOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { spaceWidth, buttonBorder } from 'utils/styles';
import { ProjectGitConfigurationHelp } from './ProjectGitConfigurationHelp';
import { iProjectModel } from 'shared/deployment';
import { TipRightTop, TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

interface iProjectGitSettings {
  project: iProjectModel;
  showInDrawer?: boolean;
  onSave?: () => void;
}

const { Text, Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { Group } = Radio;

export const ProjectGitSettings = (props: iProjectGitSettings): ReactElement => {
  const projectId = props.project.id;

  const [dstBranch, setDstBranch] = useState(null);
  const [gitReposArray, setGitRepos] = useState([]);
  const [gitReposBranches, setGitRepoBranches] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [selectedGitHubRepo, setSelectedGitHubRepo] = useState(null);
  const [reLink, setReLink] = useState(false);

  const { loading, error, data, stopPolling, startPolling, refetch } = useAuthedQuery(
    gql`
      query ProjectGitController_checkGitLock($projectId: Int!) {
        ProjectGitController_checkGitLock(projectId: $projectId)
        ProjectController_getProject(projectId: $projectId) {
          gitInitialized
        }
        ProjectGitController_getGitConfiguration(projectId: $projectId) {
          gitToken
          gitUrl
          gitIntegrationType
          gitProjectPath
          gitBranch
          gitAuthType
        }
      }
    `,
    { variables: { projectId: projectId }, pollInterval: 10000 },
  );
  startPolling(10000);

  const git = data?.ProjectGitController_getGitConfiguration;
  let selectedAuthType = authType || git?.gitAuthType || 'github-oauth';

  const readyForGitOperations = git && git.gitUrl && git.gitBranch;
  const readyForGitPushOperations = git && git.gitUrl && git.gitBranch && git.gitToken;
  const repoIsLinked = !reLink && git?.gitUrl && git?.gitBranch;
  const gitInitialized = data?.ProjectController_getProject?.gitInitialized;
  const isValidGithubToken = isValidGithubApiToken();

  useEffect(() => {
    git ? setDstBranch(git.gitBranch) : null;
    isValidGithubToken ? (async () => setGitRepos(await getGitHubAllRepos()))() : null;
    return stopPolling;
  }, [git]);

  const handleSubmit = async (e: { gitProjectPath: string; gitAuthType: string; gitUrl: string; gitBranch: any; gitToken: string }) => {
    if (e.gitProjectPath === undefined) {
      e.gitProjectPath = '';
    }

    if (e.gitAuthType === 'github-oauth') {
      if (!selectedGitHubRepo) {
        notification.error({ message: 'Please select repository' });
        return;
      }
      e.gitUrl = String(selectedGitHubRepo?.clone_url).replace('http://', '').replace('https://', '');
      e.gitBranch = dstBranch;
      e.gitToken = getGitHubToken();
    } else {
      setDstBranch(e.gitBranch);
    }
    e.gitUrl = String(e.gitUrl).replace('http://', '').replace('https://', '');

    const res = await projectService.saveProjectGitConfig(projectId, e);
    refetch();
    if (res.error) {
      notification.error({ message: `Error: ${res.error}` });
    } else {
      if (props.onSave) {
        props.onSave();
      }
      notification.success({ message: 'Ready' });
    }

    if (readyForGitOperations && gitInitialized === false) {
      await projectGitPull(projectId);
    }
  };

  if (!git) return <Skeleton active={true} loading={true} />;

  let gitOAuthLink = `/api/github/oauth/repo-authorize?params=${encodeURIComponent(`gitconfig-${projectId}`)}`;
  if (window.location.hostname === 'localhost') {
    gitOAuthLink = `http://localhost:4001${gitOAuthLink}`;
  }

  const gitAlert = () => {
    const alertMessage = (
      <Space direction="vertical">
        <Text>
          You can use Github and Gitlab Repositories. You can create token in your
          <TipTop tip="Click here to login through your GitHub Account">
            <Text strong>
              <a href={'https://github.com/settings/tokens?type=beta'} target="_blank">
                &nbsp;GitHub&nbsp;
              </a>
            </Text>
          </TipTop>
          or
          <TipTop tip="Click here to login through your GitLab Account">
            <Text strong>
              <a href={'https://docs.gitlab.com/ee/user/project/settings/project_access_tokens.html#create-a-project-access-token'} target="_blank">
                &nbsp;GitLab&nbsp;
              </a>
            </Text>
          </TipTop>
          accounts. Project will use write and read access.
        </Text>
      </Space>
    );

    const alertAction = <ProjectGitConfigurationHelp btnText={<QuestionCircleTwoTone twoToneColor="#00A58E" />} />;

    return (
      <div hidden={props.showInDrawer}>
        <Alert showIcon type="info" message={alertMessage} action={alertAction} />
      </div>
    );
  };

  const errorAlert = () => error && <Alert type="warning" showIcon key={'error'} message="Error" description={JSON.stringify(error)} />;

  const busyAlert = () => {
    const showBusy = !props.showInDrawer && data && data.ProjectGitController_checkGitLockl;
    return (
      showBusy && (
        <Alert showIcon type="warning" message="Project is locked by another user. Please wait until all other users finish the work with git" />
      )
    );
  };

  const gitOperations = () => {
    const handleOnClick = () => setReLink(true);

    const buttons = () => (
      <BottomButtons>
        <Space direction="horizontal">
          <ProjectGitBtn_push disabled={!readyForGitPushOperations} projectId={projectId} onClick={refetch} dstBranch={dstBranch} />
          <ProjectGitBtn_Pull disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
          <ProjectGitBtn_Clone disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
        </Space>
      </BottomButtons>
    );

    return (
      repoIsLinked && (
        <>
          <Space direction="horizontal">
            <Text> Repository is linked with: </Text>
            <Tag color="green"> Branch {git?.gitBranch} </Tag>
            <Button type="dashed" size="small" onClick={handleOnClick}>
              Unlink
            </Button>
            <Text strong>
              <TipRightTop tip={git?.gitUrl}>
                <a href={`https://${git?.gitUrl}`} target="_blank">
                  Git link here
                </a>
              </TipRightTop>
            </Text>
          </Space>
          {!props.showInDrawer && buttons()}
        </>
      )
    );
  };

  const gitAuth = () => {
    const authType = () => {
      const handleOnChange = e => setAuthType(e.target.value);

      const gitIcon = (
        <>
          <GithubOutlined /> &nbsp; GitHub OAuth
        </>
      );

      const tokenIcon = (
        <>
          <KeyOutlined /> &nbsp; User & Password
        </>
      );

      const authButton = [
        { value: 'github-oauth', title: 'GitHub OAuth', icon: gitIcon },
        { value: 'token', title: 'Access token or password', icon: tokenIcon },
      ];

      return (
        <Item name="gitAuthType" label={<Text strong> Git authentication type </Text>}>
          <Group buttonStyle="solid" onChange={handleOnChange}>
            {authButton.map(({ value, title, icon }) => (
              <TipTop tip={title} key={value}>
                <Radio.Button value={value}> {icon} </Radio.Button>
              </TipTop>
            ))}
          </Group>
        </Item>
      );
    };

    const gitSign = () => (
      <a href={gitOAuthLink}>
        <Button type="primary" data-cy="git-repo-auth">
          Sign-in with GitHub <GithubOutlined />
        </Button>
      </a>
    );

    const newData = () => {
      const handleOnClickButton = async () => {
        const repo = await createGitHubRepoForProject(props.project);
        console.log(`Repo: ${repo}`);
        debugger;
        const res = await projectService.saveProjectGitConfig(projectId, {
          gitUrl: repo.clone_url,
          gitBranch: repo.default_branch,
          gitAuthType: 'github-oauth',
          gitToken: getGitHubToken(),
          gitProjectPath: '',
        });
        if (res.error) {
          notification.error({ message: `Error: ${res.error}` });
        } else {
          if (props.onSave) {
            props.onSave();
          }
          notification.success({ message: 'Ready' });
        }
        refetch();
      };

      const onSelectGithubRepo = async repoId => {
        const repo = gitReposArray.find(repo => repo.id === Number(repoId));
        const branches = await getGitHubRepoBranches(repo);
        setSelectedGitHubRepo(repo);
        if (Array.isArray(branches)) {
          setGitRepoBranches(branches);
        } else {
          debugger;
        }
      };

      return (
        <>
          <Button type="primary" onClick={handleOnClickButton}>
            New repo for this project
          </Button>
          <Item label="Repository">
            <Select style={spaceWidth} onChange={onSelectGithubRepo}>
              {gitReposArray.map(({ id, full_name, description }) => (
                <Option key={id}>
                  {full_name}
                  <Text italic style={{ color: '#999999' }}>
                    {description}
                  </Text>
                </Option>
              ))}
            </Select>
          </Item>
          {gitReposBranches && (
            <Item label="Branch">
              <Select style={spaceWidth} onChange={e => setDstBranch(e)}>
                {gitReposBranches.map(({ name }) => (
                  <Option key={name}> {name} </Option>
                ))}
              </Select>
            </Item>
          )}
        </>
      );
    };

    const formData = () => {
      const logo = (
        <>
          &nbsp; <ArrowRightOutlined /> &nbsp;
        </>
      );

      const gitTokenInfo = (
        <Space direction="vertical">
          <Title level={5}> Steps to get Access token </Title>
          <Text>
            Github {logo} Setting {logo} Developer Setting {logo} Personal Access Token {logo} Token (Classic) {logo} Generate New Token.
          </Text>
          <Text> Copy this access token and paste it in Git. </Text>
          <Text> Provide Git repository link without http:// </Text>
        </Space>
      );

      const formFields = [
        {
          name: 'gitUrl',
          label: `Git repository URL ${'\u00A0'.repeat(3)}`,
          placeholder: 'Enter URL (without `https://`) Here',
          rules: [{ required: true }],
          help: `Your git repository url should be in the format "gitlab.com/username/repo" or "github.com/username/project.git"`,
        },
        {
          name: 'gitToken',
          label: `Access Token ${'\u00A0'.repeat(14)}`,
          placeholder: 'Enter Access Token Here',
          type: 'password',
          help: gitTokenInfo,
        },
        {
          name: 'gitBranch',
          label: `Branch ${'\u00A0'.repeat(21)}`,
          placeholder: 'Enter Branch Here',
          rules: [{ required: true }],
          help: 'Branch name should be the name of the branch you want to use for this project',
        },
      ];

      return formFields.map(({ name, label, rules, placeholder, type, help }) => {
        const inputAddons = (
          <Popover placement="topLeft" title={help}>
            <QuestionCircleTwoTone twoToneColor="#00A58E" />
          </Popover>
        );

        const handleOnChange = e => {
          const { value } = e.target;
          e.target.value = value.startsWith('https://') ? value.slice(8) : value;
        };

        return (
          <Item key={name} name={name} label={<Text strong> {label} </Text>} rules={rules}>
            <Input placeholder={placeholder} type={type} addonAfter={inputAddons} onChange={handleOnChange} />
          </Item>
        );
      });
    };

    const bottomButtons = () => {
      const repoIsLinkedFormshowInDrawerTrue = () => (
        <Space>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save
          </Button>
        </Space>
      );

      const repoIsLinkedFormshowInDrawerFalse = () => (
        <Space>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save Git Configuration
          </Button>
          <ProjectGitBtn_push disabled={!readyForGitPushOperations} projectId={projectId} onClick={refetch} dstBranch={dstBranch} />
          <ProjectGitBtn_Pull disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
          <ProjectGitBtn_Clone disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
        </Space>
      );

      return <BottomButtons> {props.showInDrawer ? repoIsLinkedFormshowInDrawerTrue() : repoIsLinkedFormshowInDrawerFalse()} </BottomButtons>;
    };

    return (
      !repoIsLinked && (
        <Form onFinish={handleSubmit} initialValues={git}>
          {authType()}
          {selectedAuthType === 'github-oauth' ? (!isValidGithubToken ? gitSign() : newData()) : formData()}
          {bottomButtons()}
        </Form>
      )
    );
  };

  const gitReady = () => {
    const ready = readyForGitOperations && !props.showInDrawer && data?.ProjectController_getProject && gitInitialized === false;

    const alertDescription = (
      <Space direction="vertical">
        <Text>
          Set up a Git Repository Connection to your Project and Click to the <Text strong> "git pull" </Text> button.
        </Text>
        <Text> Projects Store their configuration in Git. This allows you to use Version history. </Text>
      </Space>
    );

    const alertAction = (
      <Button type="primary" onClick={() => projectGitPull(projectId)}>
        Pull files from Git
      </Button>
    );

    return (
      ready && (
        <Space direction="vertical" style={spaceWidth}>
          <Space>
            <Text />
          </Space>
          <Alert showIcon type="error" key={'gitInitialized'} message="Git is not Initialized" description={alertDescription} action={alertAction} />
        </Space>
      )
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {gitAlert()} <Text />
      {errorAlert()}
      {busyAlert()}
      {gitOperations()}
      {gitAuth()}
      {gitReady()}
    </Space>
  );
};

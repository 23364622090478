import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { updateDeployment } from '../common';
import { CustomWidgets } from 'form-components';
import { iDeployment, iDeploymentServicesConfig } from 'shared/deployment';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { Button, Skeleton, Space, Switch, Typography } from 'antd';
import { buttonBorder, buttonWidth } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { useState } from 'react';

const { Title } = Typography;

export const DeploymentOneServiceSettings = (props: {
  onSave?: () => void;
  serviceName: string;
  inPopup?: boolean;
  deployment: iDeployment;
  schema: iDeploymentServicesConfig;
}) => {
  const [stateService, setServiceState] = useState<boolean>(props.deployment?.services?.[props.serviceName]?.enabled);

  if (!props.deployment) {
    return <Skeleton active={true} loading={true} />;
  }

  const handleSubmit = async (e: any) => {
    const data = {};
    data[props.serviceName] = e.formData;

    const newData = { vars: data };
    newData[`service.${props.serviceName}.enabled`] = stateService;

    await updateDeployment(props.deployment.id, newData);
    if (props.onSave) {
      props.onSave();
    }
  };

  if (!props?.schema?.hasUIconfig) {
    return (
      <>
        <Title level={5}> Enable/Disable service {props.serviceName} </Title>
        <Switch onChange={setServiceState} checkedChildren="On" unCheckedChildren="OFF" defaultChecked={stateService} />
        <BottomButtons>
          <Space>
            {props.inPopup && (
              <Button
                style={buttonWidth}
                type="default"
                key="back"
                onClick={() => {
                  if (props.onSave) props.onSave();
                }}
              >
                Cancel
              </Button>
            )}
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleSubmit({ formData: {} });
              }}
              style={buttonBorder}
            >
              Save
            </Button>
          </Space>
        </BottomButtons>
      </>
    );
  }
  const FormSchema: any = props.schema.FormSchema;
  return (
    <>
      <Title level={5}> Enable/Disable service {props.serviceName} </Title>
      <Switch onChange={setServiceState} checkedChildren="On" unCheckedChildren="OFF" defaultChecked={stateService} />

      <Title level={5}> Configuration for service {props.serviceName} </Title>
      <Form
        widgets={CustomWidgets}
        formData={props.schema.serviceVarConfig}
        schema={FormSchema}
        uiSchema={props.schema.UISchema}
        validator={validator}
        onSubmit={handleSubmit}
        onError={(e: any) => console.log('Error from submit: ', e)}
        templates={{ FieldTemplate: CustomFieldTemplate }}
      >
        <BottomButtons>
          <Space>
            {props.inPopup && (
              <Button
                style={buttonWidth}
                type="default"
                key="back"
                onClick={() => {
                  if (props.onSave) props.onSave();
                }}
              >
                Cancel
              </Button>
            )}
            <Button type="primary" htmlType="submit" style={buttonBorder}>
              Save
            </Button>
          </Space>
        </BottomButtons>
      </Form>
    </>
  );
};

import _ from 'lodash';
import * as yaml from 'js-yaml';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { iCodeObjectBasic, PatchYamlAction, patchYamlCode } from '../utils/yaml-tools';
import { Button, Input, notification, Select, Skeleton, Space, Steps, Switch, Tag, Typography } from 'antd';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { createElement, useContext, useState } from 'react';
import { ProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { iCloudProjectServiceYamlSpecs, iIngressRoute, iIngressRouteOnePath } from 'shared/project.interface';
import { stringToYaml } from 'shared/yaml';
import { PersistentVolumeClaimMainOptionsUI } from '../PersistentVolumeClaimMainOptionsUI';
import { ConfigMapMainOptionsUI } from '../ConfigMapMainOptionsUI';
import { SecretsMainOptionsUI } from '../SecretsMainOptionsUI';
import { buttonBorder, dockerWidth } from 'utils/styles';
import { VolumesUI } from '../VolumesUI';
import { JsonFormModalUI } from '../JsonFormModalUI';
import { containerVolumeMountsDynamicSchema } from '../schema/ContainerVolumeMounts';
import { EditOutlined } from '@ant-design/icons';
import { ResourcesUI } from './ResourcesUI';
import { EnvVarsUI } from './EnvVarsUI';
import { ImagePullSecrets } from '../schema/ImagePullSecrets';
import { ScalingSettingsUI } from '../schema/ScalingSettingsUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { TipRight } from 'components/SharedComponents/Tooltip/Tooltip';

export interface iWCodeObjectArray extends iCodeObjectBasic {
  index: number;
  role: 'deployment' | 'statefulset' | 'service' | 'none';
}

interface iDockerWizardProps {
  fileName: string;
  serviceName: string;
  project: iProjectModel;
  deployment: iDeployment;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
}

function getDeploymentObj(yamlCodeObjectArray: iWCodeObjectArray[]) {
  const deploymentObj: iWCodeObjectArray =
    yamlCodeObjectArray.find(item => item.role === 'deployment') || yamlCodeObjectArray.find(item => item.role === 'statefulset') || null;
  return deploymentObj;
}

const { Text } = Typography;
const { Option } = Select;

/**
 * Main component to edit docker file
 * @param props
 * @constructor
 */
export const DockerWizard = (props: iDockerWizardProps) => {
  const { project, fileName } = props;
  const [content, fError, fLoader] = useApiQuery(() => projectService.getFileContent(project.id, fileName), [fileName]);
  const [isChanged, setChanged] = useState<boolean>(false);
  const [newCode, setNewCode] = useState<string>(null);
  const syncVarsContext = useContext(ProjectDeploymentSyncVarsContext);
  const [showVolumes, setShowVolumes] = useState<boolean>(false);
  const [customUrlTemplate, setCustomUrlTemplate] = useState<boolean>(false);
  const [addedDomain, setDomain] = useState<string>(undefined);

  const [service, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(props.project.id, props.serviceName),
    [props.project.id, props.serviceName],
  );

  const projService: iCloudProjectServiceYamlSpecs = service;
  const routes: iIngressRoute[] = projService?.routes || [];
  const yamlCodeString: string = newCode || content?.data || '';
  const yamlCodeObject: any[] = stringToYaml(yamlCodeString);

  const yamlCodeObjectArray: iWCodeObjectArray[] = (Array.isArray(yamlCodeObject) ? yamlCodeObject : []).map(
    (yamlObj, indexInArray): iWCodeObjectArray => {
      return { obj: yamlObj, index: indexInArray, role: (yamlObj?.metadata?.annotations?.['unifie/role'] || yamlObj.kind || 'none').toLowerCase() };
    },
  );
  const deploymentObj: iWCodeObjectArray = getDeploymentObj(yamlCodeObjectArray);

  const kedaScaledObject = yamlCodeObjectArray.find(item => {
    return (
      item.obj?.kind?.toLowerCase() === 'scaledobject' &&
      deploymentObj.obj?.kind?.toLowerCase() === item.obj?.spec?.scaleTargetRef?.kind?.toLowerCase() &&
      deploymentObj.obj?.metadata?.name?.toLowerCase() === item.obj?.spec?.scaleTargetRef?.name?.toLowerCase()
    );
  });

  const saveChanges = (yamlCodeObjectArray: iWCodeObjectArray[]) => {
    const newValue = yamlCodeObjectArray
      .filter(y => y.toRemove !== true)
      .map(yamlObj => yaml.dump(yamlObj.obj))
      .join('\n---\n');
    setChanged(true);
    setNewCode(newValue);
    console.log(`New value: ${newValue}`);
    if (props.onChange) props.onChange(newValue);
  };

  /**
   * Function to change any property in the current yaml object
   * @param key - path in the object
   * @param newObj - object to set or undefined if we want to unset
   * @param action - push, splice or undefined (set or unset)
   */
  const onChangeCurrentProperty = (indexInArray, key: string, newObj: any, action?: PatchYamlAction) => {
    yamlCodeObjectArray[indexInArray] = patchYamlCode<iWCodeObjectArray>(yamlCodeObjectArray[indexInArray], key, newObj, action);
    saveChanges(yamlCodeObjectArray);
  };

  const addNewItemIfNeeded = (newYaml: any) => {
    let obj = yamlCodeObjectArray.find(
      item => item.obj.metadata.name === newYaml.metadata.name && item.obj.kind?.toLowerCase() === newYaml.kind?.toLowerCase(),
    );
    if (obj) return obj; // Skip adding the same object twice
    obj = { obj: newYaml, index: yamlCodeObjectArray.length, role: newYaml.kind };
    yamlCodeObjectArray.push(obj);
    saveChanges(yamlCodeObjectArray);
    return obj;
  };

  const removeItemIfNeeded = (kind: string, name: string) => {
    let toRemove = yamlCodeObjectArray.find(item => item.obj.metadata.name === name && item.obj.kind?.toLowerCase() === kind?.toLowerCase());
    if (!toRemove) return false; // Skip adding the same object twice
    yamlCodeObjectArray[toRemove.index].toRemove = true;
    saveChanges(yamlCodeObjectArray);
    return true;
  };

  if (!deploymentObj) {
    // If we do not have deployment object - we will create it here
    addNewItemIfNeeded({
      apiVersion: 'apps/v1',
      kind: 'Deployment',
      metadata: { name: props.serviceName, labels: { app: props.serviceName } },
      spec: {
        selector: { matchLabels: { app: props.serviceName } },
        template: {
          metadata: { labels: { app: props.serviceName } },
          spec: { containers: [{ name: props.serviceName, image: 'karthequian/helloworld:latest', ports: [{ containerPort: 80, name: 'http' }] }] },
        },
      },
    });
    return <Skeleton active={true} loading={true} />;
  }

  const yName = deploymentObj?.obj?.metadata?.name || props.serviceName;
  const yContainer = deploymentObj?.obj?.spec?.template?.spec?.containers?.[0];
  const yInitContainer = deploymentObj?.obj?.spec?.template?.spec?.initContainers?.[0];
  const showVolumesUi = showVolumes || deploymentObj?.obj?.spec?.template?.spec?.volumes?.length > 0;

  const volumesDefintionUI = () => {
    const fileredData = volume => volume.persistentVolumeClaim !== undefined || volume.configMap !== undefined || volume.secret !== undefined;

    // Skip checks for emptyDir object types - due they do not have any configuration
    const mappedData = () => (current_volume, i) => {
      const handleOnChange = (key: string, newObj: any, action: PatchYamlAction) => onChangeCurrentProperty(configObj.index, key, newObj, action);

      const skipConfig = deploymentObj?.obj?.metadata?.annotations?.[`unifie_volume_${current_volume.name}`] === 'skip';
      let configKind = '';
      let configUI = null;
      let configObj = null;

      const volumeData = {
        persistentVolumeClaim: {
          kind: 'PersistentVolumeClaim',
          spec: { accessModes: ['ReadWriteOnce'], resources: { requests: { storage: '1Gi' } } },
          component: PersistentVolumeClaimMainOptionsUI,
        },
        configMap: { kind: 'ConfigMap', data: { 'example.txt': 'hello' }, component: ConfigMapMainOptionsUI },
        secret: { kind: 'Secret', type: 'Opaque', stringData: {}, component: SecretsMainOptionsUI },
      };

      Object.keys(volumeData).forEach(key => {
        current_volume[key]
          ? (() => {
              const { kind, ...rest } = volumeData[key];
              configKind = kind.toLowerCase();
              configObj = addNewItemIfNeeded({
                apiVersion: 'v1',
                kind,
                metadata: { name: current_volume[key].name || current_volume[key].secretName || current_volume[key].claimName },
                ...rest,
              });
              configUI = createElement(volumeData[key].component, { lockName: true, yamlObj: configObj.obj, onChange: handleOnChange });
            })()
          : null;
      });

      const mountDescription = () => {
        const handleOnChange = val =>
          onChangeCurrentProperty(deploymentObj.index, `.metadata.annotations.unifie_volume_${current_volume.name}`, val ? undefined : 'skip');
        return (
          <Space direction="vertical">
            <Switch
              checkedChildren={`Define ${configKind}`}
              unCheckedChildren="Skip configuration"
              defaultChecked={!skipConfig}
              onChange={handleOnChange}
            />
            <Text hidden={!skipConfig}>
              If this volume is already configured in another part of the yaml file or created in the cluster by another tool, you can skip
              configuration here.
            </Text>
            {!skipConfig && configUI}
          </Space>
        );
      };

      return { title: `Mount: ${configKind} ${current_volume.name}`, description: mountDescription() };
    };

    return (deploymentObj?.obj?.spec?.template?.spec?.volumes || []).filter(fileredData).map(mappedData());
  };

  // Use 'app' label to find the service object
  let k8sService = yamlCodeObjectArray.find(
    item => item.obj?.kind?.toLowerCase() === 'service' && item.obj?.spec?.selector?.app === deploymentObj.obj?.spec?.selector?.matchLabels?.app,
  );

  const dockerSteps = () => {
    const serviceNameDescription = () => {
      const handleOnChange = e => onChangeCurrentProperty(deploymentObj.index, `.metadata.name`, e.target.value);
      return <Input placeholder="Name" value={yName} onChange={handleOnChange} style={dockerWidth} />;
    };

    const serviceTypeDescription = () => {
      const handleOnChange = value => onChangeCurrentProperty(deploymentObj.index, `.kind`, value);
      return (
        <Space direction="vertical">
          <Select placeholder="Select state here" defaultValue="Deployment" onChange={handleOnChange} style={dockerWidth}>
            <Option value="Deployment">
              <TipRight tip="If you don't need to store any data on the service">
                Stateless - <Text type="secondary"> "Kubernetes Deployment" </Text>
              </TipRight>
            </Option>
            <Option value="StatefulSet">
              <TipRight tip="If you need to store data">
                Stateful - <Text type="secondary"> "Kubernetes StatefulSet" </Text>
              </TipRight>
            </Option>
          </Select>
          <Text type="secondary">
            Stateless type is preferd due it is simple to scale and deploy, but you can't store any data on the service and can be deployed on SPOT
            nodes for safe costs.
          </Text>
          <Text type="secondary">
            Stateful type services maintain session data and user state, enabling personalized experiences, but they require more resources and
            complex management.
          </Text>
        </Space>
      );
    };

    const diskVolumeTitle = () => {
      const hiddenData = deploymentObj?.obj?.spec?.template?.spec?.volumes?.length > 0;
      const handleOnChange = val => setShowVolumes(val);
      return (
        <span hidden={hiddenData}>
          <Space direction="horizontal">
            {'\u00A0'.repeat(24)}
            <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" defaultChecked={showVolumesUi} onChange={handleOnChange} />
          </Space>
        </span>
      );
    };

    const diskVolumeDescription = () => {
      const handleOnChange = (key: string, newObj: any, action: PatchYamlAction) => {
        const oldValue = _.get(deploymentObj.obj, key.replace('.', ''));
        const linkedSecret = yamlCodeObjectArray.find(item => item.obj.metadata.name === oldValue && item.obj?.kind?.toLowerCase() === 'secret');
        const linkedConfigmap = yamlCodeObjectArray.find(
          item => item.obj.metadata.name === oldValue && item.obj?.kind?.toLowerCase() === 'configmap',
        );
        const linkedVolume = yamlCodeObjectArray.find(
          item => item.obj.metadata.name === oldValue && item.obj?.kind?.toLowerCase() === 'persistentvolumeclaim',
        );
        if (key.includes('.secret.secretName')) {
          if (linkedSecret) onChangeCurrentProperty(linkedSecret.index, `.metadata.name`, newObj);
        } else if (key.includes('.configMap.name')) {
          if (linkedConfigmap) onChangeCurrentProperty(linkedConfigmap.index, `.metadata.name`, newObj);
        } else if (key.includes('.persistentVolumeClaim.claimName')) {
          if (linkedVolume) onChangeCurrentProperty(linkedVolume.index, `.metadata.name`, newObj);
        }
        onChangeCurrentProperty(deploymentObj.index, key, newObj, action);
      };
      return (
        <div hidden={!showVolumesUi}>
          <VolumesUI yamlObj={deploymentObj.obj} onChange={handleOnChange} />
        </div>
      );
    };

    const dockerImageDescription = () => {
      const handleOnChangeSwitch = val => {
        val
          ? onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].image`, `{{.Values.image.${props.serviceName}}}`)
          : onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].image`, `karthequian/helloworld:latest`);
      };

      const handleOnChangeInputA = e => onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].image`, e.target.value);

      const handleOnChangeInputB = e => {
        if (e.target.value === '') return onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].command`, undefined);
        onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].command`, e.target.value);
      };

      const handleOnClickButton = () => {
        JsonFormModalUI({
          formData: yContainer?.volumeMounts || [],
          formConfigs: containerVolumeMountsDynamicSchema(deploymentObj.obj, yContainer),
          SyncVarsContext: syncVarsContext,
          onChange: v => onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].volumeMounts`, v),
        });
      };

      const handleResources = (key, newEnv) => onChangeCurrentProperty(deploymentObj.index, key, newEnv);

      const handleENV = (key, newEnv) => onChangeCurrentProperty(deploymentObj.index, key, newEnv);

      const volumeCount = (deploymentObj.obj?.spec?.template?.spec?.containers?.[0]?.volumeMounts || []).length;

      return (
        <Space direction="vertical">
          <Space direction="horizontal">
            <Text type="secondary"> Use image from versions API {'\u00A0'.repeat(1)} </Text>
            <Switch
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              defaultChecked={yContainer?.image?.includes('{{.Values')}
              onChange={handleOnChangeSwitch}
            />
          </Space>
          <Input
            hidden={yContainer?.image?.includes('{{.Values')}
            placeholder="Image"
            value={yContainer?.image}
            onChange={handleOnChangeInputA}
            style={dockerWidth}
          />
          Start command
          <Input placeholder="Start command" value={yContainer?.command} onChange={handleOnChangeInputB} style={dockerWidth} />
          <Space direction="horizontal" hidden={!deploymentObj?.obj?.spec?.template?.spec?.volumes?.length}>
            Mount volumes
            <Button onClick={handleOnClickButton}>
              <EditOutlined />
            </Button>
            <Tag color={volumeCount ? 'green' : 'red'}> {volumeCount ? `${volumeCount} volumes` : 'Not configured'} </Tag>
          </Space>
          <ResourcesUI
            yamlObj={deploymentObj}
            path={`.spec.template.spec.containers.[0].resources`}
            onChange={handleResources}
            syncVarsContext={syncVarsContext}
          />
          <EnvVarsUI yamlObj={deploymentObj} path={`.spec.template.spec.containers[0].env`} onChange={handleENV} syncVarsContext={syncVarsContext} />
        </Space>
      );
    };

    const initContainerTitle = () => {
      const handleOnChange = val => {
        val
          ? onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].image`, `{{.Values.image.${props.serviceName}}}`)
          : onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers`, undefined);
      };
      return (
        <Space direction="horizontal">
          {'\u00A0'.repeat(24)}
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            defaultChecked={yInitContainer?.image !== undefined}
            onChange={handleOnChange}
          />
        </Space>
      );
    };

    const initContainerDescription = () => {
      const handleOnChangeSwitch = val => {
        val
          ? onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].image`, `{{.Values.image.${props.serviceName}}}`)
          : onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].image`, `karthequian/helloworld:latest`);
      };

      const handleOnChangeInputA = e => onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].image`, e.target.value);
      const handleOnChangeInputB = e => {
        if (e.target.value === '') return onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].command`, undefined);
        onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].command`, e.target.value);
      };

      const handleENV = (key, newEnv) => onChangeCurrentProperty(deploymentObj.index, key, newEnv);
      const handleResources = (key, newEnv) => onChangeCurrentProperty(deploymentObj.index, key, newEnv);
      const handleOnClickButton = () => {
        JsonFormModalUI({
          formData: yInitContainer?.volumeMounts || [],
          formConfigs: containerVolumeMountsDynamicSchema(deploymentObj.obj, yInitContainer),
          SyncVarsContext: syncVarsContext,
          onChange: v => onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].volumeMounts`, v),
        });
      };

      const initVolumeCount = (deploymentObj.obj?.spec?.template?.spec?.initContainers?.[0]?.volumeMounts || []).length;

      return yInitContainer?.image === undefined ? null : (
        <Space direction="vertical">
          <Text> You can also add a Init container to your application. Main application will start only after the Init container is finished. </Text>
          <Text> It can be used for example to run migrations or other tasks before the main application starts. </Text>
          <Space direction="horizontal">
            <Text type="secondary"> Use image from versions API {'\u00A0'.repeat(1)} </Text>
            <Switch
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              defaultChecked={yInitContainer?.image?.includes('{{.Values')}
              onChange={handleOnChangeSwitch}
            />
          </Space>
          <Input
            hidden={yInitContainer?.image?.includes('{{.Values')}
            placeholder="Image"
            value={yInitContainer?.image}
            onChange={handleOnChangeInputA}
            style={dockerWidth}
          />
          Start command
          <Input placeholder="Start command" value={yInitContainer?.command} onChange={handleOnChangeInputB} style={dockerWidth} />
          <EnvVarsUI
            yamlObj={deploymentObj}
            path={`.spec.template.spec.initContainers.[0].env`}
            onChange={handleENV}
            syncVarsContext={syncVarsContext}
          />
          <ResourcesUI
            yamlObj={deploymentObj}
            path={`.spec.template.spec.initContainers.[0].resources`}
            onChange={handleResources}
            syncVarsContext={syncVarsContext}
          />
          <Space direction="horizontal" hidden={!deploymentObj?.obj?.spec?.template?.spec?.volumes?.length}>
            {/* ToDo: Make it as separated component */}
            Mount volumes
            <Button onClick={handleOnClickButton}>
              <EditOutlined />
            </Button>
            <Tag color={initVolumeCount ? 'green' : 'red'}> {initVolumeCount ? `${initVolumeCount} volumes` : 'Not configured'} </Tag>
          </Space>
        </Space>
      );
    };

    const openPortsDescription = () => {
      const inputDefaultValue = yContainer?.ports
        .map(port => port?.containerPort)
        .filter(v => !!v)
        .join(',');

      const handleOnBlur = e => {
        const val = (e.target.value || '')
          .split(/[ ,]/gm)
          .map(v => parseInt(v, 10))
          .filter(v => !isNaN(v))
          .map(v => ({ containerPort: v, name: `port${v}` }));

        onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].ports`, val);

        const newPorts = yContainer?.ports.map(({ containerPort }) => {
          return { name: `port${containerPort}`, port: containerPort, protocol: 'TCP', targetPort: containerPort };
        });

        if (newPorts.length === 0) {
          if (k8sService) k8sService.toRemove = true;
        } else {
          k8sService = addNewItemIfNeeded({
            apiVersion: 'v1',
            kind: 'Service',
            metadata: { name: yName, labels: { app: yName } },
            spec: { selector: { app: yName }, ports: newPorts },
          });
          k8sService.toRemove = false;
          onChangeCurrentProperty(k8sService.index, `.spec.ports`, newPorts);
        }
      };

      return <Input placeholder="Port" defaultValue={inputDefaultValue} onBlur={handleOnBlur} style={dockerWidth} />;
    };

    const httpsRoutes = () => {
      const DefaultUrlTemplate = `{{ .UnifieVar.deployment.domain }}`;
      const autoAddServiceAndDomain = ![null, undefined, 'false', 'no'].includes(addedDomain);
      const customUrlTemplateUI = customUrlTemplate || addedDomain !== DefaultUrlTemplate;

      const httpRoutesDescription = () => {
        const handleOnChangeSwitchA = val => {
          val ? (setDomain(DefaultUrlTemplate), setChanged(true)) : (setDomain(undefined), setChanged(true));
          // onChangeCurrentProperty(k8sService.index, `.metadata.annotations['unifie/add-domain']`, DefaultUrlTemplate);
          // onChangeCurrentProperty(k8sService.index, `.metadata.annotations['unifie/add-domain']`, undefined, 'unset');
          setCustomUrlTemplate(false);
        };

        const handleOnChangeSwitchB = val => {
          val ? setCustomUrlTemplate(true) : (setCustomUrlTemplate(false), setDomain(DefaultUrlTemplate), setChanged(true));
          // onChangeCurrentProperty(k8sService?.index, `.metadata.annotations['unifie/add-domain']`, DefaultUrlTemplate);
        };

        const handleOnBlur = e => {
          setDomain(e.target.value || '');
          setChanged(true);
          // onChangeCurrentProperty(k8sService?.index, `.metadata.annotations['unifie/add-domain']`, val);
        };

        return (
          <Space direction="vertical">
            <Space direction="horizontal">
              Add URL for port&nbsp;
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                onChange={handleOnChangeSwitchA}
                defaultChecked={autoAddServiceAndDomain}
              />
            </Space>
            <div hidden={!autoAddServiceAndDomain}>
              Use custom URL template&nbsp;
              <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" onChange={handleOnChangeSwitchB} checked={customUrlTemplateUI} />
            </div>
            <Input
              hidden={!autoAddServiceAndDomain || !customUrlTemplateUI}
              placeholder="Domain name template"
              defaultValue={addedDomain || DefaultUrlTemplate}
              onBlur={handleOnBlur}
              style={dockerWidth}
            />
          </Space>
        );
      };

      return (
        yContainer?.ports.length > 0 &&
        k8sService && { title: `Http route for port ${yContainer?.ports[0]?.containerPort}`, description: httpRoutesDescription() }
      );
    };

    const imagePullSecretsDescription = () => {
      const imageData = deploymentObj.obj?.spec?.template?.spec?.imagePullSecrets || [];
      const handleOnChange = function (v): void {
        onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.imagePullSecrets`, v);
      };
      return (
        <ImagePullSecrets deployment={props.deployment} imagePullSecrets={imageData} syncVarsContext={syncVarsContext} onChange={handleOnChange} />
      );
    };

    const configureScalingDescription = () => {
      const handleOnSave = function (type, replicas, KedaObjectSpec): void {
        debugger;
        if (!kedaScaledObject && KedaObjectSpec) addNewItemIfNeeded(KedaObjectSpec); // Add new Keda object
        if (kedaScaledObject && KedaObjectSpec) onChangeCurrentProperty(kedaScaledObject.index, '', KedaObjectSpec); // Update existing Keda object
        if (kedaScaledObject && !KedaObjectSpec) removeItemIfNeeded(kedaScaledObject.obj.kind, kedaScaledObject.obj.metadata.name); // Remove existing Keda object
        onChangeCurrentProperty(deploymentObj.index, `.spec.replicas`, replicas, replicas ? 'unset' : 'set'); // Update deployment replicas
      };
      return (
        <ScalingSettingsUI
          deployment={props.deployment}
          deploymentObj={deploymentObj}
          kedaScaledObject={kedaScaledObject}
          syncVarsContext={syncVarsContext}
          onSave={handleOnSave}
        />
      );
    };

    const stepsItems = [
      { title: <Text strong> Service name: {yName} </Text>, description: serviceNameDescription() },
      { title: <Text strong> Service type: </Text>, description: serviceTypeDescription() },
      { title: <Text strong> Disk volumes: {diskVolumeTitle()} </Text>, description: diskVolumeDescription() },
      ...volumesDefintionUI(),
      { title: <Text strong> Docker image: </Text>, description: dockerImageDescription() },
      { title: <Text strong> Init container: {initContainerTitle()} </Text>, description: initContainerDescription() },
      { title: <Text strong> Open ports: </Text>, description: openPortsDescription() },
      httpsRoutes(),
      { title: <Text strong> Image pull secrets: </Text>, description: imagePullSecretsDescription() },
      { title: <Text strong> Configure scaling: </Text>, description: configureScalingDescription() },
    ];

    return <Steps progressDot direction="vertical" items={stepsItems} />;
  };

  const saveButton = () => {
    /**
     * Save all config to the file on the server backend
     * @param value
     */
    const handleSaveFile = async value => {
      setChanged(false);
      if (props.onSubmit) props.onSubmit(value);
      const newValue = yamlCodeObjectArray
        .filter(y => y.toRemove !== true)
        .map(yamlObj => yaml.dump(yamlObj.obj))
        .join('\n---\n');
      if (newValue) {
        const res = await projectService.setFileContent(project.id, fileName, newValue);
        if (res.error) return notification.error({ message: `Error: ${res.error}` });
      }

      const targetPort = Number(k8sService?.obj?.spec?.ports?.[0]?.port) || 0;
      let newRoutes = routes;

      if (!addedDomain || yContainer?.ports.length <= 0 || targetPort <= 0) {
        // remove route if no domain is set
        newRoutes = routes
          .map(route => {
            route.http.paths = route.http.paths.filter(path => path.backend.service.name !== k8sService?.obj?.metadata?.name);
            return route;
          })
          .filter(route => route.http.paths.length > 0);
      } else {
        let findRoute = false;
        newRoutes = routes.map((route: iIngressRoute) => {
          const main: iIngressRouteOnePath = route.http.paths.find(path => path.backend.service.name === k8sService?.obj?.metadata?.name);
          if (main) {
            route.http.paths = route.http.paths.map((path: iIngressRouteOnePath): iIngressRouteOnePath => {
              if (path.backend.service.name !== k8sService?.obj?.metadata?.name) return path; // Skip other services
              if (path.path !== '/') return path; // Skip custom path configured on 'Routes' tab
              findRoute = true;
              path.backend.service.port.number = targetPort;
              return path;
            });
            route.host = addedDomain;
          }
          return route;
        });
        if (!findRoute) {
          newRoutes.push({
            host: addedDomain,
            http: {
              paths: [
                { path: '/', pathType: 'Prefix', backend: { service: { name: k8sService?.obj?.metadata?.name, port: { number: targetPort } } } },
              ],
            },
          });
        }
      }
      const res = await projectService.setProjectServices(project.id, { name: props.serviceName, data: { routes: newRoutes } });
      if (res.error) notification.error({ message: `Error: ${res.error}` });
      notification.success({ message: 'Saved' });
    };

    return (
      <BottomButtons>
        <Button type="primary" htmlType="submit" onClick={handleSaveFile} disabled={!isChanged} style={buttonBorder}>
          Save
        </Button>
      </BottomButtons>
    );
  };

  if (fLoader || fError || serviceLoader || serviceError) return <Skeleton active={true} loading={true} />;
  return (
    <>
      {dockerSteps()}
      {saveButton()}
    </>
  );
};

import * as jForm from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { useState } from 'react';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { schemaFormService } from 'services/schemaForm.service';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Alert, Button, Form, Skeleton, Switch, Tabs, notification, Space, Typography } from 'antd';
import { ApartmentOutlined, AppstoreOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;
const { TabPane } = Tabs;
const { Item } = Form;

interface iProjectGeneralProps {
  project: iProjectModel;
}

export const ProjectEnvSettings = (props: iProjectGeneralProps) => {
  const project = props.project;
  const [schema, schemaError, schemaLoader] = useApiQuery(() => schemaFormService.getEnv(project.id));
  const [env, envError, envLoader] = useApiQuery(() => projectService.getProjectEnv(project.id));
  const [showEnvForm, setShowForm] = useState(false);

  if (schemaError || schemaLoader || envError || envLoader) {
    return <Skeleton active={true} loading={true} />;
  }
  if (!schema || !env) {
    return <Skeleton active={true} loading={true} />;
  }

  const primaryTabPane = () => {
    const itemsData = () => {
      const items = [
        { name: 'showEnvVarsTab', label: 'Add the Environment variables configuration tab in the Deployment UI', value: project.showEnvVarsTab },
        {
          name: 'autoAddEnvVarsToDeployments',
          label: 'Add Environment variables to all Deployments in this project automatically',
          value: project.autoAddEnvVarsToDeployments,
        },
      ];
      return items.map(item => (
        <Item key={item.name} name={item.name} label={item.label} initialValue={item.value}>
          <Switch defaultChecked={item.value} />
        </Item>
      ));
    };

    const alertData = () => {
      const alertDescription = (
        <ul>
          <li> This vars will be used as default environment variables for Deployment. </li>
          <li> You can override them in the deployment form. </li>
        </ul>
      );
      const alertAction = (
        <TipLeft tip="Click here to know more...">
          <a href="https://www.unifie.cloud/post/variables" target="_blank">
            <Button type="text">
              <QuestionCircleTwoTone twoToneColor="#00A58E" />
            </Button>
          </a>
        </TipLeft>
      );
      return <Alert showIcon type="info" message="How to use template variables ?" description={alertDescription} action={alertAction} />;
    };

    const handleSaveAndContinue = async values => {
      debugger;
      const res = await projectService.setProjectSettings(project.id, values);
      res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
      setShowForm(values.showEnvVarsTab || values.autoAddEnvVarsToDeployments);
    };

    return (
      <Form onFinish={handleSaveAndContinue}>
        {itemsData()}
        {alertData()}
        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save and continue
          </Button>
        </BottomButtons>
      </Form>
    );
  };

  const showForm = showEnvForm || project.showEnvVarsTab || project.autoAddEnvVarsToDeployments;

  const secondaryTabPane = () => {
    const handleSubmit = async e => {
      const res = await projectService.setProjectEnv(project.id, e.formData);
      res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
    };

    return (
      <jForm.Form
        formData={env}
        schema={schema.envSettingSchema}
        uiSchema={schema.ui}
        validator={validator}
        onSubmit={handleSubmit}
        templates={{ FieldTemplate: CustomFieldTemplate }}
        onError={e => e}
      >
        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonBorder}>
            Save
          </Button>
        </BottomButtons>
      </jForm.Form>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {!showForm && primaryTabPane()}
      {showForm && (
        <Tabs defaultActiveKey={'General'}>
          <TabPane
            key="general"
            tab={
              <Text>
                <AppstoreOutlined /> General
              </Text>
            }
          >
            {primaryTabPane()}
          </TabPane>
          <TabPane
            key="env"
            disabled={!showForm}
            tab={
              <Text>
                <ApartmentOutlined /> Environment Variables
              </Text>
            }
          >
            {secondaryTabPane()}
          </TabPane>
        </Tabs>
      )}
    </Space>
  );
};

export const layout = { labelCol: { span: 7 }, wrapperCol: { span: 8 } };

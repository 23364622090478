import { ReactElement, useState } from 'react';
import { Button, Form, Input, Modal, notification, Space, Typography } from 'antd';
import { CopyServiceButton } from './CopyService';
import { projectService } from 'services/project.service';
import { useHistory } from 'react-router-dom';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { buttonBorder } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { TemplateSelectionList } from 'components/Deployments/New/DeploymentFromTemplate';
import { PlusOutlined } from '@ant-design/icons';
import { TipBottomLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { isServiceNameValid } from 'shared/services';

interface iNewService {
  deployment: iDeployment;
}

const { Text, Title } = Typography;
const { Item } = Form;

export const NewService = (props: iNewService): ReactElement => {
  const projectId = props.deployment?.projectId;
  const history = useHistory();
  const [serviceType, setServiceType] = useState(null);

  const handleCreateService = async (e: any) => {
    if (e.name === 'new') {
      // name `new` is reserved for our routes
      return notification.error({ message: `Error - use another service name` });
    }

    if (!isServiceNameValid(e.name)) {
      return notification.error({ message: `Error - use another service name` });
    }

    const newService: iCloudProjectServiceYamlSpecs = { name: e.name, uiType: serviceType };
    const res = await projectService.setNewService(projectId, newService);
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
    history.push(`/app/${props.deployment.id}/configuration/services/${e.name}/main`);
  };

  const serviceData = () => {
    const serviceButtons = [
      { key: 'docker', label: 'Docker image', text: 'Click here to use Docker image here' },
      { key: 'helm', label: 'Helm chart', text: 'Click here to use Helm chart here' },
      { key: 'k8s', label: 'Kubernetes yaml file', text: 'Click here to use kubernetes yaml file' },
    ];
    return serviceButtons.map(({ key, label, text }) => (
      <TipBottomLeft tip={text}>
        <Button key={key} type={serviceType === key ? 'primary' : 'default'} icon={<PlusOutlined />} onClick={() => setServiceType(key)}>
          {label}
        </Button>
      </TipBottomLeft>
    ));
  };

  const deployButton = () => {
    const buttonType = serviceType === `OneClick` ? 'primary' : 'default';
    const handleOnClick = e => {
      Modal.info({
        title: 'Import',
        content: <TemplateSelectionList onSelect={undefined} selectBtnName={'Import'} selectBtnTooltip={''} />,
        onOk(project: iProjectModel) {},
      });
    };
    return (
      <TipBottomLeft tip={`Deploy One click applications`}>
        <Button key={`OneClick`} type={buttonType} onClick={handleOnClick}>
          Deploy One click applications
        </Button>
      </TipBottomLeft>
    );
  };

  const serviceTypeData = () => {
    return (
      serviceType && (
        <Form onFinish={handleCreateService}>
          <Space direction="vertical">
            <Item key={`name`} name={`name`} label={<Text strong> Service name </Text>} rules={[{ required: true }]}>
              <Input placeholder="Enter Service name here" style={{ width: '35.5vw' }} />
            </Item>
            <BottomButtons>
              <Button type="primary" htmlType="submit" style={buttonBorder}>
                Save
              </Button>
            </BottomButtons>
          </Space>
        </Form>
      )
    );
  };

  const extraServices = () => (
    <>
      {serviceType === 'docker' && 'docker'}
      {serviceType === 'helm' && 'helm based'}
      {serviceType === 'k8s' && 'k8s'}
    </>
  );

  return (
    <Space direction="vertical">
      <Title level={5}> Add a Service </Title>
      <Text> Select Service Type to add or Use Prebuilt Template </Text>
      <Text />
      <Text />
      <Space direction="horizontal">
        {serviceData()}
        <CopyServiceButton project={props?.deployment?.ProjectModel} />
        {/* {deployButton()} */}
      </Space>
      <Text />
      <Text />
      {serviceTypeData()}
      {/* {extraServices()} */}
    </Space>
  );
};

import './Authentication.scss';
import history from '../../history';
import { useState, useEffect, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import { handleInputChange, isMainDomain, useApiQuery } from '../../utils/common';
import { Typography, Button, Input, notification, Skeleton, Space, Form } from 'antd';
import { ArrowRightOutlined, GithubFilled, LoginOutlined } from '@ant-design/icons';
import { formInput, formButton } from 'utils/styles';
import { capitalLetter } from 'utils/nameFormat';
import { AuthCommon, AuthenticationForm, LoginHeading } from '../SharedComponents/AuthComp/AuthComp';

const { Text } = Typography;
const { Password } = Input;

export const FindTenantForm = () => {
  const [email, setEmail] = useState('');
  const [tenants, setTenants] = useState([]);
  const [lastEmail, setLastEmail] = useState(undefined);

  const onTenantsRequest = async (email: any) => {
    debugger;
    const res = await authService.tenants(email);
    if (res.error) {
      notification.error({ key: 'Login', message: `status ${res.status}`, description: res.data.message });
      return;
    }
    setLastEmail(email);
    if (res.data.length === 1)
      window.location.replace(`https://${res.data[0]}.${window.location.host.replace('auth.', '')}/#/login/email/${encodeURIComponent(email)}`);
    if (res.data.length === 0) {
      // @ts-ignore
      notification.warn({ key: 'Login', description: 'Your email not found. Do you want signUp?' });
    }
    setTenants(res.data);
  };

  const emailButtonLogin = () => (
    <Button type="primary" disabled={lastEmail === email} onClick={() => onTenantsRequest(email)} style={formButton}>
      Login
    </Button>
  );

  const findTenantFormData = () => {
    const handleFormSubmit = () => onTenantsRequest({ email });
    const handleFormChange = handleInputChange(setEmail);
    const tenantForm = () => (
      <AuthCommon>
        <AuthenticationForm>
          {LoginHeading()}
          <form onSubmit={handleFormSubmit}>
            <Space direction="vertical" style={formInput}>
              <Text strong> Email </Text>
              <Input placeholder="Enter Email Here" value={email} onChange={handleFormChange} />
              {lastEmail !== email || tenants.length == 0 ? emailButtonLogin() : null}
              {!(lastEmail !== email)
                ? tenants.map(tenant => {
                    const handleOpenTenant = () => window.location.replace(`https://${tenant}.${window.location.host.replace('auth.', '')}/`);
                    return (
                      <Button type="primary" onClick={handleOpenTenant} style={formButton}>
                        Open Tenant {capitalLetter(tenant)}
                      </Button>
                    );
                  })
                : null}
            </Space>
          </form>
        </AuthenticationForm>
      </AuthCommon>
    );
    return tenantForm();
  };

  return findTenantFormData();
};

const onLoginRequest = async ({ email, password }: any) => {
  notification.info({ key: 'Login', description: 'Please wait a second...', message: '' });
  const res = await authService.login({ email, password });
  res.error
    ? notification.error({ key: 'Login', message: `status ${res.status}`, description: res.data.message })
    : (window.location.host === 'localhost:3000' &&
        ((window.localStorage['local_host_login'] = email), (window.localStorage['local_host_password'] = password)),
      window.location.reload());
};

export default function Login() {
  // @ts-ignore
  let { email, oldPath } = useParams();
  const [login, setLogin] = useState(decodeURIComponent(email || ''));
  const [password, setPassword] = useState('');
  const [authStatus, setAuthStatus] = useState(undefined);
  const [authTypeInfo, error, loading] = useApiQuery(() => authService.authTypeInfo());
  const savedLogin = window.localStorage['local_host_login'];
  let gitLink = '/api/github/oauth/authorize';

  if (!loading && authStatus === 'no') authTypeInfo?.useSso ? window.location.replace(`${window.location.origin}/api/auth/sso`) : null;

  useEffect(() => {
    const checkUser = async () => {
      const res = await authService.getSelfInfo();
      !res.error ? (oldPath ? history.push(decodeURIComponent(oldPath).replace('#/', '')) : history.push('/')) : setAuthStatus('no');
    };
    checkUser();
  }, []);

  if (window.location.host === 'localhost:3000' && !login)
    savedLogin ? (setLogin(savedLogin), setPassword(window.localStorage['local_host_password'])) : null;

  if (window.location.hostname === 'localhost') gitLink = 'http://localhost:4001/api/github/oauth/authorize';

  const loginForm = () => {
    const formInputs = () => {
      const inputs = [
        { label: 'Username', placeholder: 'Enter Username Here', dataCy: 'login', value: login, onChange: handleInputChange(setLogin), Type: Input },
        {
          label: 'Password',
          placeholder: 'Enter Password Here',
          dataCy: 'password',
          value: password,
          onChange: handleInputChange(setPassword),
          Type: Password,
        },
      ];
      return inputs.map(({ label, placeholder, dataCy, value, onChange, Type }, index) => (
        <Fragment key={index}>
          <Text strong> {label} </Text>
          <Type placeholder={placeholder} data-cy={dataCy} value={value} onChange={onChange} />
        </Fragment>
      ));
    };

    const formExtra = () => (
      <Space direction="horizontal">
        <Text strong>
          <Link to="/reset-password">
            <Space direction="horizontal">
              Forgot Password? <ArrowRightOutlined />
            </Space>
          </Link>
        </Text>
      </Space>
    );

    const formButtons = () => (
      <Space direction="horizontal">
        <Button type="primary" data-cy="loginSubmit" onClick={() => onLoginRequest({ email: login, password })} style={formButton}>
          Sign In <LoginOutlined />
        </Button>
        <a href={gitLink} rel="nofollow">
          <Button type="primary" data-cy="loginSubmit" style={formButton}>
            Signin with GitHub <GithubFilled />
          </Button>
        </a>
      </Space>
    );

    const loginFormData = () => {
      const handleOnKeyDown = (e: { key: string }) => e.key === 'Enter' && onLoginRequest({ email: login, password });
      return (
        <AuthCommon>
          <AuthenticationForm>
            {LoginHeading()}
            <Form onKeyDown={handleOnKeyDown}>
              <Space direction="vertical" style={formInput}>
                {formInputs()}
                {formExtra()}
                {formButtons()}
              </Space>
            </Form>
          </AuthenticationForm>
        </AuthCommon>
      );
    };

    return loginFormData();
  };

  return isMainDomain() ? <FindTenantForm /> : loading || authTypeInfo?.useSso ? <Skeleton active={true} loading={true} /> : loginForm();
}

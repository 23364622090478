import gql from 'graphql-tag';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { projectService } from 'services/project.service';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';
import { Alert, Button, Input, Modal, Skeleton, Space, Typography, notification } from 'antd';
import { ForkOutlined, WarningFilled } from '@ant-design/icons';
import { bannerStyle, buttonWidth, redColor, spaceWidth } from 'utils/styles';
import type { iProjectModel } from 'shared/deployment';

interface iDeleteProjectProps {
  project: iProjectModel;
}

const { Text, Title } = Typography;

export const DeleteProject = ({ project }: iDeleteProjectProps) => {
  const history = useHistory();
  const [copyProject, newProject] = useAuthedMutationWithNotification(gql`
    mutation ProjectController_copyProject($projectId: Int!) {
      ProjectController_copyProject(projectId: $projectId) {
        id
      }
    }
  `);

  const [confirmText, setConfirmText] = useState('');
  const [isFork, setIsFork] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  if (newProject?.loading) return <Skeleton active={true} loading={true} />;
  if (newProject?.data?.ProjectController_copyProject) {
    history.push(`/project/${newProject.data.ProjectController_copyProject.id}/settings/general`);
    return <Skeleton active={true} loading={true} />;
  }

  const forkTemplate = () => {
    const handleForkButton = async () => await copyProject({ variables: { projectId: Number(project.id) } });

    const handleForkInput = e => {
      const { value } = e.target;
      setConfirmText(value);
      setIsFork(value === 'Confirm Fork');
    };

    const alertMessage = () => (
      <Space direction="horizontal">
        <Title level={5} type="success">
          <ForkOutlined /> &nbsp; Fork {project.name} Template?
        </Title>
      </Space>
    );

    const alertDescription = () => (
      <Space direction="vertical">
        <Text>
          You can create a fork of {project.name} Template. Type <Text strong> Confirm Fork </Text> to continue. {'\u00A0'.repeat(27)}
        </Text>
        <Input placeholder="Enter confirmation text here..." onChange={handleForkInput} />
      </Space>
    );

    const alertAction = () => (
      <Button type="primary" onClick={handleForkButton} disabled={!isFork} style={buttonWidth}>
        Confirm and Fork
      </Button>
    );

    return <Alert message={alertMessage()} description={alertDescription()} action={alertAction()} style={bannerStyle} />;
  };

  const deleteTemplate = () => {
    const handleDeleteInput = e => {
      const { value } = e.target;
      setConfirmText(value);
      setIsDelete(value === 'Confirm Delete');
    };

    const deleteProject = async () => {
      const res = await projectService.deleteProject(Number(project.id));
      if (res.status === 403) {
        notification.error({ description: 'Deletion is Prohibited. The project has Child Deployments', message: 'Deleted' });
      } else if (res.status === 200) {
        notification.success({ description: 'Template Deleted', message: 'Deleted' });
        history.push('/projects');
      }
    };

    const alertMessage = () => (
      <Space direction="horizontal">
        <Title level={5} type="danger">
          <WarningFilled /> &nbsp; Delete {project.name} Template?
        </Title>
      </Space>
    );

    const alertDescription = () => (
      <Space direction="vertical">
        <Text> Deleting this Application is permanent and cannot be undone. All associated data will be lost </Text>
        <Text>
          <Text style={redColor}> This cannot be undone .</Text> Type <Text strong> Confirm Delete </Text> to continue.
        </Text>
        <Input placeholder="Enter confirmation text here..." onChange={handleDeleteInput} />
      </Space>
    );

    const alertAction = () => {
      const handleOnClick = () =>
        Modal.confirm({
          title: 'Confirm?',
          content: `Do you want to Delete ${project.name} Template?`,
          okText: 'Ok',
          cancelText: 'Cancel',
          onOk: deleteProject,
        });
      return (
        <Button type="primary" danger onClick={handleOnClick} disabled={!isDelete} style={buttonWidth}>
          Confirm and Delete
        </Button>
      );
    };

    return <Alert message={alertMessage()} description={alertDescription()} action={alertAction()} style={bannerStyle} />;
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {forkTemplate()}
      {deleteTemplate()}
    </Space>
  );
};

import { useContext, useEffect, useState } from 'react';
import { projectService, useProjectGetProjectService } from 'services/project.service';
import { deploymentService } from 'services/deployment.service';
import { YamlSpecCustomizationComponent } from 'components/Projects/YamlSpecCustomization/YamlSpecCustomization';
import { ProjectDeploymentContext } from 'components/Projects/ProjectDeploymentContext';
import { SpecsRenderResult } from 'shared/yaml';
import { TitleUIRow } from 'layout/TitleUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Button, Skeleton, notification } from 'antd';
import { buttonBorder } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';

export interface iProjectOneServiceSettingsPostProcessingProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
}

export const ProjectOneServiceSettingsPostProcessing = (props: iProjectOneServiceSettingsPostProcessingProps) => {
  const [specs, setSpecs] = useState<SpecsRenderResult>(null);
  const [vars, setVars] = useState({});
  const [currentRules, setRules] = useState(null);

  const projectId = props.project.id;
  const serviceName = props.serviceName;
  const service = useProjectGetProjectService(projectId, serviceName, [projectId, serviceName, props.tabType]);
  const deploymentContext = useContext(ProjectDeploymentContext);

  useEffect(() => {
    deploymentContext?.deployment?.id
      ? deploymentService.debugUI_serviceYAML(deploymentContext.deployment.id, serviceName).then(res => {
          setSpecs(res.data.specs);
          setVars(res.data.vars);
        })
      : null;
  }, [deploymentContext, serviceName]);

  if (service.loading) return <Skeleton active={true} />;

  const rules = currentRules !== null ? currentRules : service?.answer?.postProcessingRules;

  const handleOnConfirm = async () => {
    const serviceObj = { tabType: props.tabType, name: props.serviceName, data: { postProcessingRules: rules } };
    const res = await projectService.setProjectServices(projectId, serviceObj);
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
  };

  return (
    <>
      <TitleUIRow title={`Post Processing configuration for ${serviceName}`} />
      <YamlSpecCustomizationComponent
        rules={rules}
        specs={specs}
        syncVars={vars}
        onChange={(rules: any[]) => {
          debugger;
          setRules(rules);
        }}
      />
      <BottomButtons>
        <Button type="primary" onClick={handleOnConfirm} style={buttonBorder}>
          Save
        </Button>
      </BottomButtons>
    </>
  );
};

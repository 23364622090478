import { useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById, useGqlDeploymentRoutes } from '../../../services/deployment.service';
import DeploymentEnvVariablesForm from './EnvironmentVariable/EnvironmentVariable';
import DeploymentUpdateVersionForm from './General/DeploymentUpdateVersion';
import DeploymentGeneralSettingsForm from './General/General';
import { Storage } from './General/Storage';
import { IngressAnnotationForm } from './IngressAnnotationForm/IngressAnnotationForm';
import { iDeployment } from 'shared/deployment';
import { CronJobsUI } from './General/CronJobs';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { Skeleton, Tabs } from 'antd';

const { TabPane } = Tabs;

export default function DeploymentAdvancedSettingPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();

  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const dpQueryRoutes = useGqlDeploymentRoutes(deployment.id);
  const hasRoutes = dpQueryRoutes.data?.DeploymentsController_getRoutes?.routes?.length > 0;

  if (!tabName) history.push(`/app/${id}/advanced/general`);
  if (dpQuery.error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }
  if (dpQuery.loading || !deployment) return <Skeleton active={true} loading={true} />;
  if (!deployment.isReady) {
    history.push(`/app/${deployment.id}/advanced/details`);
    return <Skeleton active={true} loading={true} />;
  }

  const tabsData = () => {
    const handleTabClick = key => history.push(`/app/${id}/advanced/${key}`);
    const conditionalTabs = [
      {
        key: 'general',
        tab: 'General',
        content: <DeploymentGeneralSettingsForm deploymentId={deployment.id} hasRoutes={hasRoutes} />,
        condition: true,
      },
      {
        key: 'env',
        tab: 'Environment variables',
        content: <DeploymentEnvVariablesForm deployment={deployment} />,
        condition: deployment.ProjectModel?.showEnvVarsTab !== false,
      },
      {
        key: 'version',
        tab: 'Version',
        content: <DeploymentUpdateVersionForm deployment={deployment} />,
        condition: deployment.ProjectModel?.showVerionsTab !== false,
      },
      { key: 'storage', tab: 'Storage', content: <Storage deployment={deployment} />, condition: deployment.ProjectModel.name === 'nanoheal' },
      { key: 'cronjobs', tab: 'Cron jobs', content: <CronJobsUI deployment={deployment} />, condition: deployment.ProjectModel.name === 'nanoheal' },
      {
        key: 'ingress',
        tab: 'Ingress',
        content: <IngressAnnotationForm deployment={deployment} />,
        condition: hasRoutes && deployment.ProjectModel.name === 'nanoheal',
      },
    ];
    return (
      <Tabs defaultActiveKey={tabName || 'general'} onChange={handleTabClick}>
        {conditionalTabs.map(({ key, tab, content, condition }) =>
          condition ? (
            <TabPane key={key} tab={tab}>
              {content}
            </TabPane>
          ) : null,
        )}
      </Tabs>
    );
  };

  return (
    <>
      <TitleUIRow title={`Deployment: ${deployment.name}`} />
      <DeploymentWidgetsRow deployment={deployment} />
      {tabsData()}
    </>
  );
}

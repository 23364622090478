import { useHistory } from 'react-router-dom';
import { deploymentService, useGqlDeploymentById } from 'services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { Button, Result, notification, Alert, Space, Typography } from 'antd';
import { buttonWidth, spaceWidth } from 'utils/styles';
import { DeploymentStep4 } from 'components/SharedComponents/CreateSteps/CreateSteps';

const { Text } = Typography;

export const DeployPage = (props: { deploymentId: number }) => {
  const history = useHistory();
  const dpQuery = useGqlDeploymentById(props.deploymentId);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};

  if (!deployment.region) {
    const resultButton = (
      <Button style={buttonWidth} type="primary" onClick={async () => history.push(`/app/${props.deploymentId}/setting/details`)}>
        Select Region
      </Button>
    );
    return (
      <>
        <Result status="warning" title="Please Select a Region to Deploy." extra={resultButton} />
      </>
    );
  }

  const resultSubtitle = () => {
    const deploySummary = [
      { label: 'Project Template:', value: deployment.ProjectModel.title },
      { label: 'Deployment Name:', value: deployment?.name },
      { label: 'Deployment Cluster:', value: deployment.RegionModel.name },
      { label: 'Deployment ID:', value: deployment.id },
    ];
    return (
      <Space direction="vertical">
        {deploySummary.map((item, index) => (
          <Text key={index}>
            <Text strong> {item.label} </Text> {item.value}
          </Text>
        ))}
      </Space>
    );
  };

  const resultExtra = () => {
    const makeReadyToDeploy = async (id: number) => {
      const res = await deploymentService.updateDeployment(id, { isReady: true, isEnabled: true });
      return res.status === 401
        ? (window.location.reload(), false)
        : res.error || res.data.error
        ? (notification.error({ message: res.error || res.data.error }), false)
        : (notification.success({ message: `Saved` }), true);
    };
    const onClickButton = async () => (await makeReadyToDeploy(props.deploymentId)) && history.push(`/app/${props.deploymentId}/status/overview`);
    return (
      <Button style={buttonWidth} type="primary" onClick={onClickButton}>
        Confirm and Deploy
      </Button>
    );
  };

  const createDeploymentStep4 = () => (
    <Space direction="vertical" style={spaceWidth}>
      <DeploymentStep4 />
      <Result status="success" title="Deployment is Ready to Deploy." subTitle={resultSubtitle()} extra={resultExtra()} style={{ marginTop: -50 }} />
    </Space>
  );

  return createDeploymentStep4();
};

import { useState } from 'react';
import { applyPostProcessingRules } from 'shared/postProcessingRules';
import { iPostProcessingRule } from 'shared/project.interface';
import { getK8sDocs, k8sDocsKindItem } from './k8sDocs';
import { RulesListComponent } from './RulesListComponent';
import { SpecsRenderResult } from 'shared/yaml';
import { Button, Col, Collapse, Popover, Result, Row, notification } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AddIcoStyle, dotTwo, floatingRight, minusKey, postContent, postPop, relatively } from 'utils/styles';
// import { uiDefaultOffset } from 'utils/util';

export interface iYamlSpecCustomizationComponentProps {
  onChange: (rules: any[]) => void;
  specs: SpecsRenderResult;
  syncVars: any;
  rules: iPostProcessingRule[];
}

interface iYamlElementProps {
  spec: any;
  level: number;
  path: string;
  keyName: string;
  onNewRule: (rule: iPostProcessingRule) => void;
  mainKind: string;
  mainMetadataName: string;
}

interface iYamlKeyNameProps {
  name: string;
  path: string;
  onNewRule: (rule: iPostProcessingRule) => void;
  mainKind: string;
  mainMetadataName: string;
  showAddButton?: boolean;
}

const { Panel } = Collapse;

export const YamlSpecCustomizationComponent = (props: iYamlSpecCustomizationComponentProps) => {
  const [rulesList, setRulesList] = useState(props.rules || []);
  const [curentSpecs, setResultSpecs] = useState(null);
  // const [loader, setLoader] = useState(true);

  const specs = props?.specs?.specs;
  const resultSpecs = curentSpecs !== null ? curentSpecs : applyPostProcessingRules(rulesList, specs, props.syncVars);

  const onNewRule = (rule: iPostProcessingRule) => {
    if (rulesList.find(r => r.path === rule.path)) {
      notification.error({ message: 'Error', description: 'Rule already exists' });
      return;
    }
    const newRulesList = [...rulesList, rule];
    setRulesList(newRulesList);
    props.onChange(newRulesList);
    setResultSpecs(applyPostProcessingRules(newRulesList, specs, props.syncVars));
  };

  const removeRule = (rule: iPostProcessingRule) => {
    const newRulesList = rulesList.filter(r => r.path !== rule.path);
    setRulesList(newRulesList);
    props.onChange(newRulesList);
    setResultSpecs(applyPostProcessingRules(newRulesList, specs, props.syncVars));
  };

  const editRule = (rule: iPostProcessingRule) => {
    const newRulesList = rulesList.map(r => {
      if (r.path === rule.path) {
        return rule;
      }
      return r;
    });
    setRulesList(newRulesList);
    props.onChange(newRulesList);
    setResultSpecs(applyPostProcessingRules(newRulesList, specs, props.syncVars));
  };

  if (!resultSpecs && !rulesList) {
    return (
      <Result
        status="404"
        title="Your yaml is not ready yet."
        subTitle={<>This tab allow to edit YAML specification before send it to Kubernetes cluster.</>}
      />
    );
  }

  return (
    <Row gutter={[0, 0]} wrap={false} style={{ marginTop: '15px' }}>
      <Col flex="auto">
        <Collapse size="small" defaultActiveKey={[]}>
          {(resultSpecs || []).map((spec: any, index) => {
            return (
              <Panel
                key={index}
                header={
                  <>
                    {spec?.kind} - {spec?.metadata?.name}
                    <Button
                      size="small"
                      danger
                      style={floatingRight}
                      onClick={event => {
                        onNewRule({
                          path: `${spec.kind}["${spec?.metadata?.name}"]`,
                          action: 'removeFullRecord',
                          mainKind: spec.kind,
                          mainMetadataName: spec?.metadata?.name,
                        });
                        event.stopPropagation();
                      }}
                    >
                      Remove
                    </Button>
                  </>
                }
              >
                <YamlElement
                  key={`YamlElement_${index}`}
                  mainMetadataName={spec?.metadata?.name}
                  mainKind={spec.kind}
                  spec={spec}
                  level={0}
                  path={``}
                  keyName={''}
                  onNewRule={onNewRule}
                />
              </Panel>
            );
          })}
        </Collapse>
      </Col>
      <Col flex={'520px'} style={{ ...relatively, paddingLeft: 15 }}>
        <RulesListComponent editRule={editRule} removeRule={removeRule} rulesList={rulesList} syncVars={props.syncVars} />
      </Col>
    </Row>
  );
};

const MinusKey = (props: any) => <span style={minusKey}>-</span>;
const Dot2 = (props: any) => <span style={dotTwo}>:</span>;

const YamlKeyName = (props: iYamlKeyNameProps) => {
  const content = (props: iYamlKeyNameProps) => {
    const path = `${props.path}["${props.name}"]`;
    const docs: k8sDocsKindItem = getK8sDocs(props.mainKind, path);
    return (
      <>
        <Button
          type="primary"
          style={postContent}
          onClick={() => {
            props.onNewRule({
              path: path,
              action: 'edit',
              mainKind: props.mainKind,
              mainMetadataName: props.mainMetadataName,
              varValueType: 'string',
              varValueSrcType: 'hardcoded',
              value: '',
            });
          }}
        >
          Edit
        </Button>

        <Button
          danger
          style={postContent}
          onClick={() => {
            props.onNewRule({
              path: path,
              action: 'remove',
              mainKind: props.mainKind,
              mainMetadataName: props.mainMetadataName,
            });
          }}
        >
          Remove
        </Button>

        {props.showAddButton ? (
          <Button
            type="primary"
            style={postContent}
            onClick={() => {
              props.onNewRule({
                path: path,
                action: 'add',
                mainKind: props.mainKind,
                mainMetadataName: props.mainMetadataName,
                varValueType: 'string',
                varValueSrcType: 'hardcoded',
                addPropertyName: 'NewProperty',
                value: '',
              });
            }}
          >
            Add
          </Button>
        ) : null}

        {docs && docs.description ? <div> {docs.description} </div> : null}
      </>
    );
  };

  return (
    <Popover content={() => content(props)} title={`${props.path}["${props.name}"]`} trigger="hover">
      <span style={postPop}>{props.name}</span>
      <Dot2 />
      {props.showAddButton ? <PlusCircleOutlined style={AddIcoStyle} /> : null}
    </Popover>
  );
};

const YamlKeyValue = (props: { value: string }) => {
  return <span style={{ color: '#8B4502', paddingLeft: '4px' }}> {props.value} </span>;
};

const YamlElement = (props: iYamlElementProps) => {
  const leftPadding = `${8 * props.level}px`;
  const styles = { paddingLeft: leftPadding, marginLeft: '0px' };
  const path = props.path;

  const res = Object.keys(props?.spec || {}).map((key: string, index: any) => {
    if (Array.isArray(props.spec[key])) {
      return (
        <div key={`Ye${index}`}>
          <YamlKeyName
            showAddButton={true}
            mainMetadataName={props.mainMetadataName}
            mainKind={props.mainKind}
            name={key}
            path={path}
            onNewRule={props.onNewRule}
          />
          {props.spec[key].map((item: any, index) => {
            if (['string', 'number', 'boolean'].includes(typeof item)) {
              const leftPadding = `${8 * (props.level + 1)}px`;
              const styles = { paddingLeft: leftPadding, marginLeft: '0px' };
              return (
                <div style={styles} key={`YamlDiv_${index}`}>
                  <MinusKey />
                  <YamlKeyValue value={item} />
                </div>
              );
            }

            return (
              <YamlElement
                key={`YamlElement_${index}`}
                mainMetadataName={props.mainMetadataName}
                mainKind={props.mainKind}
                spec={item}
                level={props.level}
                path={`${path}["${key}"][${index}]`}
                keyName={'-'}
                onNewRule={props.onNewRule}
              />
            );
          })}
        </div>
      );
    }

    if (['string', 'number', 'boolean'].includes(typeof props.spec[key])) {
      return (
        <div key={`YamlElement_string${index}`}>
          <YamlKeyName mainMetadataName={props.mainMetadataName} mainKind={props.mainKind} name={key} path={path} onNewRule={props.onNewRule} />
          <YamlKeyValue value={props.spec[key]} />
        </div>
      );
    }

    if (typeof props.spec[key] === 'object') {
      return (
        <div key={`YamlElement_Object${index}`}>
          <YamlKeyName
            showAddButton={true}
            mainMetadataName={props.mainMetadataName}
            mainKind={props.mainKind}
            name={key}
            path={path}
            onNewRule={props.onNewRule}
          />
          <YamlElement
            key={`YamlElement_${index}`}
            mainMetadataName={props.mainMetadataName}
            mainKind={props.mainKind}
            spec={props.spec[key]}
            keyName={key}
            level={props.level + 1}
            path={`${props.path}["${key}"]`}
            onNewRule={props.onNewRule}
          />
        </div>
      );
    }
  });

  if (props.keyName == '-') {
    return (
      <div style={styles}>
        <MinusKey />
        <div> {res} </div>
      </div>
    );
  }

  return (
    <div style={styles} key={path}>
      {res}
    </div>
  );
};

import { ReactElement, useState } from 'react';
import { Skeleton, Tabs, Switch } from 'antd';
import { BranchesOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
import TabPane from 'antd/es/tabs/TabPane';
import { ProjectFiles } from 'components/Projects/FileEditor/ProjectFiles';
import { ProjectContainerStorage } from 'components/Projects/ProjectContainerStorage/ProjectContainerStorage';
import { ProjectPage } from 'components/Projects/ProjectPage';
import { GitLogs } from 'components/Projects/Settings/Git/gitLogs';
import { ProjectGitSettings } from 'components/Projects/Settings/Git/ProjectGitSettings';
import { ProjectDocs } from 'components/Projects/Settings/ProjectDocs';
import { ProjectEnvSettings } from 'components/Projects/Settings/ProjectEnvSettings';
import { ProjectGeneral } from 'components/Projects/Settings/ProjectGeneral';
import { ProjectVersions } from 'components/Projects/Settings/ProjectVersion';
import { ShareProject } from 'components/Projects/Settings/ShareProject';
import { isInEditMode, setEditMode } from 'utils/util';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { DeleteTab } from 'components/Deployments/Setting/General/settings/DeleteTab';

interface iConfigurationSettings {
  deployment: iDeployment;
  settingsTab: string;
}

export const ConfigurationSettings = (props: iConfigurationSettings): ReactElement => {
  const history = useHistory();
  const [isEditMode, setIsEditMode] = useState(isInEditMode(`dp`, props.deployment.id));
  const user = useUser();

  const project = props.deployment.ProjectModel;
  const deployment = props.deployment;
  let tabName = props.settingsTab;

  let defaultTab = tabName;
  if (!defaultTab) {
    if (deployment.isReady === false) {
      defaultTab = 'general';
    } else {
      defaultTab = 'danger-zone';
    }
  }

  // tabName !== 'git' && tabName !== 'danger-zone' && !project.gitInitialized
  //   ? (() => {
  //       history.push(`/app/${deployment.id}/configuration/git`);
  //       notification.error({
  //         key: 'gitInitialized',
  //         message: `Git configuration is required`,
  //         description: `Please configure git settings for this project`,
  //       });
  //       return null;
  //     })()
  //   : null;

  if (!tabName) {
    history.push(`/app/${deployment.id}/configuration/${defaultTab}`);
    return null;
  }

  if (!deployment || !user) {
    return <Skeleton active={true} loading={true} />;
  }

  let baseMenuTab = `settings`;

  const handleTabClick = (key: any) => history.push(`/app/${deployment.id}/configuration/${baseMenuTab}/${key}`);

  const gitTabs = [
    deployment.isReady === true
      ? {
          forEditMode: false,
          key: 'danger-zone',
          tab: 'Danger Zone',
          component: <DeleteTab id={deployment.id} />,
        }
      : null,
    // { key: 'provisioner', tab: 'Provisioner', component: <ProvisionerConfiguration project={project} /> },
    // { key: 'permissions', tab: 'Permissions', component: <ClusterPermissions project={project} /> },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'general',
      tab: 'General',
      component: <ProjectGeneral project={project} />,
      disabled: !project.gitInitialized,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'git',
      tab: (
        <>
          <BranchesOutlined /> GIT Settings
        </>
      ),
      component: <ProjectGitSettings project={project} />,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'git-audit',
      tab: <>GIT Audit</>,
      component: <GitLogs project={project} />,
      disabled: !project.gitInitialized,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'docs',
      tab: 'Docs',
      component: <ProjectDocs project={project} />,
      disabled: !project.gitInitialized,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'share',
      tab: 'Share',
      component: <ShareProject project={project} />,
      disabled: !project.gitInitialized,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'env',
      tab: 'Env',
      component: <ProjectEnvSettings project={project} />,
      disabled: !project.gitInitialized,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'project-files',
      tab: 'Files',
      component: <ProjectFiles project={project} />,
      disabled: !project.gitInitialized,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'pull-secrets',
      tab: 'Pull secrets',
      component: <ProjectContainerStorage project={project} />,
      disabled: !project.gitInitialized,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'versions',
      tab: 'Versions',
      component: <ProjectVersions project={project} />,
      disabled: !project.gitInitialized,
    },
    {
      permission: userHasPermission(user, UserPermissions.ProjectsMange),
      forEditMode: true,
      key: 'logs',
      tab: 'Activity',
      component: <ProjectPage project={project} />,
      disabled: !project.gitInitialized,
    },

    // { key: 'danger-zone', tab: 'Danger Zone (to remove)', component: <DeleteProject project={project} /> },
  ]
    .filter(Boolean)
    .filter(v => v.permission !== false)
    .filter(v => v.forEditMode === isEditMode || v.forEditMode === false || deployment.isReady === false);

  console.log(
    'gitTabs',
    tabName,
    defaultTab,
    gitTabs.map(tab => tab.key),
  );
  return (
    <Tabs
      defaultActiveKey={tabName || defaultTab}
      onChange={handleTabClick}
      tabBarExtraContent={
        userHasPermission(user, UserPermissions.ProjectsMange) &&
        deployment.isReady === true && (
          <Switch
            checkedChildren="Edit mode"
            unCheckedChildren="View mode"
            onClick={val => {
              setIsEditMode(val);
              setEditMode(`dp`, props.deployment.id, val);
            }}
            checked={isEditMode}
          />
        )
      }
    >
      {gitTabs.map(tab => (
        <TabPane key={tab.key} tab={tab.tab} disabled={tab.disabled}>
          {tab.component}
        </TabPane>
      ))}
    </Tabs>
  );
};

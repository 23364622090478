import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { buttonWidth } from 'utils/styles';

export const NewApplicationButton = () => {
  const user = useUser();
  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) {
    return null;
  }

  return (
    <Link to="/new-application">
      <Button type="primary" style={buttonWidth}>
        New Application
      </Button>
    </Link>
  );
};

import { HelpNoteAboutVars } from './HelpNotes';
import { YamlEditor } from 'components/Projects/YamlEditor/YamlEditor';
import { RiseOutlined } from '@ant-design/icons';
import { PROJECT_CODE_NAME } from 'interface/common';
import { Alert, Space, Typography } from 'antd';
import { spaceWidth } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';
import { TipBottomLeft } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;

export interface ProjectOneServiceSettingsFormPropsProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
}

export const ProjectOneServiceSettingsFormKEDA = (props: ProjectOneServiceSettingsFormPropsProps) => {
  const project = props.project;
  const serviceName = props.serviceName;

  const alertMessage = (
    <Space direction="horizontal">
      <Text>
        Deployment should not have defined number of replicas, KEDA can have conflict with metacontroller if one property will be changed in two
        places.
      </Text>
      <Text strong>
        <TipBottomLeft tip="Metacontroller Apply Docs">
          <a href="https://metacontroller.github.io/metacontroller/api/apply.html" target="_blank">
            See more.
          </a>
        </TipBottomLeft>
      </Text>
    </Space>
  );

  const YamlEditorEmptyDescription = (
    <Space direction="vertical">
      <Text> KEDA is a Kubernetes-based Event Driven Autoscaler. </Text>
      <Text> With KEDA, you can drive the scaling of any container in Kubernetes based on the number of events needing to be processed. </Text>
    </Space>
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Text />
      <Alert type="info" showIcon message={<HelpNoteAboutVars />} />
      <Alert type="warning" showIcon message={alertMessage} />
      <YamlEditor
        key={`yaml-editor`}
        fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/keda.yaml`}
        project={project}
        mainKindType={'scaledobject'}
        emptyTitle={`Event-driven Autoscaling`}
        emptyDescription={YamlEditorEmptyDescription}
        emptyIco={<RiseOutlined />}
        hideOtherObjectTypes={true}
        emptyButtonTitle={`Configure Autoscalling`}
      />
    </Space>
  );
};

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setPageWidgets } from 'services/actions';
import { DeploymentSelectionComponent } from './DeploymentSelectionComponent/DeploymentSelectionComponent';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { iProjectDeploymentContext } from './ProjectDeploymentContext';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { Button, Space, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { buttonSize, cardTextStyle } from 'utils/styles';
import { GitStatusComponent } from './GitStatusComponent/GitStatusComponent';
import { TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

const { Title, Text } = Typography;

export const ProjectWidgetsRow = (props: { project: iProjectModel; deployment?: iDeployment; setDeploymentContext: any }) => {
  const project = props.project;
  const [services, error, loading] = useApiQuery(() => projectService.getProjectServiceList(project.id));

  const hasServices = !loading && !error && project.gitInitialized ? (services?.length === 0 ? false : true) : null;

  const tooltipTitle = (
    <Space direction="vertical">
      <Title level={5} style={cardTextStyle}>
        No services in this project
      </Title>
      <Text style={cardTextStyle}> This project does not have any services. Please create at least one service. </Text>
    </Space>
  );

  const gitStatus = () => project.gitInitialized === true && <GitStatusComponent projectId={project.id} deploymentId={props?.deployment?.id} />;

  const addService = () =>
    hasServices === false && (
      <TipTop tip={tooltipTitle}>
        <Link to={`/project/${project.id}/settings/services`}>
          <Button danger icon={<ExclamationCircleFilled />} style={buttonSize}>
            Add Service
          </Button>
        </Link>
      </TipTop>
    );

  const deploymentSelector = () =>
    hasServices === true &&
    project.gitInitialized === true && (
      <DeploymentSelectionComponent
        projectId={project.id}
        onSelect={async (deployment: iDeployment) => {
          const newContextValue: iProjectDeploymentContext = { deployment };
          props.setDeploymentContext(newContextValue);
        }}
      />
    );

  const newServices = () => (
    <Space>
      {gitStatus()}
      {addService()}
      {deploymentSelector()}
    </Space>
  );

  useEffect(() => {
    const row = project ? newServices() : null;
    setPageWidgets(row);
    return () => {
      setPageWidgets(null);
    };
  }, [project, hasServices]);
  return null;
};

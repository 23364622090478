import moment from 'moment';
import { useState } from 'react';
import { usersService } from 'services/users.service';
import { useApiQuery } from 'utils/common';
import { formatUserName } from 'utils/nameFormat';
import { iUserModel } from 'shared/user';
import { NewUserPage } from './NewUserPage';
import { UserPage } from './UserPage';
import { Avatar, Button, Card, Col, Flex, Row, Skeleton, Space, Tag, Typography, Input, Drawer, Result, Switch, Select } from 'antd';
import { AppstoreOutlined, CaretLeftFilled, CheckCircleFilled, RollbackOutlined, UnorderedListOutlined, WarningFilled } from '@ant-design/icons';
import { buttonWidth, externalStatusTag, userTags, topMargin, filterWidth, spaceWidth } from 'utils/styles';
import { TipRight, TipTop } from 'components/SharedComponents/Tooltip/Tooltip';

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

export const UsersList = () => {
  const [users, err, loading] = useApiQuery(usersService.getUsers);
  const [filteredUsers, setFilteredUsers] = useState<iUserModel[]>([]);
  const [isCompactView, setIsCompactView] = useState(false);
  const [activeButton, setActiveButton] = useState<'list' | 'card'>('list');
  const [addUserDrawerOpen, setAddUserDrawerOpen] = useState(false);
  const [deleteUserDrawerOpen, setDeleteUserDrawerOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [userSort, setUserSort] = useState<'id' | 'name'>('id');
  const [selectedRole, setSelectedRole] = useState<string | null>(null);

  if (!users || err || loading) return <Skeleton active={true} loading={true} />;

  const handleSearch = (value: string) => {
    const filtered = users.filter(
      (user: { email: string; id: { toString: () => string | string[] }; roles: string | string[] }) =>
        (user.email.toLowerCase().includes(value.toLowerCase()) || user.id.toString().includes(value.toLowerCase())) &&
        (selectedRole === null || user.roles.includes(selectedRole)),
    );
    setFilteredUsers(filtered);
    setSearchQuery(value);
  };

  const handleRoleChange = (role: string) => {
    setSelectedRole(role === 'All' ? null : role);
    const low = searchQuery.toLowerCase();
    const filteredByQuery = users.filter(user => user.email.toLowerCase().includes(low) || user.id.toString().includes(low));
    setFilteredUsers(role === 'All' ? filteredByQuery : filteredByQuery.filter(user => user.roles.includes(role)));
  };

  const toggleView = (view: 'list' | 'card') => {
    setIsCompactView(view === 'card');
    setActiveButton(view);
  };

  const pastLogin = (recentLogin: Date | string | undefined): string | null =>
    recentLogin ? (!moment(recentLogin).isSame(moment(), 'day') ? moment(recentLogin).format('DD-MM-YYYY ~ hh:mm:ss A') : null) : null;

  const formatrecentLogin = (recentLogin: Date | string | undefined): string => {
    if (!recentLogin) return 'Offline';
    const loginDate = moment(recentLogin);
    const now = moment();
    const lastDay = now.diff(loginDate, 'days');
    const lastWeek = now.diff(loginDate, 'weeks');
    const lastMonth = now.diff(loginDate, 'months');
    const lastYear = now.diff(loginDate, 'years');
    return loginDate.isSame(now, 'day')
      ? loginDate.format('DD-MM-YYYY ~ hh:mm:ss A')
      : lastDay === 0
      ? 'Offline since 1 day'
      : lastDay < 7
      ? `Offline since ${lastDay} days`
      : lastWeek === 0
      ? 'Offline since 1 week'
      : lastDay < 30
      ? `Offline since ${lastWeek} weeks`
      : lastMonth === 0
      ? 'Offline since 1 month'
      : lastDay < 365
      ? `Offline since ${lastMonth} months`
      : lastYear === 0
      ? 'Offline since 1 year'
      : `Offline since ${lastYear} years`;
  };

  const userLists = () => {
    const cardRows: JSX.Element[] = [];

    const sortedUserList = (searchQuery.length > 0 || selectedRole !== null ? filteredUsers : users).sort(
      (a: { name: { toLowerCase: () => number }; id: number }, b: { name: { toLowerCase: () => number }; id: number }) => {
        return userSort === 'name'
          ? a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
          : a.id - b.id;
      },
    );

    if (sortedUserList.length === 0) {
      const noResults = (
        <Button type="primary" onClick={() => setSearchQuery('')} icon={<CaretLeftFilled />}>
          Back to Users
        </Button>
      );
      return (
        <Col span={24}>
          <Result status="404" subTitle="This User does not exist... Please search for appropriate User" extra={noResults} style={topMargin} />
        </Col>
      );
    }

    sortedUserList.forEach((user: iUserModel, index: number) => {
      const cardTitle = (
        <Row>
          <TipRight tip={pastLogin(user.lastLogin)}>
            <Tag color="geekblue"> {formatrecentLogin(user.lastLogin)} </Tag>
          </TipRight>
          {user?.roles?.length > 0 && <Tag color="purple"> {user.roles.join(', ')} </Tag>}
        </Row>
      );

      const cardExtra = <Tag> ID: {user.id} </Tag>;

      const handleCardClick = () => {
        setSelectedUserId(user.id.toString());
        setDeleteUserDrawerOpen(true);
      };

      const userCards = (
        <Col span={isCompactView ? 8 : 24} key={user.id} style={spaceWidth}>
          <Card size="small" type="inner" bordered={false} title={cardTitle} extra={cardExtra} onClick={handleCardClick}>
            <Space direction="horizontal" data-cy={user.id} style={spaceWidth}>
              <Space direction="horizontal">
                <Avatar src={user.avatar} shape="square" size={48} />
                <Space direction="vertical">
                  <Text> {formatUserName(user.email)} </Text>
                  <Text type="secondary"> {user.name} </Text>
                </Space>
              </Space>
            </Space>
            <Flex justify={'flex-end'}>
              <Space style={externalStatusTag}>
                <Tag color={user.isBlocked ? 'red' : 'green'} icon={user.isBlocked ? <WarningFilled /> : <CheckCircleFilled />} style={userTags}>
                  {user.isBlocked ? 'Blocked' : 'Active'}
                </Tag>
              </Space>
            </Flex>
          </Card>
        </Col>
      );
      cardRows.push(userCards);
    });
    return cardRows;
  };

  const userSwitch = () => {
    const handleOnChangeSwitch = () => setUserSort(userSort === 'id' ? 'name' : 'id');
    return (
      <TipRight tip={`Click here to sort by ${userSort === 'id' ? 'Username' : 'User ID'}`}>
        <Switch checkedChildren="Username" unCheckedChildren="User ID" checked={userSort === 'name'} onChange={handleOnChangeSwitch} />
      </TipRight>
    );
  };

  const userRoles = ['Admin', 'Developer', 'DevOps', 'Viewer'];

  const rolesFilter = () => (
    <Col span={6}>
      <Select showSearch placeholder="Roles" value={selectedRole === null ? undefined : selectedRole} onChange={handleRoleChange} style={filterWidth}>
        {selectedRole !== null && (
          <Option key="RESET" value="All">
            <Flex justify="flex-end">
              <Text strong type="warning">
                Reset <RollbackOutlined />
              </Text>
            </Flex>
          </Option>
        )}
        {userRoles.map(role => (
          <Option key={role} value={role}>
            {role}
          </Option>
        ))}
      </Select>
    </Col>
  );

  const searchViews = () => (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Search placeholder="Search using username or ID" enterButton allowClear onChange={e => handleSearch(e.target.value)} />
      </Col>
      {rolesFilter()}
      <Col span={10}>
        <Flex justify={'flex-end'}>
          <Space direction="horizontal">
            {userSwitch()}
            <TipTop tip="List View">
              <Button icon={<UnorderedListOutlined />} onClick={() => toggleView('list')} type={activeButton === 'list' ? 'primary' : 'default'} />
            </TipTop>
            <TipTop tip="Grid View">
              <Button icon={<AppstoreOutlined />} onClick={() => toggleView('card')} type={activeButton === 'card' ? 'primary' : 'default'} />
            </TipTop>
            <Button type="primary" onClick={() => setAddUserDrawerOpen(true)} style={buttonWidth}>
              New User
            </Button>
          </Space>
        </Flex>
      </Col>
    </Row>
  );

  const userData = () => (
    <Space direction="vertical" style={spaceWidth}>
      {searchViews()}
      <Text />
      <Row gutter={[16, 16]}> {userLists()} </Row>
      <Text />
      <Drawer
        width={isCompactView ? '20%' : '40%'}
        title={addUserDrawerOpen ? 'Add a New User' : 'User Details'}
        open={addUserDrawerOpen || deleteUserDrawerOpen}
        onClose={() => (addUserDrawerOpen ? setAddUserDrawerOpen(false) : setDeleteUserDrawerOpen(false))}
      >
        {addUserDrawerOpen ? <NewUserPage /> : selectedUserId && <UserPage userId={selectedUserId} />}
      </Drawer>
    </Space>
  );

  return userData();
};

import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { projectService } from 'services/project.service';
import { CustomWidgets } from 'form-components';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Button, Col, Divider, Modal, Popconfirm, Progress, Row, Space, Spin, Tabs, Typography, notification } from 'antd';
import { buttonBorder, modalStyle, spaceWidth, tabMargin } from 'utils/styles';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { iProjectModel } from 'shared/deployment';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';

export interface iProjectOneServiceSettingsFormProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
  service: iCloudProjectServiceYamlSpecs;
}

interface iVType {
  type: string | number | boolean | ReactElement<string> | Iterable<ReactNode>;
  cmd: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  message: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const oneServiceSettingsForm: any = {
  type: 'object',

  properties: {
    helmRepositoryName: {
      type: 'string',
      title: 'Helm Chart Repository Name',
    },

    helmRepositoryURL: {
      type: 'string',
      title: 'Helm Chart Repository URL',
    },
    helmRepositoryVersion: {
      type: 'string',
      title: 'Helm Chart Version',
    },
    helmRepositoryChartName: {
      type: 'string',
      title: 'Helm Chart Name',
    },
    // helmChartName: {
    //   hidden: true,
    //   type: 'string',
    //   title: `Helm chart path (offten the same as 'helm chart name')`,
    //   description:
    //     'Path in project reposytory to the helm chart folder (please put all your helm charts to the folder `helm` in your project root directory)',
    // },
  },
};

const oneServiceSettingsUISchema = {
  helmChartName: {
    'ui:emptyValue': '',
  },
  helmRepositoryVersion: {
    'ui:emptyValue': '',
  },
  helmRepositoryChartName: {
    'ui:emptyValue': '',
  },
  helmRepositoryURL: {
    'ui:emptyValue': '',
  },
  helmRepositoryName: {
    'ui:emptyValue': '',
  },
};

const { Text } = Typography;
const { TabPane } = Tabs;

async function showModalWithHelmCommandResult(resPromis: Promise<any>, title: string) {
  Modal.destroyAll();
  Modal.success({
    title: 'Loading...',
    content: <Progress percent={100} status="active" showInfo={false} />,
    icon: <Spin size="large" />,
    closable: false,
    onOk: () => resPromis,
  });

  try {
    const res = await resPromis;
    Modal.destroyAll();
    const successModalContent = (
      <div style={modalStyle}>
        {res?.data?.cmd ? (
          <Text strong>
            {res?.data?.cmd} <Divider />
          </Text>
        ) : (
          ''
        )}
        {res?.data?.error ? (
          <span style={{ color: '#BC4040' }}>
            {res?.data?.error} <Divider />
          </span>
        ) : null}
        {res?.data?.step1?.stdout}
        {res?.data?.step1?.stderr}
        {res?.data?.step2?.stdout}
        {res?.data?.step2?.stderr}
        {res?.data?.logs &&
          res?.data?.logs.map((v: iVType) => {
            const type = (
              <Text strong style={{ color: v.type === 'error' ? '#FF7777' : '#009688' }}>
                {v.type}
              </Text>
            );
            return (
              <Space direction="vertical">
                {v?.cmd} {type}:{v.message} <Divider />
              </Space>
            );
          })}
      </div>
    );
    res.error
      ? notification.error({ message: `Error - ${res.error || res?.data?.error}` })
      : Modal.success({ width: '800px', title: title, content: successModalContent });
    return res;
  } catch (error) {
    Modal.destroyAll();
    notification.error({ message: `Error - ${error}` });
  }
}

export const ProjectOneServiceSettingsHelmTab = (props: iProjectOneServiceSettingsFormProps) => {
  const projectId = props.project.id;
  const serviceName = props.serviceName;
  const tabType = props.tabType;
  const service = props.service;

  const [helmConfig, setHelmConfig] = useState(null);

  const serviceOrConfigs = helmConfig === null ? service : helmConfig;

  const handleSubmit = async e => {
    e.formData.helmChartName = e.formData.helmRepositoryChartName;
    const res = await projectService.setProjectServices(projectId, { tabType: tabType, name: e.formData.name, data: e.formData });
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
  };

  const handleChanged = async e => {
    e.formData.helmChartName = e.formData.helmRepositoryChartName;
    setHelmConfig(e.formData);
  };

  const tabConfigurations = () => {
    const helmRepoAdd = () => {
      const helmBtnAdd = async () => {
        await handleSubmit({ formData: serviceOrConfigs });
        await showModalWithHelmCommandResult(projectService.serviceHelmAdd(projectId, serviceName), 'Helm repo add...');
      };

      const popcornDescriptionAdd = (
        <Space direction="vertical">
          <Text> Run: </Text>
          <CodeLine>
            helm repo add {serviceOrConfigs.helmRepositoryName} {serviceOrConfigs.helmRepositoryURL}
            {serviceOrConfigs.helmRepositoryVersion && `--version ${serviceOrConfigs.helmRepositoryVersion}`}
          </CodeLine>
        </Space>
      );

      return (
        <Row style={tabMargin}>
          <Col flex="250px">
            <Popconfirm
              title="Do you want to run `helm repo add` command?"
              okText="Run"
              cancelText="No"
              description={popcornDescriptionAdd}
              onConfirm={() => helmBtnAdd()}
            >
              <Button> Helm Repo Add </Button>
            </Popconfirm>
          </Col>
          <Col flex="auto">
            <Text>
              helm repo add {serviceOrConfigs.helmRepositoryName} {serviceOrConfigs.helmRepositoryURL}
              {serviceOrConfigs.helmRepositoryVersion && ` --version ${serviceOrConfigs.helmRepositoryVersion}`}
            </Text>
          </Col>
        </Row>
      );
    };

    const helmPull = () => {
      const helmBtnPull = async () => {
        await handleSubmit({ formData: serviceOrConfigs });
        await showModalWithHelmCommandResult(projectService.serviceHelmPull(projectId, serviceName), 'Helm pull...');
      };

      const popcornDescriptionPull = (
        <Text>
          <ul>
            <li style={{ color: '#FF7777' }}> This is not a reversible action </li>
            <li style={{ color: '#FF7777' }}> Remove current helm chart files </li>
            <li>
              helm pull {serviceOrConfigs.helmRepositoryName} {serviceOrConfigs.helmRepositoryChartName}
            </li>
          </ul>
        </Text>
      );

      return (
        <Row style={tabMargin}>
          <Col flex="250px">
            <Popconfirm
              title="Do you want to run `helm pull` command?"
              okText="Remove current files and pull it again"
              cancelText="No"
              description={popcornDescriptionPull}
              onConfirm={() => helmBtnPull()}
            >
              <Button danger> Helm Pull </Button>
            </Popconfirm>
          </Col>
          <Col flex="auto">
            <Text>
              helm pull {serviceOrConfigs.helmRepositoryName} {serviceOrConfigs.helmRepositoryChartName}
            </Text>
          </Col>
        </Row>
      );
    };

    const helmUpdate = () => {
      const helmBtnUpdate = async () => {
        await handleSubmit({ formData: serviceOrConfigs });
        await showModalWithHelmCommandResult(projectService.serviceHelmUpdate(projectId, serviceName), 'Helm update...');
      };

      const popcornDescriptionRun = () => {
        const helmDataPrimary = ['helm repo update', 'helm dependency update', 'helm dependency build'];
        return (
          <Space direction="vertical">
            <Text> Run: </Text>
            <ul>
              {helmDataPrimary.map((command, index) => (
                <li key={index}> {command} </li>
              ))}
            </ul>
          </Space>
        );
      };

      const hemlData = () => {
        const helmDataSecondary = ['helm repo update', 'helm dependency update', 'helm dependency build'];
        return (
          <Col flex="auto">
            <Space direction="vertical">
              {helmDataSecondary.map((command, index) => (
                <Text key={index}> {command} </Text>
              ))}
            </Space>
          </Col>
        );
      };

      return (
        <Row style={tabMargin}>
          <Col flex="250px">
            <Popconfirm
              title="Do you want to run commands?"
              okText="Run"
              cancelText="No"
              description={popcornDescriptionRun()}
              onConfirm={() => helmBtnUpdate()}
            >
              <Button> Helm Update </Button>
            </Popconfirm>
          </Col>
          {hemlData()}
        </Row>
      );
    };

    const extraText = () => (
      <Text>
        helm install {serviceOrConfigs.helmRepositoryChartName} {serviceOrConfigs.helmRepositoryName}/{serviceOrConfigs.helmRepositoryChartName}{' '}
        {serviceOrConfigs.helmRepositoryVersion && `--version ${serviceOrConfigs.helmRepositoryVersion}`}
      </Text>
    );

    return (
      <>
        {helmRepoAdd()}
        {helmPull()}
        {helmUpdate()}

        {extraText()}
      </>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Col span={20}>
        <Form
          widgets={CustomWidgets}
          formData={serviceOrConfigs}
          schema={oneServiceSettingsForm}
          uiSchema={oneServiceSettingsUISchema}
          validator={validator}
          onChange={handleChanged}
          onSubmit={handleSubmit}
          onError={e => console.log('Error in form submit: ', e)}
        >
          <BottomButtons>
            <Button type="primary" htmlType="submit" style={buttonBorder}>
              Save
            </Button>
          </BottomButtons>
        </Form>
      </Col>
      {tabConfigurations()}
    </Space>
  );
};

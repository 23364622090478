import history from '../../../../history';
import { deploymentService, useGqlDeploymentById } from '../../../../services/deployment.service';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { Button, Modal, notification, Space, Typography } from 'antd';
import { buttonWidth } from 'utils/styles';
import { iDeployment } from 'shared/deployment';

interface iDeleteDeployment {
  id: number;
  confirmDelete?: any;
}

const { Text } = Typography;

export const DeleteDeployment = (props: iDeleteDeployment) => {
  const user = useUser();
  const deployment: iDeployment = useGqlDeploymentById(props.id).data?.DeploymentsController_getDeployment || {};

  const deleteDeployment = (id: any) => {
    return new Promise(resolve => {
      const modalContent = (
        <Text>
          Do you want to Delete this Deployment? <Text strong> {deployment.id} </Text>
        </Text>
      );

      const handleOnOk = async () => {
        // @ts-ignore
        const res = await deploymentService.deleteDeployment(id);
        if (res.status === 401) {
          window.location.reload();
          return;
        }
        // @ts-ignore
        notification.success({ description: `Saved` });
        resolve(true);
      };

      const handleOnCancel = () => resolve(false);

      Modal.confirm({ title: 'Confirm', content: modalContent, okText: 'Ok', cancelText: 'Cancel', onOk: handleOnOk, onCancel: handleOnCancel });
    });
  };

  if (!userHasPermission(user, UserPermissions.DeploymentDelete)) return null;

  const deleteButton = () => {
    const handleDeleteClick = async () => {
      const isDeleted = await deleteDeployment(props.id);
      if (isDeleted) history.push('/');
    };

    return (
      <Space direction="vertical">
        <Button disabled={props?.confirmDelete} type="primary" danger onClick={handleDeleteClick} style={buttonWidth}>
          Delete
        </Button>
      </Space>
    );
  };

  return deleteButton();
};

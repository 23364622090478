import Editor from '@monaco-editor/react';
import { useEffect, useState } from 'react';
import { iDeployment } from 'shared/deployment';
import { Console } from 'console-feed';
import { deploymentService } from '../../../services/deployment.service';
import { FullScreenButton, FullScreenEditor } from '../../SharedComponents/FullScreenView/FullScreenView';
import { Deploymentk8sEventsList } from './Deploymentk8sEventsList';
import { Alert, Button, Card, Skeleton, Space, Switch, Typography, message } from 'antd';
import { CodeTwoTone, CopyOutlined, DownloadOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { buttonColor, floatingRight, logsStyle, logsText, spaceWidth } from 'utils/styles';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';

interface iServiceLogsProps {
  serviceName: string;
  deployment: iDeployment;
  container: string;
  describe: object;
  yamlPod: string;
}

const { Text } = Typography;

export function ServiceLogs(props: iServiceLogsProps) {
  const { serviceName, deployment, container, describe, yamlPod } = props;
  const deploymentId = deployment.id;
  const [projectLogs, setProjectLogs] = useState(null);
  const [inLoad, setInLoad] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [viewMode, setViewMode] = useState<'logs' | 'events' | 'editor'>('logs');

  let describePod = [];
  if (describe && describe['env']) {
    describePod.push({ name: 'Name', value: describe['name'] });
    describePod.push({ name: 'Image', value: describe['image'] });
    for (let i = 0; i < describe['env'].length; i++) {
      describePod.push({ name: describe['env'][i].name, value: describe['env'][i].value });
    }
  }

  async function getProjectLogs(deploymentId: number, serviceName: string, container: string) {
    let logsAnswer = await deploymentService.getServiceLogs(deploymentId, serviceName, container);
    const logs = logsAnswer.data;
    let state = { logs: [] };
    if (logs?.status === 500) return state.logs;
    Object.keys(logs).forEach(elem => {
      if (!logs[elem] || !logs[elem]['logs']) return;
      let temp = `${logs[elem]['logs']}`.split('\n');
      temp.forEach(item => state.logs.unshift({ method: 'info', data: [item] }));
    });
    return state.logs;
  }

  const getlogs = async () => {
    setInLoad(true);
    let new_logs = await getProjectLogs(deploymentId, serviceName, container);
    setProjectLogs(new_logs);
    setInLoad(false);
  };

  useEffect(() => {
    let interval = null;
    getlogs();
    interval = setInterval(async () => {
      setInLoad(true);
      let new_logs = await getProjectLogs(deploymentId, serviceName, container);
      setProjectLogs(new_logs);
      setInLoad(false);
    }, 30000);
    return () => {
      setProjectLogs(null);
      clearInterval(interval);
    };
  }, [serviceName, deploymentId, container]);

  const buttonFunctions = () => {
    const yamlDownload = () => {
      const blob = new Blob([yamlPod || ''], { type: 'text/yaml' });
      const url = URL.createObjectURL(blob);
      const link = Object.assign(document.createElement('a'), { href: url, download: `${serviceName}.yaml` });
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      message.success('YAML file downloaded successfully');
    };

    const yamlCopy = async () => {
      try {
        await navigator.clipboard.writeText(yamlPod || '');
        message.success('YAML file copied successfully');
      } catch (error) {
        message.error('Failed to copy YAML file');
      }
    };

    const actions = [
      { label: 'Download Data', icon: <DownloadOutlined />, yamlFunction: yamlDownload },
      { label: 'Copy', icon: <CopyOutlined />, yamlFunction: yamlCopy },
    ];

    return (
      <Space direction="horizontal">
        {actions.map(({ label, icon, yamlFunction }) => (
          <Button key={label} type="text" onClick={yamlFunction}>
            <Text type="success">
              {label} &nbsp; {icon}
            </Text>
          </Button>
        ))}
      </Space>
    );
  };

  const configurationData = () => {
    const logsReload = () => {
      const reloadIcon = inLoad ? <LoadingOutlined style={buttonColor} /> : <ReloadOutlined style={buttonColor} />;
      return (
        <TipLeft tip="Click here to reload the logs">
          <Button disabled={inLoad} onClick={getlogs} style={floatingRight} icon={reloadIcon} />
        </TipLeft>
      );
    };

    const cardTitle = () => (
      <Space direction="horizontal">
        <CodeTwoTone twoToneColor="#00A58E" />
        {viewMode === 'logs' ? 'View logs of' : viewMode === 'events' ? 'View events of' : 'View configuration of'}
        {serviceName}
      </Space>
    );

    const cardExtra = () => {
      const handleOnChangeLogs = () => setViewMode(viewMode === 'logs' ? 'editor' : 'logs');
      const handleOnChangeEvents = () => setViewMode(viewMode === 'events' ? 'editor' : 'events');
      return (
        <Space direction="horizontal">
          <Switch checked={viewMode === 'logs'} onChange={handleOnChangeLogs} /> View Logs <Text />
          <Switch checked={viewMode === 'events'} onChange={handleOnChangeEvents} /> View Events <Text />
          {viewMode === 'logs' && logsReload()}
          {viewMode === 'editor' && <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />}
        </Space>
      );
    };

    const editorData = () => {
      return (
        <Space direction="vertical" style={spaceWidth}>
          <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
            <Editor height={isFullscreen ? '100vh' : '300px'} width={`100%`} language={`yaml`} value={yamlPod || ''} theme={`GitHub`} />
          </FullScreenEditor>
          {buttonFunctions()}
        </Space>
      );
    };

    const eventsData = () => <Deploymentk8sEventsList deployment={deployment} podName={serviceName} />;

    const logsData = () => {
      const logAlert = () => <Alert showIcon type="error" message="No logs for this pod, please refresh..." action={logsReload()} />;
      const logsContent = (
        <Space direction="vertical">
          <div style={logsStyle}>
            <Console logs={projectLogs} variant="light" styles={logsText} />
          </div>
        </Space>
      );
      return projectLogs === null ? <Skeleton active={true} loading={true} /> : projectLogs.length === 0 ? logAlert() : logsContent;
    };

    return (
      <Space direction="vertical" style={spaceWidth}>
        <Card type="inner" title={cardTitle()} extra={cardExtra()}>
          {{ editor: editorData(), logs: logsData(), events: eventsData() }[viewMode]}
        </Card>
      </Space>
    );
  };

  return configurationData();
}

import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { PROJECT_NAME } from 'interface/common';
import { UserNameFormat } from 'utils/nameFormat';
import { Card, Col, Divider, Flex, Image, Row, Space, Typography } from 'antd';
import { cardForm, cardStyle, cardFormContent, resetText, authImages, dividerColor, logoStyle } from 'utils/styles';
import { cardBlur, cardBlurAlign, cardBlurContent, cardBlurContentButton, cardTextStyle } from 'utils/styles';
import { iAuthCommon, iAuthImage, iFormHeading, iLinkContent, iLogoContent, iSubText, iSubTextContent } from 'interface/Auth';

/** These below are used as component in Auth pages
 * @AuthenticationForm - Auth forms
 * @AuthCommon - Auth forms only in login page
 * @FormHeading - Heading layout
 * @AuthImage - Background image in Auth screens
 * @AuthLink - Auth links
 * @SubText - Extra info in a blurry card
 * @excludedPaths Ignores pageDetails in Admin Layout */

const { Text, Title } = Typography;

export const AuthenticationForm: FC<iAuthCommon> = ({ children }: { children: ReactNode }) => (
  <Row style={cardForm}>
    <Col span={24}>
      <Card size="small" style={cardStyle}>
        <Card style={cardFormContent}>
          <Space direction="vertical"> {children} </Space>
        </Card>
      </Card>
    </Col>
  </Row>
);

export const AuthCommon: FC<iAuthCommon> = ({ children }) => (
  <>
    <AuthImageB />
    <Row>
      <BrandLogo /> <SignUpText />
    </Row>
    {children}
    <SubText>
      <SubTextHeader /> <SubTextLogin />
    </SubText>
  </>
);

const FormHeading: FC<iFormHeading> = ({ title, description }) => (
  <Space direction="vertical">
    <Title level={2}> {title} </Title>
    <Text> {description} </Text> <Text />
  </Space>
);

export const LoginHeading = () => <FormHeading title="Login into your account" description="Hello there! Please input your email to proceed." />;

export const SignupHeading = () => <FormHeading title={`Get Started With ${PROJECT_NAME}`} description="Sign up to start your journey." />;

export const ForgotPasswordHeading = () => (
  <FormHeading
    title="Forgot Password?"
    description="Forgot your password? Don't worry! We've got you covered. Please enter your email address below, and we will send you a secure link to reset your password."
  />
);

export const ChangePasswordHeading = () => (
  <FormHeading
    title="Change Password"
    description={`Hello, ${UserNameFormat()} To ensure the security of your account, please proceed to set your password.`}
  />
);

export const PasswordSentHeading: FC<{ email: string }> = ({ email }) => {
  const resetInfo = (
    <Space direction="vertical" style={resetText}>
      <Text>
        We will send you a Password reset link if the Email ID provided <Text strong> {email} </Text> matches the one we have on records.
      </Text>
      <Text> Kindly check your Email for the next steps. If the Email address is incorrect, click here to make the necessary changes. </Text>
      <Text> If you have not received a Password reset link in the next few minutes: </Text>
      <ol>
        <li>
          <Text>
            Check your Junk/Spam folder for an Email from <Text strong> noreply@nanoheal.com </Text>.
          </Text>
        </li>
        <li>
          <Text> Make sure that the Email ID you entered matches the one used while registering. </Text>
        </li>
      </ol>
    </Space>
  );
  return <FormHeading title="Password Reset Email Sent!" description={resetInfo} />;
};

export const [AuthImageA, AuthImageB, AuthImageC, AuthImageD] = ['1', '2', '3', '4'].map(img =>
  (
    ({ image }: iAuthImage) =>
    () =>
      <div style={{ ...authImages, backgroundImage: `url(${image})` }} />
  )({ image: `branding/loginImage_${img}.svg` }),
);

const AuthLink: FC<iLinkContent> = ({ text, to }) => (
  <Col span={14}>
    <Flex justify={'flex-end'}>
      <Card size="small" style={cardStyle}>
        <Text strong>
          {text} &nbsp;
          <Link to={to}> {to === '/login' ? 'Login' : 'Sign up'} </Link>
        </Text>
      </Card>
    </Flex>
  </Col>
);

export const LoginText = () => <AuthLink text="Already have an account?" to="/login" />;
export const SignUpText = () => <AuthLink text="Don't have an account?" to="/signup" />;

const LogoContent: FC<iLogoContent> = ({ src, alt, showDivider }) => (
  <Space direction="horizontal">
    <Image src={src} alt={alt} preview={false} />
    <Text strong> {PROJECT_NAME} </Text>
    {showDivider && <Divider type="vertical" style={dividerColor} />}
  </Space>
);

export const BrandLogo = () => (
  <Col span={10}>
    <Card size="small" style={cardStyle}>
      <LogoContent src="branding/headerLogo.svg" alt={`${PROJECT_NAME} Logo`} />
    </Card>
  </Col>
);

export const BrandLogoMain = () => (
  <Space style={logoStyle}>
    <Link to="/">
      <LogoContent src="branding/headerLogo.svg" alt={`${PROJECT_NAME} Logo`} showDivider />
    </Link>
  </Space>
);

export const SubText: FC<iSubText> = ({ children }) => (
  <Row style={cardBlur}>
    <Col span={24}>
      <Card size="small" style={cardStyle}>
        <Flex justify={'flex-end'}>
          <Card style={cardBlurContent}>
            <Space direction="vertical" style={cardBlurAlign}>
              {children}
            </Space>
          </Card>
        </Flex>
      </Card>
    </Col>
  </Row>
);

export const SubTextHeader = () => (
  <Card size="small" style={cardBlurContentButton}>
    <Space direction="horizontal">
      <Image src="branding/loginThumbsupImage.svg" preview={false} />
      <Text> One Portal to Manage Them All </Text>
    </Space>
  </Card>
);

const SubTextContent: FC<iSubTextContent> = ({ text }) => <Text style={cardTextStyle}> {text.replace(/name/g, PROJECT_NAME)} </Text>;

const contentTexts: Record<string, string> = {
  login: `Embark on a digital odyssey with name, harnessing the power to seamlessly navigate clouds, clusters, and deployments alike. With just one click, our portal becomes your gateway to unified control, ensuring your IT infrastructure is as harmonious as a well-orchestrated symphony.`,
  signup: `Begin your digital journey with name. Join our community in a few clicks. Navigate clouds, clusters, and deployments seamlessly. name ensures harmony in your IT infrastructure.`,
  forgot: `Lost your password? No problem! Easily regain access with name. Just enter your email, and we'll guide you through resetting it.`,
  change: `Secure your digital journey with name's password management. Take control of your security. Ensure a smooth, secure experience through clouds, clusters, and deployments.`,
  find: `Navigate your IT infrastructure effortlessly with name's search. Locate pages and resources quickly. Stay efficient on your digital journey.`,
};

export const SubTextLogin = () => <SubTextContent text={contentTexts.login} />;
export const SubTextSignup = () => <SubTextContent text={contentTexts.signup} />;
export const SubTextForgot = () => <SubTextContent text={contentTexts.forgot} />;
export const SubTextChange = () => <SubTextContent text={contentTexts.change} />;
export const SubTextFind = () => <SubTextContent text={contentTexts.find} />;

export const excludedPaths = [
  '/',
  '/projects',
  '/clusters',
  '/charts',
  '/users',
  '/audit-logs',
  '/integrations',
  '/tariffs',
  '/new-intro',
  '/new-from-template',
];

import { Space, Typography } from 'antd';
import { HelpDrawerTpl } from 'components/Help/HelpDrawerTpl';
import { TipLeft } from 'components/SharedComponents/Tooltip/Tooltip';
import { PROJECT_NAME } from 'interface/common';
import { spaceWidth } from 'utils/styles';

const { Title, Text } = Typography;

export const ProjectGitConfigurationHelp = (props: { btnText?: any }) => {
  const mainData = () => {
    const linkData = [
      {
        platform: 'GitHub',
        description:
          'Personal access tokens are an alternative to using passwords for authentication to GitHub when using the GitHub API or the command line.',
        links: [
          {
            url: 'https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens',
            text: 'Managing your personal access tokens',
            tooltip: 'Click here to login through your GitHub Account',
          },
          {
            url: 'https://github.com/settings/tokens?type=beta',
            text: 'Personal access tokens in your GitHub Account',
            tooltip: 'Click here to login through your GitHub Account',
          },
        ],
      },
      {
        platform: 'GitLab',
        description:
          'Project access tokens are similar to passwords, except you can limit access to resources, select a limited role, and provide an expiry date.',
        links: [
          {
            url: 'https://docs.gitlab.com/ee/user/project/settings/project_access_tokens.html#create-a-project-access-token',
            text: 'GitLab',
            tooltip: 'Click here to login through your GitLab Account',
          },
        ],
      },
    ];

    return linkData.map(({ platform, description, links }) => (
      <Space key={platform} direction="vertical" style={spaceWidth}>
        <Title level={4}> {platform} </Title>
        <Text> {description} </Text>
        <ul>
          {links.map(({ url, text, tooltip }) => (
            <li key={url}>
              <TipLeft tip={tooltip}>
                <Text strong>
                  <a href={url} target="_blank">
                    {text}
                  </a>
                </Text>
              </TipLeft>
            </li>
          ))}
        </ul>
      </Space>
    ));
  };

  return (
    <HelpDrawerTpl title={`Help`} btnText={props.btnText}>
      <Space direction="vertical" style={spaceWidth}>
        <Text> You can use Github and Gitlab Repositories. </Text>
        <Text> {PROJECT_NAME} will need write and read access. </Text>
        <Text />
        {mainData()}
      </Space>
    </HelpDrawerTpl>
  );
};

export const BottomButtons = ({ children }) => (
  <>
    <br />
    <br />
    <br />
    <br />
    <br />
    <div className="bottomButtons"> {children} </div>
  </>
);

import { AuthenticationForm, AuthImageB, BrandLogo, SubText, SubTextHeader, SubTextLogin } from '../SharedComponents/AuthComp/AuthComp';
import { Button, Form, Input, message, Row, Space } from 'antd';
import Password from 'antd/es/input/Password';
import { Fragment } from 'react';
import { authService } from '../../services/auth.service';

export default function CreateUserPassword() {
  const [form] = Form.useForm();

  const createPassword = async (values: any) => {
    return values.password.length < 8 || values.rePassword.length < 8
      ? message.error('Password is not valid')
      : values.password !== values.password.replace(/[^\x00-\x7F]+/gi, '')
      ? message.error('Password is not valid')
      : values.password !== values.rePassword
      ? message.error('Password mismatch')
      : (await authService.createPassword(values.password), message.success('Success'));
  };

  return (
    <>
      <AuthImageB />
      <Row>
        <BrandLogo />
      </Row>
      <AuthenticationForm>
        <Space direction="vertical">
          <h2>Create user password</h2>
          <Form form={form} onFinish={createPassword}>
            <Form.Item key={'password'} name={'password'}>
              <Password type="password" placeholder="enter the password" />
              {/*<Input type="password" placeholder="Enter the password" />*/}
            </Form.Item>
            <Form.Item key={'rePassword'} name={'rePassword'}>
              {/*<Input placeholder="Re-enter the password" />*/}
              <Password type="password" placeholder="Re-enter the password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </AuthenticationForm>
      <SubText>
        <SubTextHeader />
        <SubTextLogin />
      </SubText>
    </>
  );
}

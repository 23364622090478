import { Link, useHistory } from 'react-router-dom';
import { useUser } from '../../../utils/common';
import { authService } from '../../../services/auth.service';
import { NhHelpDrawer } from 'components/Help/NhHelpDrawer';
import { EditOutlined, ExperimentOutlined, GithubFilled, KeyOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Dropdown, Space, Spin, Tooltip, Typography } from 'antd';
import { formatUserName } from 'utils/nameFormat';
import { headerCard } from 'utils/styles';
import { TipBottom } from '../Tooltip/Tooltip';

const { Text } = Typography;

const userLogout = async () => {
  await authService.logout();
  window.location.reload();
};

export default function NhHeader() {
  const user = useUser();
  const history = useHistory();

  const userNameFormattedHovered = () =>
    user &&
    user.email && (
      <Space
        direction="horizontal"
        onClick={() => {
          history.push(`/users/${user.id}`);
        }}
      >
        <Avatar src={user.avatar} shape="square" size={52} />
        <Space direction="vertical">
          <Text strong>
            {formatUserName(user.email)}{' '}
            <Button size="small">
              <EditOutlined />
            </Button>
          </Text>
          <Text> {user.email} </Text>
        </Space>
      </Space>
    );

  const userSession = () => (
    <Space direction="horizontal">
      <Button data-cy="changePasswordLink">
        <Link to="/changePassword">
          Change password <KeyOutlined />
        </Link>
      </Button>
      <Button data-cy="logOut" onClick={userLogout}>
        Sign out <LogoutOutlined />
      </Button>
    </Space>
  );

  const githubLogin = (
    <Button data-cy="githubLogin" block disabled={!!(user && user.githubLogin)}>
      {user && user.githubLogin ? (
        `Your account is linked with GitHub`
      ) : (
        <a href="/api/github/oauth/link" target="_blank">
          Link account with GitHub <GithubFilled />
        </a>
      )}
    </Button>
  );

  const userGithubLogin = () => (user && !user.githubLogin ? githubLogin : null);

  const menu = (
    <Card size="small" bordered={false} style={headerCard}>
      <Space direction="vertical">
        {userNameFormattedHovered()}
        {userSession()}
        {userGithubLogin()}
      </Space>
    </Card>
  );

  const userData = () => (
    <Text strong>
      Welcome, {formatUserName(user.email)} &nbsp;
      <Avatar src={user.avatar} shape="circle" size={20} style={{ marginTop: -5 }} /> &nbsp;
    </Text>
  );

  const userNameFormattedHeader = () => (
    <Dropdown overlay={menu}>
      <a data-cy="user" href="/" onClick={e => e.preventDefault()}>
        {user && user.email ? userData() : <Spin />}
      </a>
    </Dropdown>
  );

  const userNameFormat = () => (
    <Space direction="horizontal">
      <TipBottom tip={`API documentation`} key={`api`}>
        <a href={`https://${window.location.host}/unifie-api/graphql/v1`} target="_blank">
          <ExperimentOutlined />
        </a>
      </TipBottom>
      <NhHelpDrawer />
      {userNameFormattedHeader()}
    </Space>
  );

  return userNameFormat();
}

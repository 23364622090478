import gql from 'graphql-tag';
import TabPane from 'antd/lib/tabs/TabPane';
import { ReactElement, useEffect, useState } from 'react';
import { Skeleton, Switch, Tabs } from 'antd';
import { projectService } from 'services/project.service';
import { useHistory, useParams } from 'react-router-dom';
import { NewService } from './NewService';
import { useLazyQuery } from '@apollo/client';
import { ProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { ProjectOneServiceSettingsMainTab } from 'components/Projects/Settings/ProjectService/MainTab';
import { ProjectOneServiceSettingsPostProcessing } from 'components/Projects/Settings/ProjectService/PostProcessingTab';
import { ProjectOneServiceCustomYAML } from 'components/Projects/Settings/ProjectService/ProjectOneServiceCustomYAML';
import { ProjectOneServiceSettingsFormKEDA } from 'components/Projects/Settings/ProjectService/ProjectOneServiceSettingsFormKEDA';
import { ProjectOneServiceSettingsFormRoutes } from 'components/Projects/Settings/ProjectService/ProjectOneServiceSettingsFormRoutes';
import { ProjectOneServiceVariables } from 'components/Projects/Settings/ProjectService/ProjectOneServiceVariables';
import { useApiQuery, useUser } from 'utils/common';
import { iDeployment, iDeploymentServicesConfig, iProjectModel } from 'shared/deployment';
import { ProjectOneServiceSettingsHelmTab } from 'components/Projects/Settings/ProjectService/HelmTab';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { DockerWizard } from 'components/Projects/YamlEditor/wizard/DockerWizard';
import { PROJECT_CODE_NAME } from 'interface/common';
import { YamlEditor } from 'components/Projects/YamlEditor/YamlEditor';
import { StarFilled } from '@ant-design/icons';
import { useGqlAllServicesConfigurationUI } from 'services/deployment.service';
import { DeploymentOneServiceSettings } from 'components/Deployments/Setting/General/DeploymentOneServiceSettings';
import { isInEditMode, setEditMode } from 'utils/util';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';

const { Provider } = ProjectDeploymentSyncVarsContext;

/** http://localhost:3000/#/app/43/configuration/services
 * @param props
 * @returns */
export const ConfigurationServices = (props: { deployment: iDeployment; serviceName: string }): ReactElement => {
  const history = useHistory();
  const deployment = props.deployment;
  const project: iProjectModel = deployment?.ProjectModel;

  const params: any = useParams();
  const { serviceName, activeTabType } = params;
  const [isEditMode, setIsEditMode] = useState(isInEditMode(`dp`, deployment.id));

  const user = useUser();

  const deploymentId = deployment?.id;
  const [getSyncVars, { loading, error, data }] = useLazyQuery(
    gql`
      query DeploymentsController_debugUI_deploymentSyncVars($deploymentId: Int!) {
        DeploymentsController_debugUI_deploymentSyncVars(deploymentId: $deploymentId) {
          syncVars
        }
      }
    `,
  );

  const qlQuery = useGqlAllServicesConfigurationUI(Number(deployment.id));
  const servicesConfig: iDeploymentServicesConfig[] = qlQuery.data?.DeploymentsController_AllServicesConfigurationUI || [];

  const serviceConfig = servicesConfig.find(service => service.serviceName === props.serviceName);

  const [serviceData, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(project?.id, serviceName),
    [project?.id, serviceName],
  );
  const service: iCloudProjectServiceYamlSpecs = serviceData;

  useEffect(() => {
    if (deploymentId) getSyncVars({ variables: { deploymentId: deploymentId } });
  }, [deploymentId]);

  if (loading || serviceLoader || qlQuery.loading) return <Skeleton active={true} loading={true} />;
  if (serviceName === 'new') return <NewService deployment={deployment} />;

  const uiType = service?.uiType || null;
  let defaultOpenKey = 'main';
  if (serviceConfig) {
    defaultOpenKey = 'favorit';
  } else if (uiType === `helm`) {
    defaultOpenKey = 'helm';
  } else if (uiType === `docker`) {
    defaultOpenKey = 'deployment-configuration';
  } else if (uiType === `k8s`) {
    defaultOpenKey = 'custom-yaml';
  }

  const selectedTabType = activeTabType || defaultOpenKey;
  const projectFunctions = { service: service, project: project, serviceName: serviceName, tabType: selectedTabType };

  if (!deploymentId || !user) return <Skeleton active={true} loading={true} />;

  const handleTabClick = (key: any) => history.push(`/app/${deploymentId}/configuration/services/${serviceName}/${key}`);

  const configurationService = () => {
    const providervalue = { serviceName: serviceName, syncVars: data?.DeploymentsController_debugUI_deploymentSyncVars?.syncVars };

    const handleOnClick = val => {
      setIsEditMode(val);
      setEditMode(`dp`, deployment.id, val);
    };

    const extraTab = userHasPermission(user, UserPermissions.ProjectsMange) && serviceConfig?.hasUIconfig === true && deployment.isReady === true && (
      <Switch checkedChildren="Edit mode" unCheckedChildren="View mode" onClick={handleOnClick} checked={isEditMode} />
    );

    const gitTabsData = () => {
      const filterUI = v => !v.uiTypes || v.uiTypes.includes(uiType);
      const filterPermission = v => v.permission !== false;
      const filterService = v =>
        !serviceConfig?.hasUIconfig || v.forEditMode === isEditMode || v.forEditMode === false || deployment.isReady === false;

      const favoriteSettingsTab = () => (
        <>
          <StarFilled /> Favorite settings
        </>
      );
      const favoriteSettingsComponent = () => (
        <DeploymentOneServiceSettings serviceName={props.serviceName} deployment={deployment} schema={serviceConfig} />
      );
      const settingsComponent = () => <ProjectOneServiceSettingsMainTab deployment={deployment} {...projectFunctions} />;
      const postProcessingComponent = () => <ProjectOneServiceSettingsPostProcessing {...projectFunctions} />;
      const customYAMLtemplateComponent = () => <ProjectOneServiceCustomYAML {...projectFunctions} />;
      const wizardComponent = () => (
        <DockerWizard
          key="wizard"
          serviceName={serviceName}
          fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/template.yaml`}
          project={project}
          deployment={deployment}
        />
      );
      const yamlAdvancedConfigurationComponent = () => (
        <YamlEditor key={`yaml-editor`} fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/template.yaml`} project={project} />
      );
      const helmComponent = () => <ProjectOneServiceSettingsHelmTab {...projectFunctions} />;
      const variablesComponent = () => <ProjectOneServiceVariables deployment={deployment} {...projectFunctions} />;
      const autoscalingComponent = () => <ProjectOneServiceSettingsFormKEDA {...projectFunctions} />;
      const routesComponent = () => <ProjectOneServiceSettingsFormRoutes {...projectFunctions} />;

      const gitTabs = [
        {
          forEditMode: false,
          key: 'favorit',
          permission: userHasPermission(user, UserPermissions.DeploymentMange),
          tab: favoriteSettingsTab(),
          component: favoriteSettingsComponent(),
        },
        {
          uiTypes: [`docker`, `helm`, `k8s`, null],
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forEditMode: true,
          key: 'main',
          tab: 'Settings',
          component: settingsComponent(),
        },
        {
          uiTypes: [`helm`, null],
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forEditMode: true,
          key: 'post-processing-rules',
          tab: 'Post Processing',
          component: postProcessingComponent(),
        },
        {
          uiTypes: [`helm`, `k8s`, null],
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forEditMode: true,
          key: 'custom-yaml',
          tab: 'Custom YAML Template',
          component: customYAMLtemplateComponent(),
        },
        {
          uiTypes: [`docker`, null],
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forEditMode: true,
          key: 'deployment-configuration',
          tab: 'Wizard',
          component: wizardComponent(),
        },
        {
          uiTypes: [`docker`, null],
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forEditMode: true,
          key: 'deployment-yaml',
          tab: 'YAML advanced configuration',
          component: yamlAdvancedConfigurationComponent(),
        },
        {
          uiTypes: [`helm`, null],
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forEditMode: true,
          key: 'helm',
          tab: 'HELM',
          component: helmComponent(),
        },
        {
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forEditMode: true,
          key: 'variables',
          tab: 'Variables',
          component: variablesComponent(),
        },
        {
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forEditMode: true,
          key: 'keda-autoscaling',
          tab: 'Autoscaling',
          component: autoscalingComponent(),
        },
        {
          permission: userHasPermission(user, UserPermissions.ProjectsMange),
          forEditMode: true,
          key: 'route',
          tab: 'Routes',
          component: routesComponent(),
        },
      ]
        .filter(Boolean)
        .filter(filterUI)
        .filter(filterPermission)
        .filter(filterService);

      return gitTabs.map(({ key, tab, component }) => (
        <TabPane key={key} tab={tab}>
          {component}
        </TabPane>
      ));
    };

    return (
      <Provider value={providervalue}>
        <Tabs defaultActiveKey={selectedTabType} onChange={handleTabClick} tabBarExtraContent={extraTab}>
          {gitTabsData()}
        </Tabs>
      </Provider>
    );
  };

  return configurationService();
};

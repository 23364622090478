import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { Typography, Skeleton, Space } from 'antd';
import { useGqlDeploymentsList } from 'services/deployment.service';
import { NewProjectForm } from 'components/Projects/NewProjectForm';
import { BigOption, BigOptions, Center } from 'components/SharedComponents/BigOptions/BigOptions';

const { Title, Text } = Typography;

export function SelectDeployWay() {
  const user = useUser();
  const gqlQuery = useGqlDeploymentsList();
  const data = gqlQuery.data?.DeploymentsController_deployments || [];
  const hasDeployments = data?.length > 0;

  if (gqlQuery?.loading) {
    return <Skeleton active={true} loading={true} />;
  }

  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) {
    return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;
  }

  return (
    <Center>
      <Space direction="vertical">
        <Text /> <Text />
        <Center>
          <Title level={3}> Ready to Get Started? </Title>
        </Center>
        <Center>
          <Text type="secondary"> Please choose how you want to deploy your application. </Text>
        </Center>
        <Text /> <Text />
        <BigOptions
          options={[
            hasDeployments
              ? {
                  name: 'Copy existing deployment',
                  path: '/new',
                  tooltip: 'Copy existing deployment',
                  img: 'branding/newCluster.svg',
                  secondary: 'Create a new deployment by copying an existing one',
                }
              : null,
            {
              name: 'Deploy from public template',
              path: '/new-from-template',
              tooltip: 'Deploy from public template',
              img: 'branding/newCluster.svg',
              secondary: 'Choose from a list of public preconfigured templates',
            },
            {
              customUI: (
                <NewProjectForm
                  openBtnUI={
                    <BigOption
                      secondary={`Create a new project and deploy your application`}
                      name={`Deploy from scratch`}
                      img={'branding/newCluster.svg'}
                    />
                  }
                />
              ),
            },
          ]}
        />
      </Space>
    </Center>
  );
}

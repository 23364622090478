import { Space, Card, Image, Typography, Flex, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { TipBottom } from '../Tooltip/Tooltip';

const { Title, Text } = Typography;

export const Center = ({ children }) => <Flex justify="center"> {children} </Flex>;

interface iBigOption {
  customUI?: any;
  name?: string | any;
  secondary?: string | any;
  path?: string;
  onClick?: () => void;
  tooltip?: string;
  img?: string;
}
interface iBigOptions {
  options: iBigOption[];
}

export const BigOption = (props: iBigOption) => {
  const option = props;
  if (option.customUI) {
    return option.customUI;
  }
  return (
    <TipBottom tip={option.tooltip || ''}>
      <Link
        to={option?.path || ''}
        onClick={e => {
          if (option.onClick) {
            e.stopPropagation();
            option.onClick();
            return false;
          }
          if (!option?.path) {
            e.preventDefault();
            return false;
          }
        }}
      >
        <Card size="small" bordered={false}>
          <Center>
            <Image src={option.img} preview={false} height={30} />
          </Center>
          <Center>
            <Title level={5}> {option.name} </Title>
          </Center>
          <Text type="secondary"> {option.secondary} </Text>
        </Card>
      </Link>
    </TipBottom>
  );
};

export const BigOptions = (props: iBigOptions) => {
  return (
    <Space direction="horizontal">
      {props.options.map((option, index) => {
        return <BigOption key={index} {...option} />;
      })}
    </Space>
  );
};

import gql from 'graphql-tag';
import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useApiQuery } from 'utils/common';
import { schemaFormService } from 'services/schemaForm.service';
import { ProjectServiceList, projectService } from 'services/project.service';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { Button, Col, Flex, Image, notification, Row, Select, Skeleton, Space, Tag, Typography } from 'antd';
import { buttonBorder, buttonWidth, cardTextStyle, newServices, spaceWidth } from 'utils/styles';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { TipRight } from 'components/SharedComponents/Tooltip/Tooltip';

const { Title, Text } = Typography;

export const ProjectServicesList = (props: { srcProjectId: number; drcProjectId: number; onFinish?: any }) => {
  const history = useHistory();
  const [serviceList, servicesError, servicesLoader] = useApiQuery(
    () => projectService.getProjectServiceList(props.srcProjectId),
    [props.srcProjectId],
  );

  const services: ProjectServiceList[] = serviceList;
  const [selected, setServiceNames] = useState([]);

  useEffect(() => {
    if (services) {
      setServiceNames(services.map(elem => elem.name));
    }
  }, [serviceList]);

  const [importService, createServiceRes] = useAuthedMutationWithNotification(gql`
    mutation ProjectController_createService($projectId: Int!, $serviceName: String!, $srcProjectId: Int) {
      ProjectController_createService(projectId: $projectId, serviceName: $serviceName, srcProjectId: $srcProjectId) {
        status
        error
        serviceName
      }
    }
  `);

  if (servicesLoader) return <Skeleton active={true} loading={true} />;

  const createServices = createServiceRes?.data?.ProjectController_createService;

  const createServicesTags = (
    <Tag color={createServices?.error ? 'red' : 'green'}>
      {createServices?.serviceName} - {createServices?.error || 'Imported'}
    </Tag>
  );

  const importButton = () => {
    const handleImportButton = async () => {
      for (let i = 0; i < selected.length; i++) {
        await importService({
          variables: { projectId: Number(props.drcProjectId), serviceName: String(selected[i]), srcProjectId: Number(props.srcProjectId) },
        });
      }
      if (props.onFinish) {
        props.onFinish();
      } else {
        history.push(`/project/${props.drcProjectId}/settings/services`);
      }
    };
    return (
      <Button type="primary" onClick={handleImportButton}>
        Import selected Services
      </Button>
    );
  };

  return (
    <>
      <br />
      <br />
      <Select
        mode="multiple"
        allowClear
        style={spaceWidth}
        placeholder="Please select services"
        onChange={setServiceNames}
        value={selected}
        options={services.map(elem => {
          return { label: elem.name, value: elem.name };
        })}
      />
      <br />
      <br />

      {importButton()}
      {createServices && createServicesTags}
    </>
  );
};

export const ProjectCreateService = (props: { projectId: any; firstService: boolean }) => {
  const history = useHistory();
  const projectId = Number(props.projectId);
  const [schema, schemaError, schemaLoader] = useApiQuery(() => schemaFormService.getNewServiceForm(projectId));

  const [searchedProjects, setSearchedProjects] = useState<any[]>([]);
  const [activeProject, setProject] = useState(null);
  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        ProjectController_getProjectList {
          id
          name
          title
          description
          logo
          tags
        }
      }
    `,
    {},
  );

  const projects = searchedProjects.length > 0 ? searchedProjects : data?.ProjectController_getProjectList;

  if (loading || !projects || error || schemaError || schemaLoader) return <Skeleton active={true} loading={true} />;

  const mainFormData = () => {
    const handleCreateService = async e => {
      const res = await projectService.setNewService(projectId, e.formData);
      if (e.formData.name === 'new') return notification.error({ message: `Error - use another service name` });
      res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
      history.push(`/project/${projectId}/settings/services/${e.formData.name}/main`);
    };
    return (
      <Form
        schema={schema.createService}
        validator={validator}
        templates={{ FieldTemplate: CustomFieldTemplate }}
        onSubmit={e => handleCreateService(e)}
        onError={e => console.log('Error from submit: ', e)}
      >
        <Space wrap>
          {!props.firstService && (
            <Link to={`/project/${projectId}/settings/services`}>
              <Button style={buttonWidth}> Cancel </Button>
            </Link>
          )}
          <BottomButtons>
            <Button type="primary" htmlType="submit" style={buttonBorder}>
              Create Service
            </Button>
          </BottomButtons>
        </Space>
      </Form>
    );
  };

  const projectData = () => {
    const projectListOptions = (data?.ProjectController_getProjectList || []).map(elem => {
      const tooltipTitle = (
        <Space direction="vertical">
          <Text style={cardTextStyle}> {elem.description} </Text>
          {elem.tags && elem.tags.map(tag => <Tag color="green"> {tag} </Tag>)}
        </Space>
      );
      const tooltipLabel = (
        <TipRight tip={tooltipTitle}>
          <Space direction="horizontal">
            {elem.logo && <LogoAvatar logo={elem.logo} name={elem.title} />}
            {elem.title}
          </Space>
        </TipRight>
      );
      return { value: Number(elem.id), label: tooltipLabel };
    });
    return <Select style={spaceWidth} onChange={setProject} placeholder="Choose the Project Here" options={projectListOptions} />;
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Row justify="center"> {props.firstService && <Image src="/branding/un-happy-box.svg" preview={false} />} </Row>
      <Text />
      <Text />
      <Flex justify={'center'}>
        <Title level={4}> There are 2 ways to create a service </Title>
      </Flex>
      <Row>
        <Col span={11}>
          <Title level={5}> Create new service from scratch </Title>
          <ul>
            <li> Create a new service from scratch by filling out the form below. </li>
            <li>
              Use this way if you want to create new service from scratch or with <b> HELM chart </b>
            </li>
          </ul>
          {mainFormData()}
        </Col>
        <Col span={1} style={newServices}>
          <Text strong> OR </Text>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Title level={5}> Import pre-configured service </Title>
            <ul>
              <li> You can save your time and import configuration from other project </li>
              <li> Use this option if someone else already deploy this software. </li>
            </ul>
            <Text />
            <Text />
            <Text />
            <Text />
          </Space>
          {projectData()}
          {activeProject && <ProjectServicesList srcProjectId={Number(activeProject)} drcProjectId={Number(projectId)} />}
        </Col>
      </Row>
    </Space>
  );
};
